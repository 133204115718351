import { PageHeader } from 'antd';
import FormBlock from '../../components/forms/FormBlock';
import FormWrap from '../../components/forms/FormWrap';
import { createProductTag } from '../../network/Tags';
import { ProductTagForm } from './ProductTags';

export default function CreateProductTag({ history }) {
  return (
    <>
      <PageHeader
        className={'site-page-header'}
        title={'Create Tag'}
        subTitle={'Create a new tag'}
      />
      <FormWrap
        title="create_tag"
        request={createProductTag}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/product-tags')}
      >
        <div className="section-from-vertical">
          <FormBlock formTemplate={ProductTagForm} />
        </div>
      </FormWrap>
    </>
  );
}
