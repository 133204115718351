import { Form, PageHeader } from 'antd';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import { createGenre } from '../../network/Genres';

const CreateGenre = ({ history }) => {
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        className={'site-page-header'}
        title={'Create Genre'}
        onBack={() => history.push('/genres')}
        style={{
          backgroundColor: 'white',
        }}
      />
      <FormWrap
        title={'create_genre'}
        request={createGenre}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/genres')}
        invalidate={['getGenres']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={GenreForm} />
        </div>
      </FormWrap>
    </>
  );
};

export default CreateGenre;

export const GenreForm = {
  title: 'Genre',
  key: [],
  sections: [
    {
      columns: 1,
      fields: [
        {
          label: 'Name (English)',
          key: ['name', 'en'],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Name (Chinese)',
          key: ['name', 'zh'],
          isRequired: true,
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
      ],
    },
  ],
};
