import { Badge, Button, PageHeader } from 'antd';
import { useQueryClient } from 'react-query';

import TableBlock from '../../components/tables/TableBlock';
import { getProductCategoriesBasic } from '../../network/API';
import { getProducts } from '../../network/Products';

const Products = ({ _history }) => {
  const queryClient = useQueryClient();
  queryClient.invalidateQueries('getProductDetails');

  return (
    <>
      <PageHeader className="site-page-header" title="Products" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getProducts'}
        request={getProducts}
      />
    </>
  );
};

export default Products;

const filters = (history) => ({
  fields: [
    {
      type: 'text',
      label: 'SKU',
      key: 'sku',
    },
    {
      type: 'select',
      label: 'Collection',
      default: '',
      key: 'category',
      options: getProductCategoriesBasic,
    },
    {
      type: 'text',
      label: 'Name (english)',
      key: 'name.en',
    },
    {
      type: 'select',
      label: 'Publication',
      default: '',
      key: 'status',
      options: [
        { key: 'published', label: 'Published' },
        { key: 'unpublished', label: 'Unpublished' },
        { key: 'discontinued', label: 'Discontinued' },
      ],
    },
    {
      type: 'select',
      label: 'Target audience',
      default: '',
      key: 'targetAudience',
      options: [
        { key: 'company', label: 'Company' },
        { key: 'public', label: 'Public' },
      ],
    },
  ],
  actions: [
    {
      label: '+ Create product',
      isActive: true,
      action: () => history.push('/products/create'),
    },
  ],
});

const columns = (history, _keyRequest) => [
  {
    title: 'SKU',
    key: 'name',
    dataIndex: 'sku',
  },
  {
    title: 'Name (English)',
    key: 'name.en',
    dataIndex: 'name',
    render: (name) => name?.en,
    sorter: true,
  },
  {
    title: 'Name (中文)',
    key: 'name.zh',
    dataIndex: 'name',
    render: (name) => name?.zh,
  },
  {
    title: 'Collection',
    key: 'category',
    dataIndex: 'category',
    render: (category) => category?.name?.en || category?.name?.en,
    sorter: true,
  },
  {
    title: 'Retail price(¥)',
    key: 'name',
    dataIndex: 'retailPrice',
    sorter: true,
  },
  {
    title: 'Delivery days',
    key: 'name',
    dataIndex: 'deliveryTime',
    sorter: true,
  },
  {
    title: 'Published',
    key: 'name',
    dataIndex: 'status',
    render: (status) => {
      const statuses = {
        published: { text: 'Published', color: 'green' },
        unpublished: { text: 'Unpublished', color: 'red' },
        discontinued: { text: 'Discontinued', color: 'black' },
      };

      return (
        <Badge color={statuses[status].color} text={statuses[status].text} />
      );
    },
    sorter: true,
  },
  {
    title: '',
    key: 'action',
    width: 25,
    fixed: 'right',
    render: (e) => {
      return (
        <Button onClick={() => history.push(`products/${e.id}`)} type="primary">
          Edit
        </Button>
      );
    },
  },
];
