import { Form, PageHeader } from 'antd';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import {
  getCompanies,
  getCompanyTheme,
  getUniforms,
  createCompanyOrder,
} from '../../network/B2B';

const CreateOrder = ({ history }) => {
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Create company"
        onBack={() => history.push('/companies/orders')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        form={form}
        title="create_company"
        request={createCompanyOrder}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/companies/orders')}
        invalidate={['getCompanies']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={OrderDescriptionForm} />
          <FormBlock form={form} formTemplate={UniformsForm} />
        </div>
      </FormWrap>
    </>
  );
};

export default CreateOrder;

export const OrderDescriptionForm = {
  title: 'Description',
  key: [],
  sections: [
    {
      columns: 4,
      fields: [
        {
          label: 'Company',
          type: 'select',
          isRequired: true,
          key: ['company'],
          requestOption: getCompanies,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const UniformsForm = {
  title: 'Uniforms',
  sections: [
    {
      canAdd: true,
      key: ['uniforms'],
      addSuffix: 'number',
      columns: 4,
      // noDivider: true,
      title: 'Option',
      deleteBtn: { label: 'Delete', style: 'line' },
      addBtn: { label: 'Add uniform', style: 'primary' },
      fields: [
        {
          label: 'Uniform',
          type: 'select',
          isRequired: true,
          key: ['uniform'],
          requestOption: getUniforms,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Theme collection',
          type: 'select',
          isRequired: true,
          key: ['theme'],
          conditionalFilter: (form) => {
            let company = form.getFieldValue(['company']);
            return { company: company ? company : null };
          },
          requestOption: getCompanyTheme,
          onChange: (name, form) => {
            let uniforms = form.getFieldValue(['uniforms']);
            uniforms[name].role = undefined;
            form.setFieldsValue({ uniforms });
          },
          pos: {
            col: 2,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Position',
          type: 'select',
          isRequired: true,
          key: ['role'],
          keySearch: 'getthemeSelect',
          conditionalFilter: (form) => {
            let company = form.getFieldValue(['company']);
            return { company: company ? company : null };
          },
          requestOption: getCompanyTheme,
          optionParser: (options, fieldKey, form) => {
            let theme = form.getFieldValue(['uniforms', fieldKey, 'theme']);
            if (!theme) return [];
            const matching = options?.find((e) => e.id === theme);

            return matching?.roles?.map((e) => ({
              id: e,
              name: e,
            }));
          },
          pos: {
            col: 3,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};
