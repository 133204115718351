import { Button, PageHeader } from 'antd';
import TableBlock from '../../components/tables/TableBlock';
import { ROLE_PERMISSIONS } from '../../constants/common';
import { useAuth } from '../../hooks/useAuth';
import { getProductTypes } from '../../network/ProductTypes';

export default function ProductTypes() {
  const { user } = useAuth();
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : [];

  const columns = (history, keyRequest) => {
    return [
      {
        title: 'Order',
        key: 'order',
        dataIndex: 'order',
      },
      {
        title: 'Name',
        key: 'name.en',
        dataIndex: 'name',
        render: (name) => name.en,
      },
      {
        title: 'Name (Chinese)',
        key: 'name.zh',
        dataIndex: 'name',
        render: (name) => name.zh,
      },
      {
        title: 'Family',
        key: 'genre',
        dataIndex: 'genre',
        render: (genre) => genre,
      },
      {
        title: '',
        key: 'action',
        fixed: 'right',
        render: (e) => {
          return (
            <div className={e.status}>
              <Button
                disabled={!permissions.includes('types')}
                onClick={() => history.push(`product-types/${e.id}`)}
                type="primary"
              >
                Edit
              </Button>
            </div>
          );
        },
      },
    ];
  };

  const filters = (history) => ({
    actions: [
      {
        label: '+ New Product Family',
        isActive: permissions.includes('types'),
        action: () => {
          history.push('/product-types/create');
        },
      },
    ],
  });

  return (
    <>
      <PageHeader className={'site-page-header'} title={'Product Family'} />
      <TableBlock
        columns={columns}
        filters={filters}
        request={getProductTypes}
        name={'getProductTypes'}
        pageSize={100}
      />
    </>
  );
}

export const ProductTypesForm = {
  title: 'Product Family',
  key: [],
  sections: [
    {
      columns: 1,
      fields: [
        {
          label: 'Order',
          key: ['order'],
          isRequired: true,
          type: 'number',
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Name (English)',
          key: ['name', 'en'],
          isRequired: true,
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Name (Chinese)',
          key: ['name', 'zh'],
          isRequired: true,
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Family',
          key: ['genre'],
          isRequired: true,
          type: 'select',
          options: [
            {
              label: 'Menswear',
              key: 'menswear',
            },
            {
              label: 'Fragrance',
              key: 'fragrance',
            },
          ],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Image',
          type: 'upload',
          isRequired: true,
          key: ['image'],
          requestURI: '/files/product-types',
          storageURI: '/storage/product-types',
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
      ],
    },
  ],
};
