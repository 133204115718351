import { INSTANCE, INSTANCELIST } from './API.js'

export const getProducts = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter, tabFilter } = queryKey[1]

    INSTANCELIST({
      method: 'get',
      url: '/products/details',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
        ...tabFilter,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const getProductDetails = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1]

    INSTANCE({
      method: 'get',
      url: `/products/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const getProductDetailsComplete = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1]

    INSTANCE({
      method: 'get',
      url: `/products/${id}/details`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const createProduct = (form) => {
  new Promise((resolve, reject) => {
    INSTANCELIST({
      method: 'post',
      url: '/products',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject)
  })
}

export const updateProduct = (form, id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/products/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject)
  })
