import { PageHeader } from 'antd';

import { getStores } from '../network/API';
import TableBlock from '../components/tables/TableBlock';

const Stores = () => {
  return (
    <>
      <PageHeader className="site-page-header" title="Stores" />
      <TableBlock
        columns={columns}
        name={'getStores'}
        request={getStores}
        infinite
      />
    </>
  );
};

export default Stores;

const columns = (_history, _keyRequest) => [
  {
    title: 'Store name',
    key: 'name.en',
    render: (name) => name?.en || name?.zh,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: 'Store ID',
    key: 'storeID',
    dataIndex: 'storeID',
    sorter: true,
  },
  {
    title: 'City',
    key: 'city',
    dataIndex: 'city',
    sorter: true,
  },
];
