import { Form, PageHeader } from 'antd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getGenre, updateGenre } from '../../network/Genres';
import FormWrap from '../../components/forms/FormWrap';
import { GenreForm } from './CreateGenre';
import FormBlock from '../../components/forms/FormBlock';
import { useState } from 'react';
import Loader from '../../components/Loader';

const EditGenre = ({ history }) => {
  let { genreId } = useParams();
  const [form] = Form.useForm();
  const [_, setTemplate] = useState(null);

  const { data, isLoading } = useQuery(['getGenre', genreId], getGenre, {
    onSuccess: (data) => {
      form.setFieldsValue(data);
    },
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <PageHeader
        className={'site-page-header'}
        title={'Edit genre'}
        onBack={() => history.push('/genres')}
        style={{ backgroundColor: '#fff' }}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={genreId}
          title={'update_genre'}
          request={updateGenre}
          cancelText={'Cancel'}
          okText={'Save'}
          data={data}
          onSuccess={() => history.push('/genres')}
          onCancel={() => history.push('/genres')}
          invalidate={['getGenres']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={GenreForm} />
          </div>
        </FormWrap>
      )}
    </>
  );
};

export default EditGenre;
