import { Form, PageHeader } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import { getCompanyDetails, updateCompany } from '../../network/B2B';
import Loader from '../../components/Loader';
import Error from '../../components/Error';

const EditCompanies = ({ history }) => {
  let { companyId } = useParams();
  const [form] = Form.useForm();

  const companyDetails = useQuery(
    [
      'getCompanyDetails',
      {
        id: companyId,
      },
    ],
    getCompanyDetails,
    {
      onSuccess: (data) => {
        form.setFieldsValue(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Edit company'}
        onBack={() => history.push('/companies')}
        style={{ backgroundColor: '#fff' }}
      />
      {companyDetails.isError ? (
        <Error retry={() => companyDetails.refetch()} />
      ) : companyDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={companyId}
          form={form}
          title="update_member"
          request={updateCompany}
          cancelText={'Cancel'}
          okText={'Save'}
          data={companyDetails.data}
          onCancel={() => history.push('/companies')}
          onSuccess={() => history.push('/companies')}
          // cleanBeforeSending={cleanBeforeSendingArea}
          invalidate={['getCompanies', 'getCompanyDetails']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={UniformDescriptionForm} />
            <FormBlock form={form} formTemplate={ProductsForm} />
          </div>
        </FormWrap>
      )}
    </>
  );
};

export default EditCompanies;

export const UniformDescriptionForm = {
  title: 'Description',
  key: [],
  sections: [
    {
      columns: 4,
      fields: [
        {
          label: 'Name',
          isRequired: true,
          key: ['name'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'City',
          isRequired: true,
          key: ['city'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Country',
          isRequired: true,
          key: ['country'],
          pos: {
            col: 2,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Zip Code',
          isRequired: true,
          key: ['zipCode'],
          pos: {
            col: 3,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Address',
          type: 'text',
          isRequired: true,
          key: ['address'],
          pos: {
            col: 1,
            row: 3,
            size: 2,
          },
        },
      ],
    },
  ],
};

export const ProductsForm = {
  title: 'Themes collection',
  sections: [
    {
      canAdd: true,
      key: ['themes'],
      title: 'Theme collection',
      addSuffix: 'number',
      columns: 3,
      // noDivider: true,
      deleteBtn: { label: 'Delete', style: 'title' },
      addBtn: { label: 'Add theme', style: 'primary' },
      fields: [
        {
          label: 'Name',
          key: ['name'],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
      sections: [
        {
          canAdd: true,
          key: ['roles'],
          // title: 'Role',
          // addSuffix: 'number',
          defaultOpen: true,
          columns: 6,
          noDivider: true,
          deleteBtn: {
            label: 'Delete',
            style: 'line',
            pos: {
              col: 2,
              row: 1,
              size: 1,
            },
          },
          addBtn: {
            label: 'Add position',
            style: 'dashed',
          },
          fields: [
            {
              label: 'Position',
              key: [],
              isRequired: true,
              pos: {
                col: 1,
                row: 1,
                size: 1,
              },
            },
          ],
        },
      ],
    },
  ],
};
