import get from 'lodash.get';

export const JacketDetailsForm = {
  title: 'Jacket details',
  display: 'Jacket',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        // {
        //   label: 'Price',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['price'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Jacket design',
          type: 'basicRadio',
          isRequired: false,
          key: ['jacketDesign'],
          options: [
            { key: 'design_notch', label: 'Notch' },
            { key: 'design_peak', label: 'Peak' },
            { key: 'design_shawl', label: 'Shawl' },
            { key: 'design_mao', label: 'Mao' },
            { key: 'design_double_breated', label: 'Double breated' },
            { key: 'design_safari', label: 'Safari' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['fabricRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Jacket shoulder',
          type: 'basicRadio',
          default: 'standand',
          isRequired: true,
          foldable: true,
          key: ['jacketShoulder'],
          options: [
            { key: 'standand', label: 'Standand' },
            { key: 'rounded', label: 'Rounded shoulder' },
            {
              key: 'napolitan_pleat',
              label: 'Napolitan pleat',
            },
            {
              key: 'high_position',
              label: 'High position',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Jacket style',
          type: 'select',
          key: ['suitStyle'],
          options: [
            {
              key: '1_button_single_breasted',
              label: '1 button single breasted',
            },
            {
              key: '2_button_single_breasted',
              label: '2 button single breasted',
            },
            {
              key: '3_button_single_breasted',
              label: '3 button single breasted',
            },
            {
              key: '4_button_single_breasted',
              label: '4 button single breasted',
            },
            {
              key: '6_1_button_single_breasted',
              label: '6/1 button single breasted',
            },
            {
              key: '6_2_button_single_breasted',
              label: '6/2 button single breasted',
            },
            {
              key: '5_button_single_breasted',
              label: '5 button single breasted',
            },
            {
              key: 'others',
              label: 'Others',
            },
          ],
          isRequired: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other Jacket Style',
          isRequired: true,
          placeholder: '-',
          key: ['otherSuitStyle'],
          isHiding: (values, name) =>
            get(values, `${name}suitStyle`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Lapel style',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['lapelStyle'],
          options: [
            { key: 'notch', label: 'Notch' },
            { key: 'peak', label: 'Peak' },
            { key: 'shawl', label: 'Shawl' },
            { key: 'half_peak', label: 'Half peak' },
            { key: 'fish_mouth', label: 'Fish mouth' },
            { key: 'mao_collar', label: 'Mao collar' },
            { key: 'other', label: 'Other' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Other Lapel Style',
          isRequired: true,
          placeholder: '-',
          key: ['otherLapelStyle'],
          isHiding: (values, name) =>
            get(values, `${name}lapelStyle`, '') !== 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Lapel width',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['lapelWidth'],
          options: [
            { key: '5.5', label: '5.5' },
            { key: '6', label: '6' },
            { key: '6.5', label: '6.5' },
            { key: '7', label: '7' },
            { key: '7.5', label: '7.5' },
            { key: '8', label: '8' },
            { key: '8.5', label: '8.5' },
            { key: '9', label: '9' },
            { key: '10', label: '10' },
            { key: '10.5', label: '10.5' },
            { key: '11', label: '11' },
            { key: '12', label: '12' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vents',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['vents'],
          options: [
            { key: 'side_vents', label: 'Side vents' },
            { key: 'central_vent', label: 'Central vent' },
            { key: 'no_vent', label: 'No vent' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Chest pocket',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['chestPocket'],
          options: [
            { key: 'straigth_2.5_standard', label: 'Straight 2.5cm standard' },
            { key: 'double_bessom', label: 'Double bessom (slim pocket)' },
            { key: 'boat_shape_2.5', label: 'Boat shape 2.5cm' },
            { key: 'chest_pocket_patch', label: 'Patch' },
            { key: 'chest_pocket_patch_flap', label: 'Patch with flap' },
            { key: 'no_pocket', label: 'No chest pocket' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Ticket pocket',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['ticketPocket'],
          options: [
            { key: 'no', label: 'No' },
            { key: 'with_flap', label: 'Ticket pocket with flap' },
            {
              key: 'slim_double_bessom',
              label: 'Ticket pocket slim double bessom',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lower pocket',
          type: 'basicRadio',
          default: 'patch',
          isRequired: true,
          key: ['lowerPocket'],
          options: [
            { key: 'straight_flap_4', label: 'Straight flap 4cm' },
            { key: 'slanted_flap_4', label: 'Slanted flap 4cm' },
            {
              key: 'straight_slim_double_bessom',
              label: 'Straight slim double bessom',
            },
            {
              key: 'slanted_slim_double_bessom',
              label: 'Slanted slim double bessom',
            },
            { key: 'patch_with_flap', label: 'Patch with flap' },
            { key: 'patch_with_button', label: 'Patch with button' },
            { key: 'patch', label: 'Patch (casual)' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lining craft',
          type: 'basicRadio',
          default: 'no_lining',
          isRequired: true,
          key: ['liningCraft'],
          options: [
            { key: 'full_lining', label: 'Full lining' },
            { key: 'half_lining', label: 'Half lining' },
            { key: 'no_lining', label: 'No lining' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lapel button hole position',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['lapelButtonHolePosition'],
          options: [
            { key: 'left', label: 'Left' },
            { key: 'both', label: 'Left & right' },
            { key: 'none', label: 'No button hole' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lapel button hole style',
          type: 'basicRadio',
          default: 'straight',
          isRequired: true,
          foldable: true,
          key: ['lapelButtonHoleSize'],
          isHiding: (values, name) =>
            get(values, `${name}lapelButtonHolePosition`, '') === 'none',
          options: [
            { key: 'handmade_milanese', label: 'Handmade milanese' },
            { key: 'rounded', label: 'Rounded' },
            { key: 'straight', label: 'Straight' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lapel button hole colors ref',
          isRequired: true,
          placeholder: '-',
          key: ['lapelButtonHoleColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}lapelButtonHolePosition`, '') === 'none',
          type: 'select',
          options: [
            { key: 'yz017', label: 'Dark blue (YZ017)' },
            { key: 'yz019', label: 'Black (YZ019)' },
            { key: 'yz034', label: 'Red wine (YZ034)' },
            { key: 'yz053', label: 'Dark gold (YZ053)' },
            { key: 'yz006', label: 'Medium grey (YZ006)' },
            { key: 'yz016', label: 'Medium blue (YZ016)' },
            { key: 'yz037', label: 'Brown (YZ037)' },
            { key: 'yz045', label: 'Green (YZ045)' },
            { key: 'yz012', label: 'Royal blue (YZ012)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other Lapel button hole color ref',
          isRequired: true,
          placeholder: '-',
          isHiding: (values, name) =>
            get(values, `${name}lapelButtonHolePosition`, '') === 'none' ||
            get(values, `${name}lapelButtonHoleColorRef`, '') !== 'others',
          key: ['otherLapelButtonHoleColorRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Button ref',
          isRequired: true,
          placeholder: '-',
          key: ['buttonRef'],
          type: 'select',
          options: [
            { key: 'fk503202', label: 'Classic black horn (FK503202)' },
            { key: 'fkE03237', label: 'Tinted horn blue (FKE03237)' },
            { key: 'fkE03236', label: 'Tinted horn red (FKE03236)' },
            { key: 'fk743204', label: 'Brown shell (FK743204)' },
            { key: 'fk523212', label: 'Grey shell (FK523212)' },
            { key: 'fk213204', label: 'Silk black (FK213204)' },
            { key: 'fk743203', label: 'White shell (FK743203)' },
            { key: 'fk523205', label: 'Brown horn (FK523205)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other button ref',
          isRequired: true,
          placeholder: '-',
          isHiding: (values, name) =>
            get(values, `${name}buttonRef`, '') !== 'others',
          key: ['otherButtonRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Lining ref',
          isRequired: true,
          placeholder: '-',
          key: ['liningRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Sleeve lining ref',
          type: 'basicRadio',
          isRequired: true,
          default: 'same_as_piping',
          key: ['sleeveLiningRef'],
          options: [
            { key: 'same_as_lining', label: 'Same as lining' },
            { key: 'same_as_piping', label: 'Same as piping' },
            // { key: 'white', label: 'White' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Piping + inner jets ref',
          isRequired: true,
          placeholder: '-',
          key: ['pipingInnerJetsRef'],
          type: 'select',
          options: [
            { key: 'wine', label: 'Red Wine' },
            { key: 'black', label: 'Black' },
            { key: 'pink_flash', label: 'Pink flash' },
            { key: 'orange', label: 'Orange' },
            { key: 'light_blue', label: 'Light blue' },
            { key: 'white', label: 'White' },
            { key: 'red', label: 'Red' },
            { key: 'dark_green', label: 'Dark green' },
            { key: 'light_grey', label: 'Light grey' },
            { key: 'dark_grey', label: 'Dark grey' },
            { key: 'royal_blue', label: 'Royal blue' },
            { key: 'dark_blue', label: 'Dark blue' },
            { key: 'light_pink', label: 'Light pink' },
            { key: 'dark_brown', label: 'Dark brown' },
            { key: 'gold', label: 'Gold' },
            { key: 'yellow', label: 'Yellow' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other piping + inner jets ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherPipingInnerJetsRef'],
          isHiding: (values, name) =>
            get(values, `${name}pipingInnerJetsRef`, '') !== 'others',
          pos: {
            col: 1,
            // row: 16,
            size: 1,
          },
        },
        {
          label: 'Inside chest pocket fabric',
          type: 'basicRadio',
          default: 'same_as_piping',
          isRequired: true,
          foldable: true,
          key: ['insideChestPocketFabric'],
          options: [
            { key: 'same_as_piping', label: 'Same as piping' },
            { key: 'white', label: 'White' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Add your monogram',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['addMonogram'],
          options: [
            { key: 'yes', label: 'yes' },
            { key: 'no', label: 'no' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Monogram text',
          isRequired: true,
          placeholder: '-',
          key: ['monogramText'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') === 'no',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Monogram color',
          type: 'basicRadio',
          default: 'gold_120',
          isRequired: true,
          foldable: true,
          key: ['monogramColor'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') === 'no',
          options: [{ key: 'gold_120', label: '120 GOLD' }],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Monogram font',
          type: 'basicRadio',
          default: 'garamond_6mm',
          isRequired: true,
          foldable: true,
          key: ['monogramFont'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') === 'no',
          options: [
            { key: 'lucida_handwritting_6mm', label: 'Lucida handwriting 6mm' },
            { key: 'garamond_6mm', label: 'Garamond 6mm' },
            { key: 'monotype_corsiva_6mm', label: 'Monotype Corsiva 6mm' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Melton ref',
          isRequired: true,
          foldable: true,
          placeholder: '-',
          key: ['meltonRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Sleeve button hole',
          type: 'basicRadio',
          default: 'none',
          isRequired: true,
          key: ['sleeveButtonHole'],
          foldable: true,
          options: [
            { key: '4_button_hole', label: '4 button hole' },
            { key: '3_button_hole', label: '3 button hole' },
            { key: '2_button_hole', label: '2 button hole' },
            { key: '1_button_hole', label: '1 button hole' },
            { key: 'none', label: 'No button hole' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },

        {
          label: 'Sleeve button hole style',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['sleeveButtonHoleStyle'],
          options: [
            { key: 'kissing', label: 'Kissing' },
            { key: 'separate', label: 'Separate' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Sleeve button hole color',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['sleeveButtonHoleColor'],
          options: [
            { key: 'yes', label: 'Yes' },
            { key: 'no', label: 'No' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Sleeve button hole color: which button hole with color?',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['sleeveButtonHoleColorWhich'],
          isHiding: (values, name) =>
            get(values, `${name}sleeveButtonHoleColor`, '') === 'no',
          options: [
            { key: '4th', label: 'The 4th button' },
            { key: '4th_3rd', label: 'The 4th + the 3rd' },
            { key: 'all', label: 'All button with color' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Sleeve hole color ref',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['sleeveHoleColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}sleeveButtonHoleColor`, '') === 'no',
          options: [
            { key: 'yz017', label: 'Dark blue (YZ017)' },
            { key: 'yz019', label: 'Black (YZ019)' },
            { key: 'yz034', label: 'Red wine (YZ034)' },
            { key: 'yz053', label: 'Dark gold (YZ053)' },
            { key: 'yz006', label: 'Medium grey (YZ006)' },
            { key: 'yz016', label: 'Medium blue (YZ016)' },
            { key: 'yz037', label: 'Brown (YZ037)' },
            { key: 'yz045', label: 'Green (YZ045)' },
            { key: 'yz012', label: 'Royal blue (YZ012)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other sleeve hole color ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherSleeveHoleColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}sleeveButtonHoleColor`, '') === 'no' ||
            get(values, `${name}sleeveHoleColorRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Contrast color',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['contrastColor'],
          options: [
            { key: 'yes', label: 'Yes' },
            { key: 'no', label: 'No' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast color ref',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['contrastColorRef'],
          options: [
            { key: 'fe029023', label: 'Black silk (FE029023)' },
            { key: 'fe029025', label: 'Blue silk (FE029025)' },
            { key: 'fp010020', label: 'Red suede (FP010020)' },
            { key: 'fp015782', label: 'Dark blue suede (FP015782)' },
            { key: 'fp010043', label: 'Navy blue suede (FP010043)' },
            { key: 'fp011014', label: 'Brown suede (FP011014)' },
            { key: 'fp010114', label: 'Grey suede (FP010114)' },
            { key: 'fe029024', label: 'White silk (FE029024)' },
            { key: 'others', label: 'Others' },
          ],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') === 'no',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Others contrast color ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherContrastColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') === 'no' ||
            get(values, `${name}contrastColorRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Contrast position',
          type: 'multiSelect',
          key: ['contrastPositionMulti'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') === 'no',
          options: [
            { key: 'collar', label: 'Collar' },
            { key: 'lapel', label: 'Lapel' },
            { key: 'chest_pocket', label: 'Chest pocket' },
            {
              key: 'lower_pocket_banch_only',
              label: 'Lower pocket banch only (if flap pocket)',
            },
            { key: 'lower_pocket', label: 'Lower pocket' },
            { key: 'elbow_patch_rounded', label: 'Elbow patch rounded' },
            { key: 'cut_out_lapel_edge_1cm', label: 'Cut out lapel edge 1cm' },
          ],
          isRequired: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Canva craft',
          type: 'basicRadio',
          default: 'premium',
          isRequired: true,
          foldable: true,
          key: ['canvaCraft'],
          options: [
            { key: 'classic', label: 'Classic' },
            { key: 'premium', label: 'Premium' },
            { key: 'bespoke', label: 'Bespoke' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Fit preference',
          type: 'basicRadio',
          default: 'standard_fit',
          isRequired: true,
          foldable: true,
          key: ['fitPreference'],
          options: [
            { key: 'slim_fit', label: 'Slim fit' },
            { key: 'standard_fit', label: 'Standard' },
            { key: 'loose_fit', label: 'Loose' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        // {
        //   label: 'Other specificities',
        //   // isRequired: true,
        //   placeholder: '-',
        //   key: ['otherSpecificities'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          foldable: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const JacketSuitDetailsForm = {
  title: 'Jacket details',
  display: 'Jacket',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        // {
        //   label: 'Price',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['price'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Jacket design',
          type: 'basicRadio',
          isRequired: false,
          key: ['jacketDesign'],
          options: [
            { key: 'design_notch', label: 'Notch' },
            { key: 'design_peak', label: 'Peak' },
            { key: 'design_shawl', label: 'Shawl' },
            { key: 'design_mao', label: 'Mao' },
            { key: 'design_double_breated', label: 'Double breated' },
            { key: 'design_safari', label: 'Safari' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['fabricRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },

        {
          label: 'Jacket shoulder',
          type: 'basicRadio',
          default: 'standand',
          isRequired: true,
          key: ['jacketShoulder'],
          foldable: true,
          options: [
            { key: 'standand', label: 'Standand' },
            { key: 'rounded', label: 'Rounded shoulder' },
            {
              key: 'napolitan_pleat',
              label: 'Napolitan pleat',
            },
            {
              key: 'high',
              label: 'High ',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        // {
        //   label: 'Fabric ref',
        //   type: 'select',
        //   key: ['fabricRef'],
        //   options: [
        //     {
        //       key: 'same_as_fabric',
        //       label: 'Same as fabric',
        //     },
        //     {
        //       key: 'other_fabrics',
        //       label: 'Other fabrics',
        //     },
        //   ],
        //   isRequired: true,
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Jacket style',
          type: 'select',
          key: ['suitStyle'],
          options: [
            {
              key: '1_button_single_breasted',
              label: '1 button single breasted',
            },
            {
              key: '2_button_single_breasted',
              label: '2 button single breasted',
            },
            {
              key: '3_button_single_breasted',
              label: '3 button single breasted',
            },
            {
              key: '4_button_single_breasted',
              label: '4 button single breasted',
            },
            {
              key: '6_1_button_single_breasted',
              label: '6/1 button single breasted',
            },
            {
              key: '6_2_button_single_breasted',
              label: '6/2 button single breasted',
            },
            {
              key: '5_button_single_breasted',
              label: '5 button single breasted',
            },
            {
              key: 'others',
              label: 'Others',
            },
          ],
          isRequired: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other Jacket Style',
          isRequired: true,
          placeholder: '-',
          key: ['otherSuitStyle'],
          isHiding: (values, name) =>
            get(values, `${name}suitStyle`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Lapel style',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['lapelStyle'],
          options: [
            { key: 'notch', label: 'Notch' },
            { key: 'peak', label: 'Peak' },
            { key: 'shawl', label: 'Shawl' },
            { key: 'half_peak', label: 'Half peak' },
            { key: 'fish_mouth', label: 'Fish mouth' },
            { key: 'mao_collar', label: 'Mao collar' },
            { key: 'other', label: 'Other' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Other Lapel Style',
          isRequired: true,
          placeholder: '-',
          key: ['otherLapelStyle'],
          isHiding: (values, name) =>
            get(values, `${name}lapelStyle`, '') !== 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Lapel width',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['lapelWidth'],
          options: [
            { key: '5.5', label: '5.5' },
            { key: '6', label: '6' },
            { key: '6.5', label: '6.5' },
            { key: '7', label: '7' },
            { key: '7.5', label: '7.5' },
            { key: '8', label: '8' },
            { key: '8.5', label: '8.5' },
            { key: '9', label: '9' },
            { key: '10', label: '10' },
            { key: '10.5', label: '10.5' },
            { key: '11', label: '11' },
            { key: '12', label: '12' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vents',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['vents'],
          options: [
            { key: 'side_vents', label: 'Side vents' },
            { key: 'central_vent', label: 'Central vent' },
            { key: 'no_vent', label: 'No vent' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Chest pocket',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['chestPocket'],
          options: [
            { key: 'straigth_2.5_standard', label: 'Straight 2.5cm standard' },
            { key: 'double_bessom', label: 'Double bessom (slim pocket)' },
            { key: 'boat_shape_2.5', label: 'Boat shape 2.5cm' },
            { key: 'chest_pocket_patch', label: 'Patch' },
            { key: 'chest_pocket_patch_flap', label: 'Patch with flap' },
            { key: 'no_pocket', label: 'No chest pocket' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Ticket pocket',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['ticketPocket'],
          options: [
            { key: 'no', label: 'No' },
            { key: 'with_flap', label: 'Ticket pocket with flap' },
            {
              key: 'slim_double_bessom',
              label: 'Ticket pocket slim double bessom',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lower pocket',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['lowerPocket'],
          options: [
            { key: 'straight_flap_4', label: 'Straight flap 4cm' },
            { key: 'slanted_flap_4', label: 'Slanted flap 4cm' },
            {
              key: 'straight_slim_double_bessom',
              label: 'Straight slim double bessom',
            },
            {
              key: 'slanted_slim_double_bessom',
              label: 'Slanted slim double bessom',
            },
            { key: 'patch_with_flap', label: 'Patch with flap' },
            { key: 'patch', label: 'Patch (casual)' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lining craft',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['liningCraft'],
          options: [
            { key: 'full_lining', label: 'Full lining' },
            { key: 'half_lining', label: 'Half lining' },
            { key: 'no_lining', label: 'No lining' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lapel button hole position',
          type: 'basicRadio',
          default: 'none',
          isRequired: true,
          foldable: true,
          key: ['lapelButtonHolePosition'],
          options: [
            { key: 'left', label: 'Left' },
            { key: 'both', label: 'Left & right' },
            { key: 'none', label: 'No button hole' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lapel button hole style',
          type: 'basicRadio',
          default: 'straight',
          isRequired: true,
          foldable: true,
          key: ['lapelButtonHoleSize'],
          isHiding: (values, name) =>
            get(values, `${name}lapelButtonHolePosition`, '') === 'none',
          options: [
            { key: 'handmade_milanese', label: 'Handmade milanese' },
            { key: 'rounded', label: 'Rounded' },
            { key: 'straight', label: 'Straight' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Lapel button hole colors ref',
          isRequired: true,
          placeholder: '-',
          key: ['lapelButtonHoleColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}lapelButtonHolePosition`, '') === 'none',
          type: 'select',
          options: [
            { key: 'yz017', label: 'Dark blue (YZ017)' },
            { key: 'yz019', label: 'Black (YZ019)' },
            { key: 'yz034', label: 'Red wine (YZ034)' },
            { key: 'yz053', label: 'Dark gold (YZ053)' },
            { key: 'yz006', label: 'Medium grey (YZ006)' },
            { key: 'yz016', label: 'Medium blue (YZ016)' },
            { key: 'yz037', label: 'Brown (YZ037)' },
            { key: 'yz045', label: 'Green (YZ045)' },
            { key: 'yz012', label: 'Royal blue (YZ012)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other Lapel button hole color ref',
          isRequired: true,
          placeholder: '-',
          isHiding: (values, name) =>
            get(values, `${name}lapelButtonHolePosition`, '') === 'none' ||
            get(values, `${name}lapelButtonHoleColorRef`, '') !== 'others',
          key: ['otherLapelButtonHoleColorRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Button ref',
          isRequired: true,
          placeholder: '-',
          key: ['buttonRef'],
          type: 'select',
          options: [
            { key: 'fk503202', label: 'Classic black horn (FK503202)' },
            { key: 'fkE03237', label: 'Tinted horn blue (FKE03237)' },
            { key: 'fkE03236', label: 'Tinted horn red (FKE03236)' },
            { key: 'fk743204', label: 'Brown shell (FK743204)' },
            { key: 'fk523212', label: 'Grey shell (FK523212)' },
            { key: 'fk213204', label: 'Silk black (FK213204)' },
            { key: 'fk743203', label: 'White shell (FK743203)' },
            { key: 'fk523205', label: 'Brown horn (FK523205)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other button ref',
          isRequired: true,
          placeholder: '-',
          isHiding: (values, name) =>
            get(values, `${name}buttonRef`, '') !== 'others',
          key: ['otherButtonRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },

        {
          label: 'Lining ref',
          isRequired: true,
          placeholder: '-',
          key: ['liningRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Sleeve lining ref',
          type: 'basicRadio',
          isRequired: true,
          default: 'same_as_piping',
          key: ['sleeveLiningRef'],
          options: [
            { key: 'same_as_lining', label: 'Same as lining' },
            { key: 'same_as_piping', label: 'Same as piping' },
            // { key: 'white', label: 'White' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Piping + inner jets ref',
          isRequired: true,
          placeholder: '-',
          key: ['pipingInnerJetsRef'],
          type: 'select',
          options: [
            { key: 'wine', label: 'Red Wine' },
            { key: 'black', label: 'Black' },
            { key: 'pink_flash', label: 'Pink flash' },
            { key: 'orange', label: 'Orange' },
            { key: 'light_blue', label: 'Light blue' },
            { key: 'white', label: 'White' },
            { key: 'red', label: 'Red' },
            { key: 'dark_green', label: 'Dark green' },
            { key: 'light_grey', label: 'Light grey' },
            { key: 'dark_grey', label: 'Dark grey' },
            { key: 'royal_blue', label: 'Royal blue' },
            { key: 'dark_blue', label: 'Dark blue' },
            { key: 'light_pink', label: 'Light pink' },
            { key: 'dark_brown', label: 'Dark brown' },
            { key: 'gold', label: 'Gold' },
            { key: 'yellow', label: 'Yellow' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other piping + inner jets ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherPipingInnerJetsRef'],
          isHiding: (values, name) =>
            get(values, `${name}pipingInnerJetsRef`, '') !== 'others',
          pos: {
            col: 1,
            // row: 16,
            size: 1,
          },
        },
        {
          label: 'Inside chest pocket fabric',
          type: 'basicRadio',
          default: 'same_as_piping',
          isRequired: true,
          foldable: true,
          key: ['insideChestPocketFabric'],
          options: [
            { key: 'same_as_piping', label: 'Same as piping' },
            { key: 'white', label: 'White' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Add your monogram',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['addMonogram'],
          options: [
            { key: 'yes', label: 'yes' },
            { key: 'no', label: 'no' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Monogram text',
          isRequired: true,
          placeholder: '-',
          key: ['monogramText'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') === 'no',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Monogram color',
          type: 'basicRadio',
          default: 'gold_120',
          isRequired: true,
          foldable: true,
          key: ['monogramColor'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') === 'no',
          options: [{ key: 'gold_120', label: '120 GOLD' }],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Monogram font',
          type: 'basicRadio',
          default: 'garamond_6mm',
          isRequired: true,
          foldable: true,
          key: ['monogramFont'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') === 'no',
          options: [
            { key: 'lucida_handwritting_6mm', label: 'Lucida handwriting 6mm' },
            { key: 'garamond_6mm', label: 'Garamond 6mm' },
            { key: 'monotype_corsiva_6mm', label: 'Monotype Corsiva 6mm' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Melton ref',
          isRequired: true,
          placeholder: '-',
          foldable: true,
          key: ['meltonRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Sleeve button hole',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['sleeveButtonHole'],
          foldable: true,
          options: [
            { key: '4_button_hole', label: '4 button hole' },
            { key: '3_button_hole', label: '3 button hole' },
            { key: '2_button_hole', label: '2 button hole' },
            { key: '1_button_hole', label: '1 button hole' },
            { key: 'none', label: 'No button hole' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },

        {
          label: 'Sleeve button hole style',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['sleeveButtonHoleStyle'],
          options: [
            { key: 'kissing', label: 'Kissing' },
            { key: 'separate', label: 'Separate' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Sleeve button hole color',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['sleeveButtonHoleColor'],
          options: [
            { key: 'yes', label: 'Yes' },
            { key: 'no', label: 'No' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Sleeve button hole color: which button hole with color?',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['sleeveButtonHoleColorWhich'],
          isHiding: (values, name) =>
            get(values, `${name}sleeveButtonHoleColor`, '') === 'no',
          options: [
            { key: '4th', label: 'The 4th button' },
            { key: '4th_3rd', label: 'The 4th + the 3rd' },
            { key: 'all', label: 'All button with color' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Sleeve hole color ref',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['sleeveHoleColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}sleeveButtonHoleColor`, '') === 'no',
          options: [
            { key: 'yz017', label: 'Dark blue (YZ017)' },
            { key: 'yz019', label: 'Black (YZ019)' },
            { key: 'yz034', label: 'Red wine (YZ034)' },
            { key: 'yz053', label: 'Dark gold (YZ053)' },
            { key: 'yz006', label: 'Medium grey (YZ006)' },
            { key: 'yz016', label: 'Medium blue (YZ016)' },
            { key: 'yz037', label: 'Brown (YZ037)' },
            { key: 'yz045', label: 'Green (YZ045)' },
            { key: 'yz012', label: 'Royal blue (YZ012)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other sleeve hole color ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherSleeveHoleColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}sleeveButtonHoleColor`, '') === 'no' ||
            get(values, `${name}sleeveHoleColorRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Contrast color',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['contrastColor'],
          options: [
            { key: 'yes', label: 'Yes' },
            { key: 'no', label: 'No' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast color ref',
          isRequired: true,
          placeholder: '-',
          key: ['contrastColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') === 'no',
          type: 'select',
          options: [
            { key: 'fe029023', label: 'Black silk (FE029023)' },
            { key: 'fe029025', label: 'Blue silk (FE029025)' },
            { key: 'fp010020', label: 'Red suede (FP010020)' },
            { key: 'fp015782', label: 'Dark blue suede (FP015782)' },
            { key: 'fp010043', label: 'Navy blue suede (FP010043)' },
            { key: 'fp011014', label: 'Brown suede (FP011014)' },
            { key: 'fp010114', label: 'Grey suede (FP010114)' },
            { key: 'fe029024', label: 'White silk (FE029024)' },
            { key: 'others', label: 'Others' },
          ],

          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Others contrast color ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherContrastColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') === 'no' ||
            get(values, `${name}contrastColorRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Contrast position',
          type: 'multiSelect',
          key: ['contrastPositionMulti'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') === 'no',
          options: [
            { key: 'collar', label: 'Collar' },
            { key: 'lapel', label: 'Lapel' },
            { key: 'chest_pocket', label: 'Chest pocket' },
            {
              key: 'lower_pocket_banch_only',
              label: 'Lower pocket banch only (if flap pocket)',
            },
            { key: 'lower_pocket', label: 'Lower pocket' },
            { key: 'elbow_patch_rounded', label: 'Elbow patch rounded' },
            { key: 'cut_out_lapel_edge_1cm', label: 'Cut out lapel edge 1cm' },
          ],
          isRequired: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Canva craft',
          type: 'basicRadio',
          default: 'premium',
          isRequired: true,
          foldable: true,
          key: ['canvaCraft'],
          options: [
            { key: 'classic', label: 'Classic' },
            { key: 'premium', label: 'Premium' },
            { key: 'bespoke', label: 'Bespoke' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Fit preference',
          type: 'basicRadio',
          default: 'standard_fit',
          isRequired: true,
          foldable: true,
          key: ['fitPreference'],
          options: [
            { key: 'slim_fit', label: 'Slim fit' },
            { key: 'standard_fit', label: 'Standard' },
            { key: 'loose_fit', label: 'Loose' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        // {
        //   label: 'Other specificities',
        //   // isRequired: true,
        //   placeholder: '-',
        //   key: ['otherSpecificities'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          foldable: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const JacketMeasureForm = {
  title: 'Final measurement Jacket',
  display: 'Jacket',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Jacket length front',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['lengthFront'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Jacket length back',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['lengthBack'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        //
        {
          label: 'Shoulder',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['shoulder'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Sleeve length',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['sleeve'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Chest',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['chest'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Waist',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['waist'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Stomach',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['stomach'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Hips',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['lowerHerm'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Arm width (biceps)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['biceps'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Arm hole',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['armHole'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Jacket sleeve opening (22~32)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['sleeveWrist'],
          default: 27,
          min: 22,
          max: 32,
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Cuff jacket opening',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['cuffOpening'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
      ],
    },
  ],
};
