import { Button, PageHeader } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { ROLE_PERMISSIONS } from '../../constants/common';
import TableBlock from '../../components/tables/TableBlock';
import { deleteGenre, getGenres } from '../../network/Genres';

export default function Genre({ _history }) {
  const { user } = useAuth();
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : [];

  const columns = (history, keyRequest) => [
    {
      title: 'Name',
      key: 'name.en',
      dataIndex: 'name',
      render: (name) => name.en,
    },
    {
      title: 'Name (Chinese)',
      key: 'name.zh',
      dataIndex: 'name',
      render: (name) => name.zh,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      render: (e) => {
        return (
          <div className={e.status}>
            <Button
              disabled={!permissions.includes('genres')}
              onClick={() => history.push(`genres/${e.id}`)}
              type="primary"
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];

  const filters = (history) => ({
    actions: [
      {
        label: '+ New Genre',
        isActive: permissions.includes('genres'),
        action: () => {
          history.push('/genres/create');
        },
      },
    ],
  });

  return (
    <>
      <PageHeader className="site-page-header" title="Genre" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getGenres'}
        request={getGenres}
        pageSize={100}
      />
    </>
  );
}
