import get from 'lodash.get';
export const CashmereDetailsForm = {
  title: 'Cachemere details',
  display: 'Cachemere',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Design',
          type: 'select',
          isRequired: true,
          key: ['cashmereDesignRef'],
          options: [
            { key: 'cashl', label: '1 - Cardigan pocket' },
            { key: 'cash2', label: '2 - Cardigan button' },
            { key: 'cash3', label: '3 - Cardigan' },
            { key: 'cash4', label: '4 - Round neck classic' },
            { key: 'cash5', label: '5 - Round neck casual 2' },
            { key: 'cash6', label: '6 - Zip neck' },
            { key: 'cash7', label: '7 - Turtle neck' },
            { key: 'cash8', label: '8 - V neck classic' },
            { key: 'cash9', label: '9 - V neck casual' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['fabricRef'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Size in cm',
          type: 'basicRadio',
          isRequired: true,
          key: ['size'],
          options: [
            { key: '48', label: '48' },
            { key: '50', label: '50' },
            { key: '52', label: '52' },
            { key: '54', label: '54' },
            { key: '56', label: '56' },
            { key: '58', label: '58' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Input size',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['otherSize'],
          isHiding: (values, name) =>
            get(values, `${name}size`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const CashmereMeasureForm = {
  title: 'Final measurement Cashemere',
  display: 'Cashmere',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [],
    },
  ],
};
