import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, PageHeader, Spin, Upload } from 'antd';
import { useEffect, useState } from 'react';
import TableBlock from '../../components/tables/TableBlock';
import { ROLE_PERMISSIONS } from '../../constants/common';
import { useAuth } from '../../hooks/useAuth';
import { getMPLandingSections } from '../../network/MP';

function VideoFrame({ id, url }) {
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);

  useEffect(() => {
    const checkVideoAvailability = async () => {
      try {
        const response = await fetch(url, {
          method: 'HEAD', // Use HEAD to fetch headers only, for efficiency
        });

        if (response.ok) {
          setIsVideoAvailable(true);
        } else {
          setIsVideoAvailable(false);
        }
      } catch (error) {
        setIsVideoAvailable(false);
      }
    };

    if (url) {
      checkVideoAvailability();
    }
  }, [url]);

  return (
    <div>
      {isVideoAvailable ? (
        <video
          key={id}
          src={url}
          controls
          style={{
            width: '100%',
            maxHeight: '300px',
            marginRight: 'auto',
          }}
        />
      ) : (
        <span>No Video</span>
      )}
    </div>
  );
}

export default function MiniProgram() {
  const { user } = useAuth();
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : [];

  const columns = (history, keyRequest) => [
    {
      title: 'Order',
      key: 'order',
      dataIndex: 'order',
      sorter: true,
    },
    {
      title: 'Title',
      key: 'titleGroup',
      dataIndex: 'titleGroup',
      render: (titleGroup) => {
        console.log(titleGroup);
        if (!titleGroup) return;
        if (titleGroup.length === 1) return titleGroup[0].title.en;
        return titleGroup
          .map((title, index) => {
            return title.title.en;
          })
          .join(',');
      },
    },
    {
      title: 'Title (Chinese)',
      key: 'titleGroup',
      dataIndex: 'titleGroup',
      render: (titleGroup) => {
        if (!titleGroup) return;
        if (titleGroup.length === 1) return titleGroup[0].title.zh;
        return titleGroup
          .map((title, index) => {
            return title.title.zh;
          })
          .join(',');
      },
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      render: (e) => {
        return (
          <div className={e.status}>
            <Button
              disabled={!permissions.includes('mp')}
              onClick={() => history.push(`mp/${e.id}`)}
              type="primary"
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];

  const filters = (history) => ({
    actions: [
      {
        label: '+ New Section',
        isActive: permissions.includes('mp'),
        action: () => {
          history.push('/mp/create');
        },
      },
    ],
  });

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Mini Program Landing Sections"
      />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getMPLandingSections'}
        request={getMPLandingSections}
      />
      <VideoUpload />
    </>
  );
}

function VideoUpload() {
  const [isUploading, setIsUploading] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());

  return (
    <Card className="form-wrapper-card" title="Video Upload">
      <div
        style={{
          display: 'flex',
          flexShrink: 1,
          flexGrow: 0,
        }}
      >
        <Upload
          name="video"
          action={`${process.env.REACT_APP_API_URL}/mp/video`}
          showUploadList={false}
          customRequest={({ file }) => {
            const formData = new FormData();
            formData.append('video', file);

            setIsUploading(true);

            fetch(`${process.env.REACT_APP_API_URL}/mp/video`, {
              method: 'POST',
              body: formData,
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }).finally(() => {
              setIsUploading(false);
              setTimeout(() => {
                setTimestamp(Date.now());
              }, 1000)
            });
          }}
          listType="picture-card"
          // className="upload-list-inline"
        >
          <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>Upload *.mp4</div>
          </div>
        </Upload>
        <div
          style={{
            display: 'block',
          }}
        >
          {isUploading && (
            <Spin size="large" />
          )}
          {!isUploading && (
            <VideoFrame
              url={`${process.env.REACT_APP_API_URL}/storage/mp-landing/video.mp4?t=${timestamp}`}
            />
          )}
        </div>
      </div>
    </Card>
  );
}
