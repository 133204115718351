import get from 'lodash.get';

export const JeanDetailsForm = {
  title: 'Jean details',
  display: 'Jeans',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        // {
        //   label: 'Price',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['price'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },

        {
          label: 'Color ref',
          type: 'select',
          isRequired: true,
          key: ['colorRef'],
          options: [
            // { key: 'dark_blue', label: 'Dark blue (7308J2-IC2)' },
            // { key: 'middle_blue', label: 'Middle blue' },
            // { key: 'light_blue', label: 'Light blue' },
            // { key: 'black', label: 'Black' },
            // { key: 'full_black', label: 'Full black' },
            // { key: 'grey', label: 'Grey' },
            // { key: 'full_grey', label: 'Full grey' },
            // { key: 'white', label: 'White' },
            { key: 'r1', label: 'R1 - Dark blue (7308J2-IC2)' },
            { key: 'r2', label: 'R2 - Middle Blue (7308J2-IC2)' },
            { key: 'r3', label: 'R3 - Light blue (7308J2-IC2)' },
            { key: 'r4', label: 'R4 - Black (E6311)' },
            { key: 'r5', label: 'R5 - Middle grey (E6311)' },
            { key: 'r6', label: 'R6 - Light grey (E6311)' },
            { key: 'r7', label: 'R7 - White (TU8689F-W1)' },
            { key: 'r8', label: 'R8 - Full black (E6311)' },
            { key: 'other', label: 'Others' },
          ],
          // onChangeRef: ['fabricRef'],
          // onChange: (value) => {
          //   switch (value) {
          //     case 'dark_blue':
          //     case 'middle_blue':
          //     case 'light_blue':
          //       return '7308J2-IC2'
          //     case 'black':
          //     case 'grey':
          //       return 'E6311'
          //     case 'white':
          //       return 'TU8689F-W1'
          //     default:
          //       return ''
          //   }
          // },
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
        {
          label: 'Other Color ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}colorRef`, '') !== 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Fabric ref',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['fabricRef'],
        //   pos: {
        //     col: 1,
        //     // row: 2,
        //     size: 1,
        //   },
        // },
        {
          label: 'Thread color ref',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['threadColorRef'],
          onChangeRef: ['initialThreadRef'],
          onChange: (value) => {
            return value;
          },
          options: [
            { key: 'j01', label: 'J01 - Gold' },
            { key: 'j02', label: 'J02 - Light gold' },
            { key: 'j03', label: 'J03 - Black' },
            { key: 'j04', label: 'J04 - Dark blue' },
            { key: 'j05', label: 'J05 - White' },
            { key: 'j06', label: 'J06 - Medium gold' },
            { key: 'j07', label: 'J07 - Red' },
          ],
          pos: {
            col: 1,
            // row: 3,
            size: 1,
          },
        },
        {
          label: 'Pocket fabric ref',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['pocketFabricRef'],
          options: [
            { key: 'afj001', label: 'AFJ001' },
            { key: 'afj002', label: 'AFJ002' },
            { key: 'afj003', label: 'AFJ003' },
            { key: 'afj004', label: 'AFJ004' },
            { key: 'afj005', label: 'AFJ005' },
            { key: 'afj006', label: 'AFJ006' },
            { key: 'afj007', label: 'AFJ007' },
            { key: 'afj008', label: 'AFJ008' },
            { key: 'afj009', label: 'AFJ009' },
            { key: 'afj010', label: 'AFJ010' },
            { key: 'afj011', label: 'AFJ011' },
            { key: 'afj012', label: 'AFJ012' },
          ],
          pos: {
            col: 1,
            // row: 4,
            size: 1,
          },
        },
        {
          label: 'Initial',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          default: 'yes',
          key: ['initial'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
            {
              key: 'af_logo',
              label: 'AF Logo',
            },
            {
              key: 'other',
              label: 'Other',
            },
          ],
          pos: {
            col: 1,
            // row: 5,
            size: 1,
          },
        },
        {
          label: 'Other Initial',
          isRequired: true,
          placeholder: '-',
          key: ['otherInitial'],
          isHiding: (values, name) =>
            get(values, `${name}initial`, '') !== 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Initial text',
          isRequired: true,
          placeholder: '-',
          key: ['initialText'],
          isHiding: (values, name) =>
            get(values, `${name}initial`, '') !== 'yes',
          pos: {
            col: 1,
            // row: 6,
            size: 1,
          },
        },
        {
          label: 'Initial position',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          default: 'yes',
          key: ['initialPosition'],
          isHiding: (values, name) =>
            get(values, `${name}initial`, '') !== 'yes',
          options: [
            {
              key: 'inside',
              label: 'Inside',
            },
            {
              key: 'left_pocket',
              label: 'Left pocket',
            },
            {
              key: 'both',
              label: 'Both',
            },
          ],
          pos: {
            col: 1,
            // row: 7,
            size: 1,
          },
        },
        {
          label: 'Initial thread',
          type: 'basicRadio',
          isRequired: true,
          default: 'yes',
          key: ['initialThread'],
          isHiding: (values, name) =>
            get(values, `${name}initial`, '') !== 'yes',
          options: [
            {
              key: 'same_as_jean',
              label: 'Same as jean thread',
            },
            {
              key: 'other',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            // row: 8,
            size: 1,
          },
        },
        {
          label: 'Other Initial thread ref',
          type: 'basicRadio',
          isRequired: true,
          key: ['otherInitialThreadRef'],
          isHiding: (values, name) =>
            get(values, `${name}initialThread`, '') !== 'other' ||
            get(values, `${name}initial`, '') !== 'yes',
          options: [
            { key: 'j01', label: 'J01 - Gold' },
            { key: 'j02', label: 'J02 - Light gold' },
            { key: 'j03', label: 'J03 - Black' },
            { key: 'j04', label: 'J04 - Dark blue' },
            { key: 'j05', label: 'J05 - White' },
            { key: 'j06', label: 'J06 - Medium gold' },
            { key: 'j07', label: 'J07 - Red' },
            { key: 'others', label: 'Others' },
          ],
        },
        {
          label: 'Other Initial thread color',
          isRequired: true,
          placeholder: '-',
          key: ['otherInitialThreadColor'],
          isHiding: (values, name) =>
            get(values, `${name}otherInitialThreadRef`, '') !== 'others' ||
            get(values, `${name}initial`, '') !== 'yes',
          pos: {
            col: 1,
            // row: 9,
            size: 1,
          },
        },
        {
          label: 'Initial thread ref',
          type: 'basicRadio',
          isRequired: true,
          default: 'yes',
          key: ['initialThreadRef'],
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}initialThread`, '') === 'other' ||
            get(values, `${name}initial`, '') !== 'yes',
          options: [
            { key: 'j01', label: 'J01 - Gold' },
            { key: 'j02', label: 'J02 - Light gold' },
            { key: 'j03', label: 'J03 - Black' },
            { key: 'j04', label: 'J04 - Dark blue' },
            { key: 'j05', label: 'J05 - White' },
            { key: 'j06', label: 'J06 - Medium gold' },
            { key: 'j07', label: 'J07 - Red' },
            { key: 'other', label: 'Other' },
          ],
          pos: {
            col: 1,
            // row: 8,
            size: 1,
          },
        },
        {
          label: 'Initial thread ref others',
          isRequired: false,
          placeholder: '-',
          key: ['initialThreadRefOther'],
          isHiding: (values, name) =>
            get(values, `${name}initialThreadRef`, '') !== 'others' ||
            get(values, `${name}initial`, '') !== 'yes',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Initial height (cm)',
          type: 'number',
          isRequired: true,
          foldable: true,
          default: 0.6,
          placeholder: '-',
          key: ['initialHeight'],
          isHiding: (values, name) =>
            get(values, `${name}initial`, '') !== 'yes',
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        {
          label: 'Waist option',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          default: 'yes',
          key: ['waistOption'],
          options: [
            {
              key: 'belt_loop',
              label: 'Belt loop',
            },
            {
              key: 'side_adjustment',
              label: 'Side adjustment',
            },
          ],
          pos: {
            col: 1,
            // row: 11,
            size: 1,
          },
        },
        {
          label: 'Side adjustment',
          isRequired: true,
          placeholder: '-',
          key: ['sideAjustment'],
          isHiding: (values, name) =>
            get(values, `${name}waistOption`, '') !== 'side_adjustment',
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        {
          label: 'Washing',
          type: 'basicRadio',
          isRequired: true,
          default: 'yes',
          key: ['washing'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            // row: 11,
            size: 1,
          },
        },
        {
          label: 'Fly options',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          default: 'yes',
          key: ['flyOptions'],
          options: [
            {
              key: 'zipper',
              label: 'Zipper',
            },
            {
              key: 'button',
              label: 'Button',
            },
          ],
          pos: {
            col: 1,
            // row: 11,
            size: 1,
          },
        },
        {
          label: 'Button set',
          type: 'basicRadio',

          isHiding: (values, name) =>
            get(values, `${name}flyOptions`, '') !== 'button',
          isRequired: true,
          default: 'yes',
          key: ['buttonSet'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            // row: 11,
            size: 1,
          },
        },
        {
          label: 'Button set ref',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['buttonSetRef'],
          options: [
            { key: 'simple_cooper', label: 'Simple cooper' },
            { key: 'simple_gold', label: 'Simple gold' },
            { key: 'simple_silver', label: 'Simple silver' },
            { key: 'logo_cooper', label: 'Logo cooper' },
            { key: 'logo_gold', label: 'Logo gold' },
            { key: 'logo_silver', label: 'Logo silver' },
            { key: 'others', label: 'Others' },
          ],
          isHiding: (values, name) =>
            get(values, `${name}buttonSet`, '') !== 'yes' ||
            get(values, `${name}flyOptions`, '') !== 'button',
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        //
        {
          label: 'Button ref 1',
          isRequired: true,
          placeholder: '-',
          key: ['button1Ref'],
          isHiding: (values, name) =>
            get(values, `${name}buttonSet`, '') !== 'no' ||
            get(values, `${name}flyOptions`, '') !== 'button',
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        {
          label: 'Button ref 2',
          isRequired: true,
          placeholder: '-',
          key: ['button2Ref'],
          isHiding: (values, name) =>
            get(values, `${name}buttonSet`, '') !== 'no' ||
            get(values, `${name}flyOptions`, '') !== 'button',
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        {
          label: 'Button ref 3',
          isRequired: true,
          placeholder: '-',
          key: ['button3Ref'],
          isHiding: (values, name) =>
            get(values, `${name}buttonSet`, '') !== 'no' ||
            get(values, `${name}flyOptions`, '') !== 'button',
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        {
          label: 'Button ref 4',
          isRequired: true,
          placeholder: '-',
          key: ['button4Ref'],
          isHiding: (values, name) =>
            get(values, `${name}buttonSet`, '') !== 'no' ||
            get(values, `${name}flyOptions`, '') !== 'button',
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        {
          label: 'Small Button ref 1',
          isRequired: true,
          placeholder: '-',
          key: ['smallButton1Ref'],
          isHiding: (values, name) =>
            get(values, `${name}buttonSet`, '') !== 'no' ||
            get(values, `${name}flyOptions`, '') !== 'button',
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        {
          label: 'Small Button ref 2',
          isRequired: true,
          placeholder: '-',
          key: ['smallButton2Ref'],
          isHiding: (values, name) =>
            get(values, `${name}buttonSet`, '') !== 'no' ||
            get(values, `${name}flyOptions`, '') !== 'button',
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          foldable: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const JeansMeasureForm = {
  title: 'Final measurement Jeans',
  display: 'Jeans',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Waist jean',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['jeanWaist'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Waistband top',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['waistbanTop'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Jeans length',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['length'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Inner length',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['innerLength'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Hip',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['hips'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Whole crotch (Front crotch + Back crotch)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['crotch'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Front crotch (Min 22 - Max 32)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          default: 26,
          min: 22,
          max: 32,
          key: ['frontCrotch'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Back crotch',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['backCrotch'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Thigh',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['thigh'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Knee',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['legWidth'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Calf',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['calf'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Jean cuff opening (min 25 - max 45)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['legOpening'],
          default: 36,
          min: 25,
          max: 45,
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Tight',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['tight'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Knee',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['knee'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Cuff',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['cuff'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Jean position waist',
          type: 'basicRadio',
          isRequired: true,
          default: 'yes',
          key: ['jeanPositionWaist'],
          options: [
            {
              key: 'high',
              label: 'High',
            },
            {
              key: 'middle',
              label: 'Middle',
            },
            {
              key: 'low',
              label: 'Low',
            },
          ],
          pos: {
            col: 1,
            // row: 11,
            size: 1,
          },
        },
      ],
    },
  ],
};
