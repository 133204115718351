import { getProductCategoriesBasic, getProductsForMP } from '../network/API';
import { useStore } from '../context/store';

export const MPLandingSectionForm = {
  title: 'Landing section',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Order',
          type: 'number',
          isRequired: true,
          key: ['order'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Image',
          type: 'upload',
          isRequired: true,
          key: ['image'],
          requestURI: '/files/mp-landing',
          storageURI: '/storage/mp-landing',
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Family',
          type: 'select',
          isRequired: true,
          key: ['genre'],
          onChange: (name, form) => {
            console.log(form)
            form.setFieldValue('titleGroup', undefined)
            form.setFieldValue('selectedProducts', undefined)
          },
          options: [
            {
              label: 'Menswear',
              key: 'menswear',
            },
            {
              label: 'Fragrance',
              key: 'fragrance',
            },
          ],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const MPLandingSectionSelectedProductsForm = {
  title: 'Selected products',
  key: ['selectedProducts'],
  sections: [
    {
      canAdd: true,
      key: [],
      addSuffix: 'number',
      title: 'Product',
      deleteBtn: { label: 'Delete', style: 'title' },
      addBtn: { label: 'Add', style: 'primary' },
      max: 3,
      fields: [
        {
          label: 'Select Product',
          type: 'select',
          isRequired: true,
          key: [],
          requestOption: async ({ queryKey }) => {
            const currentIndex = queryKey[4].prev[1];
            const collectionId =
              useStore.getState().mp.titleGroup[currentIndex]?.category ||
              useStore.getState().mp.titleGroup[0]?.category;
            return getProductsForMP({ collectionId });
          },
          optionParser: (options) => {
            if(!options) return [];
            const newOptions = options.filter(option => option.status === 'published' && option.targetAudience === 'public' && option.category.showOnMP === true)
            return newOptions
          },
          multiple: true,
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const MPLandingSectionTitleGroupForm = {
  title: 'Title group',
  key: ['titleGroup'],
  sections: [
    {
      canAdd: true,
      key: [],
      addSuffix: 'number',
      columns: 4,
      title: 'Title group',
      deleteBtn: { label: 'Delete', style: 'title' },
      addBtn: { label: 'Add', style: 'primary' },
      max: 3,
      fields: [
        {
          label: 'Title',
          key: ['title', 'en'],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Title (Chinese)',
          key: ['title', 'zh'],
          isRequired: true,
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Description',
          key: ['description', 'en'],
          isRequired: true,
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Description (Chinese)',
          key: ['description', 'zh'],
          isRequired: true,
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Collection',
          type: 'select',
          isRequired: true,
          key: ['category'],
          requestOption: getProductCategoriesBasic,
          optionParser: (options, name, form) => {
            const genre = form.getFieldValue('genre');
            if(!options) return [];
            const newOptions = options.filter(option => {
              return option.showOnMP && option.status === 'published' && option.genre === genre
            })
            return newOptions
          },
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
      ],
    },
  ],
};
