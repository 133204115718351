import get from 'lodash.get';
export const TwoHundredSDetailsForm = {
  title: '200s details',
  display: '200s',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Design',
          type: 'select',
          isRequired: true,
          key: ['s200DesignRef'],
          options: [
            { key: 'polo-neck', label: '1 - Polo neck' },
            { key: 'round-neck', label: '2 - Round neck' },
            { key: 'v-neck', label: '3 - V neck' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Fabric ref',
          isRequired: true,
          type: 'basicRadio',
          key: ['fabricRef'],
          options: [
            { key: 'black', label: 'Black' },
            { key: 'dark', label: 'Dark blue' },
            { key: 'others_fabric_ref', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Other fabric ref',
          type: 'input',
          isRequired: true,
          placeholder: '-',
          key: ['otherFabricRef'],
          isHiding: (values, name) =>
            get(values, `${name}fabricRef`, '') !== 'others_fabric_ref',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Size in cm',
          type: 'basicRadio',
          isRequired: true,
          key: ['size'],
          options: [
            { key: '48', label: '48' },
            { key: '50', label: '50' },
            { key: '52', label: '52' },
            { key: '54', label: '54' },
            { key: '56', label: '56' },
            { key: '58', label: '58' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Input size',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['otherSize'],
          isHiding: (values, name) =>
            get(values, `${name}size`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const TwoHundredSMeasureForm = {
  title: 'Final measurement 200s',
  display: '200s',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [],
    },
  ],
};
