import get from 'lodash.get'

export const CoatDetailsForm = {
  title: 'Coat details',
  display: 'Coat',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        // {
        //   label: 'Price',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['price'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['fabricRef'],
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
        {
          label: 'Coat style',
          type: 'basicRadio',
          isRequired: true,
          key: ['style'],
          options: [
            {
              key: 'single_breasted_3_buttons',
              label: 'Single breasted 3 button',
            },
            {
              key: 'single_breasted_4_buttons',
              label: 'Single breasted 4 button ',
            },
            {
              key: 'single_breasted_5_buttons',
              label: 'Single breasted 5 button',
            },
            {
              key: 'double_breasted',
              label: 'Double breasted',
            },
            {
              key: 'others',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            // row: 3,
            size: 2,
          },
        },
        {
          label: 'Lapel style',
          type: 'basicRadio',
          isRequired: true,
          key: ['lapelStyle'],
          options: [
            {
              key: 'notch',
              label: 'Notch',
            },
            {
              key: 'peak',
              label: 'Peak',
            },
            {
              key: 'high_collar',
              label: 'High collar',
            },
            {
              key: 'others',
              label: 'Others',
            },
          ],
          pos: {
            col: 1,
            // row: 4,
            size: 1,
          },
        },
        {
          label: 'Lapel width',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['lapelWidth'],
          options: [
            { key: 6, label: '6' },
            { key: 7, label: '7' },
            { key: 8, label: '8' },
            { key: 9, label: '9' },
            { key: 10, label: '10' },
            { key: 11, label: '11' },
            { key: 12, label: '12' },
            { key: 13, label: '13' },
          ],
          pos: {
            col: 1,
            // row: 5,
            size: 1,
          },
        },
        {
          label: 'Chest pocket',
          type: 'basicRadio',
          isRequired: true,
          key: ['chestPocket'],
          options: [
            { key: 'yes', label: 'Yes' },
            { key: 'no', label: 'No' },
          ],
          pos: {
            col: 1,
            // row: 18,
            size: 1,
          },
        },
        {
          label: 'Pocket',
          type: 'basicRadio',
          isRequired: true,
          key: ['pocket'],
          options: [
            {
              key: '2',
              label: '2',
            },
            {
              key: '2_add_ticket_pocket_left',
              label: '2+ ticket pocket on left',
            },
            {
              key: '2_add_ticket_pocket_right',
              label: '2 + ticket pocket on right ',
            },
            {
              key: 'none',
              label: 'None',
            },
          ],
          pos: {
            col: 1,
            // row: 11,
            size: 1,
          },
        },
        {
          label: 'Pocket style',
          type: 'basicRadio',
          isRequired: true,
          key: ['pocketStyle'],
          options: [
            {
              key: 'straight',
              label: 'Straight',
            },
            {
              key: 'slanted',
              label: 'Slanted',
            },
          ],
          pos: {
            col: 1,
            // row: 12,
            size: 1,
          },
        },
        {
          label: 'Pocket design',
          type: 'basicRadio',
          isRequired: true,
          key: ['pocketDesign'],
          options: [
            {
              key: 'pocket_flap',
              label: 'Pocket flap',
            },
            {
              key: 'double_besson',
              label: 'Double bessom',
            },
          ],
          pos: {
            col: 1,
            // row: 13,
            size: 1,
          },
        },
        {
          label: 'Vent',
          type: 'basicRadio',
          isRequired: true,
          key: ['vent'],
          options: [
            {
              key: 'side',
              label: 'Side',
            },
            {
              key: 'central',
              label: 'Central',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            // row: 6,
            size: 1,
          },
        },
        {
          label: 'Back extension',
          type: 'basicRadio',
          isRequired: true,
          key: ['backExtension'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            // row: 7,
            size: 1,
          },
        },
        {
          label: 'Belt',
          type: 'basicRadio',
          isRequired: true,
          key: ['belt'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
            {
              key: 'integrated',
              label: 'Integrated',
            },
          ],
          pos: {
            col: 1,
            // row: 8,
            size: 1,
          },
        },
        {
          label: 'Sleeve strap',
          type: 'basicRadio',
          isRequired: true,
          key: ['sleeveStrap'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            // row: 9,
            size: 1,
          },
        },
        {
          label: 'Shoulder strap',
          type: 'basicRadio',
          isRequired: true,
          key: ['shoulderStrap'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            // row: 10,
            size: 1,
          },
        },
        {
          label: 'Button ref',
          isRequired: true,
          placeholder: '-',
          key: ['buttonRef'],
          pos: {
            col: 1,
            // row: 14,
            size: 1,
          },
        },
        {
          label: 'Lining ref',
          isRequired: true,
          placeholder: '-',
          key: ['liningRef'],
          pos: {
            col: 1,
            // row: 15,
            size: 1,
          },
        },
        {
          label: 'Piping',
          type: 'select',
          isRequired: true,
          key: ['piping'],
          options: [
            { key: 'wine', label: 'Red Wine' },
            { key: 'black', label: 'Black' },
            { key: 'pink_flash', label: 'Pink flash' },
            { key: 'orange', label: 'Orange' },
            { key: 'light_blue', label: 'Light blue' },
            { key: 'white', label: 'White' },
            { key: 'red', label: 'Red' },
            { key: 'dark_green', label: 'Dark green' },
            { key: 'light_grey', label: 'Light grey' },
            { key: 'dark_grey', label: 'Dark grey' },
            { key: 'royal_blue', label: 'Royal blue' },
            { key: 'dark_blue', label: 'Dark blue' },
            { key: 'light_pink', label: 'Light pink' },
            { key: 'dark_brown', label: 'Dark brown' },
            { key: 'gold', label: 'Gold' },
            { key: 'yellow', label: 'Yellow' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            // row: 17,
            size: 1,
          },
        },
        {
          label: 'Piping ref',
          isRequired: true,
          placeholder: '-',
          key: ['pipingRef'],
          isHiding: (values, name) =>
            get(values, `${name}piping`, '') !== 'others',
          pos: {
            col: 1,
            // row: 16,
            size: 1,
          },
        },
        {
          label: 'Sleeve lining',
          type: 'basicRadio',
          isRequired: true,
          key: ['sleeveLining'],
          options: [
            {
              key: 'same_as_piping',
              label: 'Same as piping',
            },
            {
              key: 'same_as_lining',
              label: 'Same as lining',
            },
          ],
          pos: {
            col: 1,
            // row: 17,
            size: 1,
          },
        },
        {
          label: 'Winter finishing',
          type: 'basicRadio',
          isRequired: true,
          key: ['winterFinishing'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
            {
              key: 'removable',
              label: 'Removable',
            },
          ],
          pos: {
            col: 1,
            // row: 18,
            size: 1,
          },
        },
        // {
        //   label: 'Other specificities',
        //   // isRequired: true,
        //   placeholder: '-',
        //   key: ['other'],
        //   pos: {
        //     col: 1,
        //     // row: 18,
        //     size: 1,
        //   },
        // },
        {
          label: 'Contrast color',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['contrastColor'],
          options: [
            { key: 'yes', label: 'Yes' },
            { key: 'no', label: 'No' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast position',
          type: 'multiSelect',
          // default: 'no',
          isRequired: true,
          key: ['contrastPosition'],
          isHiding: (values, name) => {
            return get(values, `${name}contrastColor`, '') === 'no'
          },
          options: [
            { key: 'collar', label: 'Collar' },
            { key: 'lapel', label: 'Lapel' },
            { key: 'chest_pocket', label: 'Chest pocket' },
            {
              key: 'lower_pocket_banch_only',
              label: 'Lower pocket banch only (if flap pocket)',
            },
            { key: 'lower_pocket', label: 'Lower pocket' },
            { key: 'elbow_patch_rounded', label: 'Elbow patch rounded' },
            {
              key: 'cut_out_lapel_edge_1cm',
              label: 'Cut out lapel edge 1 cm',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast color ref',
          isRequired: true,
          placeholder: '-',
          type: 'select',
          key: ['contrastColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') === 'no',
          options: [
            { key: 'fe029023', label: 'Black silk (FE029023)' },
            { key: 'fe029025', label: 'Blue silk (FE029025)' },
            { key: 'fp010020', label: 'Red suede (FP010020)' },
            { key: 'fp015782', label: 'Dark blue suede (FP015782)' },
            { key: 'fp010043', label: 'Navy blue suede (FP010043)' },
            { key: 'fp011014', label: 'Brown suede (FP011014)' },
            { key: 'fp010114', label: 'Grey suede (FP010114)' },
            { key: 'fe029024', label: 'White silk (FE029024)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },

        {
          label: 'Other contrast color ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherContrastColorRef'],
          isHiding: (values, name) =>
            get(values, `${name}contrastColor`, '') === 'no' ||
            get(values, `${name}contrastColorRef`, '') !== 'others',
          pos: {
            col: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Contrast position',
        //   type: 'select',
        //   key: ['contrastPosition'],
        //   isHiding: (values, name) =>
        //     get(values, `${name}contrastColor`, '') === 'no',
        //   options: [
        //     { key: 'collar', label: 'Collar' },
        //     { key: 'lapel', label: 'Lapel' },
        //     { key: 'chest_pocket', label: 'Chest pocket' },
        //     {
        //       key: 'lower_pocket_banch_only',
        //       label: 'Lower pocket banch only (if flap pocket)',
        //     },
        //     { key: 'lower_pocket', label: 'Lower pocket' },
        //     { key: 'elbow_patch_rounded', label: 'Elbow patch rounded' },
        //     { key: 'cut_out_lapel_edge_1cm', label: 'Cut out lapel edge 1cm' },
        //   ],
        //   isRequired: true,
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Add your monogram',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['addMonogram'],
          options: [
            { key: 'yes', label: 'yes' },
            { key: 'no', label: 'no' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Monogram text',
          isRequired: true,
          placeholder: '-',
          key: ['monogramText'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') === 'no',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Monogram color',
          type: 'basicRadio',
          // TODO: temporarily comment this for product creation form, the default value was not being set
          default: 'gold_120',
          isRequired: true,
          key: ['monogramColor'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') === 'no',
          options: [{ key: 'gold_120', label: '120 GOLD' }],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Monogram font',
          type: 'basicRadio',
          default: 'garamond_6mm',
          isRequired: true,
          key: ['monogramFont'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') === 'no',
          options: [
            { key: 'lucida_handwritting_6mm', label: 'Lucida handwriting 6mm' },
            { key: 'garamond_6mm', label: 'Garamond 6mm' },
            { key: 'monotype_corsiva_6mm', label: 'Monotype Corsiva 6mm' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Melton',
          type: 'basicRadio',
          default: 'Black',
          isRequired: true,
          key: ['melton'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') === 'no',
          options: [
            { key: 'black', label: 'Black' },
            { key: 'red', label: 'Red' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const CoatMeasureForm = {
  title: 'Final measurement Coat',
  display: 'Coat',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Coat length front',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['jacketLengthFront'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Coat length back',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['jacketLengthBack'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Shoulder',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['shoulder'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Coat sleeve length',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['sleeveLength'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Chest',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['chest'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Waist',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['waist'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Stomach',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['stomach'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Hips',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['hips'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Lower herm',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['lowerHerm'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Arm width (biceps)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['biceps'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Forearm',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['forearm'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Arm hole',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['armHole'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Coat sleeve openning',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['sleeveWrist'],
          min: 27,
          max: 35,
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Wrist / cuff',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['wristCuff'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Neck measurement',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['neckMeasurement'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
      ],
    },
  ],
}
