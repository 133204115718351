import { getStoresBasic } from '../network/API';

export const MembersDescriptionForm = {
  key: [],
  sections: [
    {
      columns: 3,
      fields: [
        {
          label: 'Name',
          key: ['name'],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Role',
          type: 'select',
          key: ['role'],
          options: [
            { key: 'admin', label: 'Admin' },
            { key: 'tailor', label: 'Tailor' },
            { key: 'stylist', label: 'Stylist' },
            { key: 'store_assistant', label: 'Store Assistant' },
            { key: 'marketing', label: 'Marketing' },
            { key: 'adjustment_staff', label: 'Adjustment Staff' },
            { key: 'production_department', label: 'Production Department' },
          ],
          isRequired: true,
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Email',
          key: ['email'],
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ],
          isRequired: true,
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Password',
          key: ['password'],
          placeholder: '********',
          //   isRequired: true,
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Store',
          type: 'multicheck',
          requestOption: getStoresBasic,
          key: ['stores'],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
      ],
    },
  ],
};
