import { Button, PageHeader } from 'antd';
import { useQueryClient } from 'react-query';

import TableBlock from '../../components/tables/TableBlock';
import { getProductCategories } from '../../network/Categories';

const Categories = ({ history }) => {
  const queryClient = useQueryClient();
  queryClient.invalidateQueries('getCategoryDetails');

  const filters = () => ({
    actions: [
      {
        label: '+ Create collection',
        isActive: true,
        action: () => history.push('/categories/create'),
      },
    ],
  });

  const columns = (_keyRequest) => [
    {
      title: 'Name (English)',
      key: 'name.en',
      render: (name) => name?.en,
      dataIndex: 'name',
    },
    {
      title: 'Name (中文)',
      key: 'name.zh',
      render: (name) => name?.zh,
      dataIndex: 'name',
    },
    {
      title: 'Product Family',
      key: 'type',
      render: (type) => type?.name?.en || type?.name?.zh,
      dataIndex: 'type',
    },
    {
      title: 'Family',
      key: 'genre',
      dataIndex: 'genre',
    },
    {
      title: '',
      key: 'action',
      width: 25,
      render: (e) => {
        return (
          <Button
            onClick={() => history.push(`/categories/${e.id}`)}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader className="site-page-header" title="Collections" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getProductCategories'}
        request={getProductCategories}
        infinite
      />
    </>
  );
};

export default Categories;
