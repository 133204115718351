import { Form, Select } from 'antd';
import { useQuery } from 'react-query';

const { Option } = Select;

const LoadingSelect = ({
  onChange,
  request,
  speKey,
  label,
  isSearch,
  noAll,
  defaultValue,
}) => {
  const options = useQuery(
    [
      // format naming to find request in react query
      `get${speKey.charAt(0).toUpperCase() + speKey.slice(1)}Select`,
    ],
    request,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Form.Item
      label={label}
      style={{ margin: 0 }}
      validateStatus={options.status}
    >
      <Select
        showSearch={isSearch}
        loading={options.isLoading}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e)}
        style={{ width: 190 }}
        optionFilterProp={!isSearch ? null : 'children'}
        filterOption={
          !isSearch
            ? null
            : (input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={
          !isSearch
            ? null
            : (optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
        }
      >
        {!noAll && (
          <Option key={''} value={''}>
            All
          </Option>
        )}
        {options.data?.list.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name?.en || option.name?.zh || option.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default LoadingSelect;
