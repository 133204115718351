import { Form, PageHeader } from 'antd';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import { getCompanyProducts, createUniform } from '../../network/B2B';

const CreateUniforms = ({ history }) => {
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Create uniform"
        onBack={() => history.push('/uniforms')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        title="create_uniforms"
        request={createUniform}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/uniforms')}
        invalidate={['getUniforms']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={UniformDescriptionForm} />
          <FormBlock form={form} formTemplate={ProductsForm} />
        </div>
      </FormWrap>
    </>
  );
};

export default CreateUniforms;

export const UniformDescriptionForm = {
  title: 'Description',
  key: [],
  sections: [
    {
      columns: 4,
      fields: [
        {
          label: 'Name',
          isRequired: true,
          key: ['name'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const ProductsForm = {
  title: 'Products',
  sections: [
    {
      canAdd: true,
      key: ['products'],
      // addSuffix: 'number',
      columns: 4,
      noDivider: true,
      // title: 'Product',
      deleteBtn: { label: 'Delete', style: 'line' },
      addBtn: { label: 'Add product', style: 'primary' },
      fields: [
        {
          label: 'Product',
          type: 'select',
          isRequired: true,
          key: ['product'],
          requestOption: getCompanyProducts,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};
