import moment from 'moment'
import { Form, Input } from 'antd'
import get from 'lodash.get'
import { getProducts } from '../network/Products'

export const ClientsDetailsForm = {
  title: 'Personal Information',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Gender',
          type: 'basicRadio',
          // default: 'male',
          isRequired: true,
          key: ['gender'],
          options: [
            {
              key: 'male',
              label: 'Man',
            },
            {
              key: 'female',
              label: 'Woman',
            },
          ],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'First name',
          isRequired: true,
          key: ['firstName'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Last name',
          isRequired: true,
          key: ['lastName'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Client ID',
          default: 'ID*****',
          isInactive: true,
          key: ['clientID'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'WeChat ID',
          // isRequired: true,
          key: ['wechatID'],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
        {
          label: 'Email',
          // isRequired: true,
          key: ['email'],
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            // {
            //   required: true,
            //   message: 'Please input your E-mail!',
            // },
          ],
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Phone number',
          isRequired: true,
          key: ['phone'],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
        {
          label: 'How do you know AF',
          type: 'select',
          isRequired: true,
          key: ['acquaintedChannel'],
          options: [
            {
              key: 'friend_recommendation',
              label: 'From A Friend',
            },
            {
              key: 'online',
              label: 'Online',
            },
            {
              key: 'wechat',
              label: 'Wechat',
            },
            {
              key: 'ad',
              label: 'Ad',
            },
            {
              key: 'other',
              label: 'Other',
            },
          ],
          pos: {
            col: 1,
            row: 8,
            size: 1,
          },
        },
        {
          label: 'You know AF from?',
          isRequired: true,
          key: ['acquaintedChannelOther'],
          isConditional: true,
          displayCondition: (parentValue) => parentValue === 'other',
          parentKey: ['acquaintedChannel'],
          pos: {
            col: 1,
            row: 9,
            size: 1,
          },
        },
        {
          label: 'Personae type',
          type: 'basicRadio',
          // default: 'sometimes_wear',
          isRequired: true,
          key: ['personae'],
          options: [
            {
              key: 'never_wear',
              label: 'Never wear suit',
            },
            {
              key: 'sometimes_wear',
              label: 'Sometimes wear suit',
            },
            {
              key: 'regularly_wear',
              label: 'Regularly wear suit',
            },
            {
              key: 'always_wear',
              label: 'Person love suit',
            },
          ],
          pos: {
            col: 1,
            row: 10,
            size: 2,
          },
        },
        {
          label: 'Client profile',
          type: 'multicheck',
          default: 'no',
          isRequired: false,
          key: ['clientProfile'],
          options: [
            {
              key: 'smart_casual',
              label: 'Smart casual',
            },
            {
              key: 'pure_business',
              label: 'Pure business',
            },
            {
              key: 'students_first',
              label: 'Students First',
            },
            {
              key: 'wedding',
              label: 'Wedding Celebration',
            },
            {
              key: 'woman_for_him',
              label: 'Woman 4him',
            },
            {
              key: 'woman_for_her',
              label: 'Woman 4her',
            },
          ],
          pos: {
            col: 1,
            row: 11,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const AddressesForm = {
  title: 'Addresses',
  key: ['addresses'],
  sections: [
    {
      canAdd: true,
      key: [],
      addSuffix: 'number',
      columns: 4,
      noDivider: true,
      title: 'Option',
      deleteBtn: { label: 'Delete', style: 'title' },
      addBtn: { label: 'Add', style: 'primary' },
      fields: [
        {
          label: 'Name',
          key: ['name'],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Area',
          key: ['area'],
          isRequired: true,
          pos: {
            col: 2,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'City',
          key: ['city'],
          isRequired: true,
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Zip code',
          key: ['zipCode'],
          isRequired: true,
          pos: {
            col: 2,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Detailled address',
          key: ['address'],
          isRequired: true,
          pos: {
            col: 1,
            row: 3,
            size: 2,
          },
        },
        {
          label: 'Contact person',
          key: ['contactPerson'],
          isRequired: true,
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Phone',
          key: ['phone'],
          isRequired: true,
          pos: {
            col: 2,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Comment',
          key: ['comment'],
          isRequired: true,
          pos: {
            col: 1,
            row: 5,
            size: 2,
          },
        },
        // name: "公司"
        // city: "上海市"
        // area: "静安区"
        // zipCode: "200000"
        // address: "海防路"
        // contactPerson: "阿曼达"
        // phone: "1351880736"
        // comment
      ],
    },
  ],
}

export const BirthdayForm = {
  title: 'Personal information',
  key: [],
  sections: [
    {
      columns: 6,
      fields: [
        {
          label: 'Nationality',
          type: 'select',
          isRequired: true,
          // default: 'chinese',
          key: ['nationality'],
          options: [
            {
              key: 'chinese',
              label: 'China',
            },
            {
              key: 'foreigner',
              label: 'Other',
            },
          ],
          pos: {
            col: 1,
            row: 1,
            size: 2,
          },
        },
        {
          label: 'Country',
          isRequired: true,
          key: ['country'],
          isConditional: true,
          displayCondition: (parentValue) => parentValue === 'foreigner',
          isHiding: (values, name) =>
            get(values, `${name}nationality`, '') !== 'foreigner',
          parentKey: ['nationality'],
          pos: {
            col: 1,
            row: 2,
            size: 2,
          },
        },
        {
          label: 'City of residence',
          isRequired: true,
          key: ['city'],
          pos: {
            col: 1,
            row: 3,
            size: 2,
          },
        },

        {
          label: 'Birth year',
          type: 'year',
          isRequired: true,
          key: ['birthDate'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Birth month',
          type: 'month',
          isRequired: true,
          key: ['birthDate'],
          pos: {
            col: 2,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Birth day',
          type: 'day',
          isRequired: true,
          key: ['birthDate'],
          pos: {
            col: 3,
            row: 4,
            size: 1,
          },
        },
        {
          label: ' ',
          render: ({ getFieldValue }) => {
            let value = ''
            const dateB = getFieldValue('birthDate')
            if (dateB)
              value = `${moment().diff(dateB, 'years', false)} years old`

            return (
              <Form.Item label=" ">
                <Input disabled={true} value={value} />
              </Form.Item>
            )
          },
          key: [''],
          pos: {
            col: 4,
            row: 4,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const BirthdayFormOrder = {
  title: 'Personal information',
  key: [],
  sections: [
    {
      columns: 6,
      fields: [
        {
          label: 'Nationality',
          type: 'select',
          isRequired: true,
          // default: 'chinese',
          key: ['nationality'],
          options: [
            {
              key: 'chinese',
              label: 'China',
            },
            {
              key: 'foreigner',
              label: 'Other',
            },
          ],
          pos: {
            col: 1,
            row: 1,
            size: 2,
          },
        },
        {
          label: 'Country',
          isRequired: true,
          key: ['country'],
          isConditional: true,
          displayCondition: (parentValue) => parentValue === 'foreigner',
          isHiding: (values, name) =>
            get(values, `${name}nationality`, '') !== 'foreigner',
          parentKey: ['nationality'],
          pos: {
            col: 1,
            row: 2,
            size: 2,
          },
        },
        {
          label: 'City of residence',
          isRequired: true,
          key: ['city'],
          pos: {
            col: 1,
            row: 3,
            size: 2,
          },
        },
        {
          type: 'birthday',
          isRequired: true,
          key: ['birthDate'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const ClientsBodyPicForm = {
  title: 'Body pictures',
  key: [],
  sections: [
    {
      columns: 1,
      fields: [
        {
          type: 'multiupload',
          // isRequired: true,
          requestURI: '/files/customer-body',
          storageURI: '/storage/customer-body',
          key: ['bodyPictures'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const ClientsComplexionForm = {
  title: 'Complexion',
  key: ['complexion'],
  sections: [
    {
      columns: 1,
      fields: [
        {
          label: 'How do you like your fit in general?',
          type: 'basicRadio',
          isRequired: true,
          key: ['fitStyle'],
          options: [
            {
              key: 'slim_fit',
              label: 'Slim fit',
            },
            {
              key: 'standard_fit',
              label: 'Standard fit',
            },
            {
              key: 'loose_fit',
              label: 'Loose fit',
            },
          ],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Body type',
          type: 'basicRadio',
          isRequired: true,
          key: ['bodyType'],
          options: [
            {
              key: 'a_standard',
              label: 'A - Standard',
            },
            {
              key: 'y_muscular',
              label: 'Y - Muscular',
            },
            {
              key: 'b_little_belly',
              label: 'B - Little stomach',
            },
            {
              key: 'c_belly',
              label: 'C - Stomach',
            },
            {
              key: 'd_big_belly',
              label: 'D - Big stomach',
            },
            {
              key: 'o_husky',
              label: 'O - Husky',
            },
          ],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        //
        {
          label: 'Shoulder type',
          type: 'basicRadio',
          isRequired: true,
          key: ['shoulderType'],
          options: [
            { key: 'normal', label: 'Normal' },
            { key: 'slightly_sloping', label: 'Slightly sloping' },
            { key: 'sloping', label: 'Sloping' },
            { key: 'strong_sloping', label: 'Strong sloping' },
            { key: 'slightly_square', label: 'Slightly square' },
            { key: 'square', label: 'Square' },
            { key: 'strong_square', label: 'Strong square' },
          ],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        //
        {
          label: 'Body position',
          type: 'basicRadio',
          isRequired: true,
          key: ['bodyPosition'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_backward',
              label: 'Little backward',
            },
            {
              key: 'little_forward',
              label: 'Little forward',
            },
            {
              key: 'very_backward',
              label: 'Very backward',
            },
            {
              key: 'very_forward',
              label: 'Very forward',
            },
          ],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Chest',
          type: 'basicRadio',
          isRequired: true,
          key: ['chestType'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'chest_out',
              label: 'Chest out',
            },
            {
              key: 'chest_very_out',
              label: 'Chest very out',
            },
            {
              key: 'pectoral',
              label: 'Pectoral',
            },
            {
              key: 'very_pectoral',
              label: 'Very pectoral',
            },
          ],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
        {
          label: 'Belly',
          type: 'basicRadio',
          isRequired: true,
          key: ['bellyType'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'big_belly',
              label: 'Rounded',
            },
            {
              key: 'very_big_belly',
              label: 'Very rounded stomach',
            },
          ],
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Head',
          type: 'basicRadio',
          isRequired: true,
          key: ['headType'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_backward',
              label: 'Little backward',
            },
            {
              key: 'little_forward',
              label: 'Little forward',
            },
            {
              key: 'very_backward',
              label: 'Very backward',
            },
            {
              key: 'very_forward',
              label: 'Very forward',
            },
          ],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
        {
          label: 'Neck',
          type: 'basicRadio',
          isRequired: true,
          key: ['neckType'],
          options: [
            {
              key: 'standard',
              label: 'Normal',
            },
            {
              key: 'little_thin',
              label: 'Little thin',
            },
            {
              key: 'little_thick',
              label: 'Little thick',
            },
            {
              key: 'very_thin',
              label: 'Very thin',
            },
            {
              key: 'very_thick',
              label: 'Very thick',
            },
          ],
          pos: {
            col: 1,
            row: 8,
            size: 1,
          },
        },
        {
          label: 'General front shoulder position',
          type: 'basicRadio',
          isRequired: true,
          key: ['frontShoulderPosition'],
          options: [
            {
              key: 'standard',
              label: 'Normal',
            },
            {
              key: 'little_backward',
              label: 'Little backward',
            },
            {
              key: 'little_forward',
              label: 'Little forward',
            },
            {
              key: 'very_backward',
              label: 'Very backward',
            },
            {
              key: 'very_forward',
              label: 'Very forward',
            },
          ],
          pos: {
            col: 1,
            row: 9,
            size: 1,
          },
        },
        {
          label: 'Left shoulder',
          type: 'basicRadio',
          isRequired: true,
          key: ['leftShoulder'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_high',
              label: 'Little high',
            },
            {
              key: 'high',
              label: 'High',
            },
            {
              key: 'very_high',
              label: 'Very high',
            },
            {
              key: 'little_sloping',
              label: 'Little sloping',
            },
            {
              key: 'sloping',
              label: 'Sloping',
            },
            {
              key: 'very_sloping',
              label: 'Very sloping',
            },
          ],
          pos: {
            col: 1,
            row: 10,
            size: 1,
          },
        },
        {
          label: 'Right shoulder',
          type: 'basicRadio',
          isRequired: true,
          key: ['rightShoulder'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_high',
              label: 'Little high',
            },
            {
              key: 'high',
              label: 'High',
            },
            {
              key: 'very_high',
              label: 'Very high',
            },
            {
              key: 'little_sloping',
              label: 'Little sloping',
            },
            {
              key: 'sloping',
              label: 'Sloping',
            },
            {
              key: 'very_sloping',
              label: 'Very sloping',
            },
          ],
          pos: {
            col: 1,
            row: 11,
            size: 1,
          },
        },
        {
          label: 'Back',
          type: 'basicRadio',
          isRequired: true,
          key: ['backType'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'slighty_fat',
              label: 'Slightly fat',
            },
            {
              key: 'very_fat',
              label: 'Very fat',
            },
            {
              key: 'little_hunchback',
              label: 'Little hunchback',
            },
            {
              key: 'very_hunchback',
              label: 'Very hunchback',
            },
            {
              key: 'little_flat',
              label: 'Little flat',
            },
            {
              key: 'very_flat',
              label: 'Very flat',
            },
          ],
          pos: {
            col: 1,
            row: 12,
            size: 1,
          },
        },
        {
          label: 'Bladebone',
          type: 'basicRadio',
          isRequired: true,
          key: ['bladebone'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'prominent',
              label: 'Prominent',
            },
            {
              key: 'very_prominent',
              label: 'Very prominent',
            },
          ],
          pos: {
            col: 1,
            row: 13,
            size: 1,
          },
        },
        {
          label: 'Arm shape',
          type: 'basicRadio',
          isRequired: true,
          key: ['armShape'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_thin',
              label: 'Little thin',
            },
            {
              key: 'little_thick',
              label: 'Little thick',
            },
            {
              key: 'very_thin',
              label: 'Very thin',
            },
            {
              key: 'very_thick',
              label: 'Very thick',
            },
          ],
          pos: {
            col: 1,
            row: 14,
            size: 1,
          },
        },
        {
          label: 'Arm position',
          type: 'basicRadio',
          isRequired: true,
          key: ['armPosition'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_backward',
              label: 'Little backward',
            },
            {
              key: 'very_backward',
              label: 'Very backward',
            },
            {
              key: 'little_forward',
              label: 'Little forward',
            },
            {
              key: 'very_forward',
              label: 'Very forward',
            },
          ],
          pos: {
            col: 1,
            row: 15,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const TDLookForm = {
  title: '3D scan measurments',
  key: ['3D Look'],
  sections: [
    {
      columns: 1,
      fields: [
        {
          label: 'Shoulder',
          type: '3dlook',
          isRequired: true,
          placeholder: '-',
          key: ['3dlookkk'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const ClientsBodyMeasurementForm = {
  title: 'Body measurements in cm',
  key: ['bodyMeasurements'],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Prefered measurements source',
          type: 'basicRadio',
          isRequired: true,
          radioType: 'button',
          key: ['measurementSource'],
          options: [
            {
              key: 'af',
              label: 'Atelier Fusari',
            },
            {
              key: '3dlook',
              label: '3D Scan',
            },
          ],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          key: [],
          type: 'title',
          label: 'AF Measures',
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        // {
        //   label: 'Height in cm',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['height'],
        //   pos: {
        //     col: 1,
        //     row: 1,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Weight in Kg',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['weight'],
        //   pos: {
        //     col: 1,
        //     row: 2,
        //     size: 1,
        //   },
        // },
        {
          label: 'Shoulder',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['shoulder'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Chest',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['chest'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Waist (jacket)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['waistJacket'],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
        {
          label: 'Stomach',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['stomach'],
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Hips',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['hips'],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
        {
          label: 'Biceps',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['biceps'],
          pos: {
            col: 1,
            row: 8,
            size: 1,
          },
        },
        {
          label: 'Forearm',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['forearm'],
          pos: {
            col: 1,
            row: 9,
            size: 1,
          },
        },
        {
          label: 'Wrist',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['wrist'],
          pos: {
            col: 1,
            row: 10,
            size: 1,
          },
        },
        {
          label: 'Arm hole',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['armHole'],
          pos: {
            col: 1,
            row: 11,
            size: 1,
          },
        },
        {
          label: 'Thigh',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['thigh'],
          pos: {
            col: 1,
            row: 12,
            size: 1,
          },
        },
        {
          label: 'Knee',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['knee'],
          pos: {
            col: 1,
            row: 13,
            size: 1,
          },
        },
        {
          label: 'Calf',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['calf'],
          pos: {
            col: 1,
            row: 14,
            size: 1,
          },
        },
        {
          label: 'Neck',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['neck'],
          pos: {
            col: 1,
            row: 15,
            size: 1,
          },
        },
        {
          label: 'Waist (pant)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['waistPant'],
          pos: {
            col: 1,
            row: 16,
            size: 1,
          },
        },
        {
          label: 'Side neck point to upper hips',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['sideNeckPoint'],
          pos: {
            col: 1,
            row: 17,
            size: 1,
          },
        },

        {
          label: 'Ankle',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['ankle'],
          pos: {
            col: 1,
            row: 18,
            size: 1,
          },
        },

        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          pos: {
            col: 1,
            row: 19,
            size: 1,
          },
        },

        // {
        //   label: 'Neck to waist pant + 5cm (vest length)',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['vestLength'],
        //   pos: {
        //     col: 1,
        //     row: 12,
        //     size: 1,
        //   },
        // },
      ],
    },
  ],
}

export const ClientsBodyMeasurementFormOrder = {
  title: 'Body measurements in cm',
  key: ['bodyMeasurements'],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Measurements source',
          type: 'basicRadio',
          isRequired: true,
          radioType: 'button',
          key: ['measurementSource'],
          options: [
            {
              key: 'af',
              label: 'Atelier Fusari',
            },
            {
              key: '3dlook',
              label: '3D Scan',
            },
          ],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Shoulder',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['shoulder'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Chest',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['chest'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Waist (jacket)',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['waistJacket'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Stomach',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['stomach'],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
        {
          label: 'Hips',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['hips'],
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Biceps',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['biceps'],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
        {
          label: 'Forearm',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['forearm'],
          pos: {
            col: 1,
            row: 8,
            size: 1,
          },
        },
        {
          label: 'Wrist',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['wrist'],
          pos: {
            col: 1,
            row: 9,
            size: 1,
          },
        },
        {
          label: 'Arm hole',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['armHole'],
          pos: {
            col: 1,
            row: 10,
            size: 1,
          },
        },
        {
          label: 'Neck',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['neck'],
          pos: {
            col: 1,
            row: 11,
            size: 1,
          },
        },
        {
          label: 'Waist (pant)',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['waistPant'],
          pos: {
            col: 1,
            row: 12,
            size: 1,
          },
        },
        {
          label: 'Side neck point to upper hips',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['sideNeckPoint'],
          pos: {
            col: 1,
            row: 13,
            size: 1,
          },
        },
        {
          label: 'Thigh',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['thigh'],
          pos: {
            col: 1,
            row: 14,
            size: 1,
          },
        },
        {
          label: 'Knee',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['knee'],
          pos: {
            col: 1,
            row: 15,
            size: 1,
          },
        },
        {
          label: 'Calf',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['calf'],
          pos: {
            col: 1,
            row: 16,
            size: 1,
          },
        },
        {
          label: 'Ankle',
          type: 'number',
          isRequired: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === '3dlook',
          placeholder: '-',
          key: ['ankle'],
          pos: {
            col: 1,
            row: 17,
            size: 1,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          // isRequired: true,
          // isHiding: (values, name) =>
          //   get(values, `${name}measurementSource`, '') === '3dlook',
          isHiding: (values, name) => true,
          // placeholder: '-',
          key: ['comment'],
          pos: {
            col: 1,
            row: 18,
            size: 1,
          },
        },

        // for 3d

        {
          label: 'Shoulder',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['shoulder'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Chest',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['chest'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Waist (jacket)',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['waistJacket'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Stomach',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['stomach'],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
        {
          label: 'Hips',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['hips'],
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Biceps',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['biceps'],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
        {
          label: 'Forearm',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['forearm'],
          pos: {
            col: 1,
            row: 8,
            size: 1,
          },
        },
        {
          label: 'Wrist',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['wrist'],
          pos: {
            col: 1,
            row: 9,
            size: 1,
          },
        },
        {
          label: 'Arm hole',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['armHole'],
          pos: {
            col: 1,
            row: 10,
            size: 1,
          },
        },
        {
          label: 'Neck',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['neck'],
          pos: {
            col: 1,
            row: 11,
            size: 1,
          },
        },
        {
          label: 'Waist (pant)',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['waistPant'],
          pos: {
            col: 1,
            row: 12,
            size: 1,
          },
        },
        {
          label: 'Side neck point to upper hips',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['sideNeckPoint'],
          pos: {
            col: 1,
            row: 13,
            size: 1,
          },
        },
        {
          label: 'Thigh',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['thigh'],
          pos: {
            col: 1,
            row: 14,
            size: 1,
          },
        },
        {
          label: 'Knee',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['knee'],
          pos: {
            col: 1,
            row: 15,
            size: 1,
          },
        },
        {
          label: 'Calf',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['calf'],
          pos: {
            col: 1,
            row: 16,
            size: 1,
          },
        },
        {
          label: 'Ankle',
          type: 'number',
          isInactive: true,
          isHiding: (values, name) =>
            get(values, `${name}measurementSource`, '') === 'af',
          placeholder: '-',
          key: ['ankle'],
          pos: {
            col: 1,
            row: 17,
            size: 1,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          isInactive: true,
          // isHiding: (values, name) =>
          //   get(values, `${name}measurementSource`, '') === 'af',
          isHiding: (values, name) => true,
          // placeholder: '-',
          key: ['comment'],
          pos: {
            col: 1,
            row: 18,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const ClientsBodyMeasurementFormExt = {
  title: 'General body size',
  key: ['bodyMeasurements'],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Height in cm',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['height'],
          options: Array.from(Array(200 - 119).keys()).map((e, i) => ({
            key: i + 120,
            label: `${i + 120}cm`,
          })),
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Weight in Kg',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['weight'],
          options: Array.from(Array(200 - 34).keys()).map((e, i) => ({
            key: i + 35,
            label: `${i + 35}kg`,
          })),
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const ClientsFootForm = {
  title: 'Foot size in cm',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Standard RTW usual size',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['foot', 'footMeasure'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Strong foot',
        //   type: 'basicRadio',
        //   isRequired: true,
        //   key: ['foot', 'strongFoot'],
        //   options: [
        //     {
        //       key: 'left',
        //       label: 'Left',
        //     },
        //     {
        //       key: 'right',
        //       label: 'Right',
        //     },
        //   ],
        //   pos: {
        //     col: 1,
        //     row: 2,
        //     size: 1,
        //   },
        // },
        // {
        //   type: 'multiupload',
        //   // isRequired: true,
        //   label: 'Right foot',
        //   requestURI: '/files/customer-foot',
        //   storageURI: '/storage/customer-foot',
        //   key: ['foot', 'picturesRight'],
        //   pos: {
        //     col: 1,
        //     row: 3,
        //     size: 1,
        //   },
        // },
        // {
        //   type: 'multiupload',
        //   // isRequired: true,
        //   label: 'Left foot',
        //   requestURI: '/files/customer-foot',
        //   storageURI: '/storage/customer-foot',
        //   key: ['foot', 'picturesLeft'],
        //   pos: {
        //     col: 1,
        //     row: 4,
        //     size: 1,
        //   },
        // },
      ],
    },
  ],
}

export const ClientsFootFormClients = {
  title: 'Foot size in cm',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Standard RTW usual size',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['foot', 'footMeasure'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Strong foot',
          type: 'basicRadio',
          isRequired: true,
          key: ['foot', 'strongFoot'],
          options: [
            {
              key: 'left',
              label: 'Left',
            },
            {
              key: 'right',
              label: 'Right',
            },
          ],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          type: 'multiupload',
          // isRequired: true,
          label: 'Right foot',
          requestURI: '/files/customer-foot',
          storageURI: '/storage/customer-foot',
          key: ['foot', 'picturesRight'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          type: 'multiupload',
          // isRequired: true,
          label: 'Left foot',
          requestURI: '/files/customer-foot',
          storageURI: '/storage/customer-foot',
          key: ['foot', 'picturesLeft'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const OrderComplexionFormP1 = {
  title: 'Body complexion',
  key: ['complexion'],
  sections: [
    {
      columns: 1,
      fields: [
        // From P1
        {
          label: 'How do you like your fit in general?',
          type: 'basicRadio',
          isRequired: true,
          key: ['fitStyle'],
          options: [
            {
              key: 'slim_fit',
              label: 'Slim fit',
            },
            {
              key: 'standard_fit',
              label: 'Standard fit',
            },
            {
              key: 'loose_fit',
              label: 'Loose fit',
            },
          ],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const OrderComplexionFormP2 = {
  title: 'Body complexion',
  key: ['complexion'],
  sections: [
    {
      columns: 1,
      fields: [
        // From P2
        {
          label: 'Body type',
          type: 'basicRadio',
          isRequired: true,
          key: ['bodyType'],
          options: [
            {
              key: 'a_standard',
              label: 'A - Standard',
            },
            {
              key: 'y_muscular',
              label: 'Y - Muscular',
            },
            {
              key: 'b_little_belly',
              label: 'B - Little stomach',
            },
            {
              key: 'c_belly',
              label: 'C - Stomach',
            },
            {
              key: 'd_big_belly',
              label: 'D - Big stomach',
            },
            {
              key: 'o_husky',
              label: 'O - Husky',
            },
          ],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Shoulder type',
          className: 'shoulder-type-radio',
          type: 'basicRadio',
          isRequired: true,
          key: ['shoulderType'],
          options: [
            { key: 'normal', label: 'Normal' },
            { key: 'slightly_sloping', label: 'Slightly sloping' },
            { key: 'sloping', label: 'Sloping' },
            { key: 'strong_sloping', label: 'Strong sloping' },
            { key: 'slightly_square', label: 'Slightly square' },
            { key: 'square', label: 'Square' },
            { key: 'strong_square', label: 'Strong square' },
          ],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Left shoulder',
          type: 'basicRadio',
          isRequired: true,
          key: ['leftShoulder'],
          isHiding: (values, name) =>
            get(values, `${name}shoulderType`, '') === 'normal',
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_high',
              label: 'Little high',
            },
            {
              key: 'high',
              label: 'High',
            },
            {
              key: 'very_high',
              label: 'Very high',
            },
            {
              key: 'little_sloping',
              label: 'Little sloping',
            },
            {
              key: 'sloping',
              label: 'Sloping',
            },
            {
              key: 'very_sloping',
              label: 'Very sloping',
            },
          ],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Right shoulder',
          type: 'basicRadio',
          isRequired: true,
          key: ['rightShoulder'],
          isHiding: (values, name) =>
            get(values, `${name}shoulderType`, '') === 'normal',
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_high',
              label: 'Little high',
            },
            {
              key: 'high',
              label: 'High',
            },
            {
              key: 'very_high',
              label: 'Very high',
            },
            {
              key: 'little_sloping',
              label: 'Little sloping',
            },
            {
              key: 'sloping',
              label: 'Sloping',
            },
            {
              key: 'very_sloping',
              label: 'Very sloping',
            },
          ],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
        {
          label: 'Chest',
          type: 'basicRadio',
          isRequired: true,
          key: ['chestType'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'chest_out',
              label: 'Chest out',
            },
            {
              key: 'chest_very_out',
              label: 'Chest very out',
            },
            {
              key: 'pectoral',
              label: 'Pectoral',
            },
            {
              key: 'very_pectoral',
              label: 'Very pectoral',
            },
          ],
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Stomach',
          type: 'basicRadio',
          isRequired: true,
          key: ['bellyType'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'big_belly',
              label: 'Rounded',
            },
            {
              key: 'very_big_belly',
              label: 'Very rounded stomach',
            },
          ],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },

        {
          label: 'Body position',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['bodyPosition'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_backward',
              label: 'Little backward',
            },
            {
              key: 'little_forward',
              label: 'Little forward',
            },
            {
              key: 'very_backward',
              label: 'Very backward',
            },
            {
              key: 'very_forward',
              label: 'Very forward',
            },
          ],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Head',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['headType'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_backward',
              label: 'Little backward',
            },
            {
              key: 'little_forward',
              label: 'Little forward',
            },
            {
              key: 'very_backward',
              label: 'Very backward',
            },
            {
              key: 'very_forward',
              label: 'Very forward',
            },
          ],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Neck',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['neckType'],
          options: [
            {
              key: 'standard',
              label: 'Normal',
            },
            {
              key: 'little_thin',
              label: 'Little thin',
            },
            {
              key: 'little_thick',
              label: 'Little thick',
            },
            {
              key: 'very_thin',
              label: 'Very thin',
            },
            {
              key: 'very_thick',
              label: 'Very thick',
            },
          ],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'General front shoulder position',
          type: 'basicRadio',
          isRequired: true,
          key: ['frontShoulderPosition'],
          options: [
            {
              key: 'standard',
              label: 'Normal',
            },
            {
              key: 'little_backward',
              label: 'Little backward',
            },
            {
              key: 'little_forward',
              label: 'Little forward',
            },
            {
              key: 'very_backward',
              label: 'Very backward',
            },
            {
              key: 'very_forward',
              label: 'Very forward',
            },
          ],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Back',
          type: 'basicRadio',
          isRequired: true,
          key: ['backType'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'slighty_fat',
              label: 'Slightly fat',
            },
            {
              key: 'very_fat',
              label: 'Very fat',
            },
            {
              key: 'little_hunchback',
              label: 'Little hunchback',
            },
            {
              key: 'very_hunchback',
              label: 'Very hunchback',
            },
            {
              key: 'little_flat',
              label: 'Little flat',
            },
            {
              key: 'very_flat',
              label: 'Very flat',
            },
          ],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
        {
          label: 'Bladebone',
          type: 'basicRadio',
          isRequired: true,
          key: ['bladebone'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'prominent',
              label: 'Prominent',
            },
            {
              key: 'very_prominent',
              label: 'Very prominent',
            },
          ],
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Arm shape',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['armShape'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_thin',
              label: 'Little thin',
            },
            {
              key: 'little_thick',
              label: 'Little thick',
            },
            {
              key: 'very_thin',
              label: 'Very thin',
            },
            {
              key: 'very_thick',
              label: 'Very thick',
            },
          ],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
        {
          label: 'Arm position',
          type: 'basicRadio',
          isRequired: true,
          foldable: true,
          key: ['armPosition'],
          options: [
            {
              key: 'normal',
              label: 'Normal',
            },
            {
              key: 'little_backward',
              label: 'Little backward',
            },
            {
              key: 'very_backward',
              label: 'Very backward',
            },
            {
              key: 'little_forward',
              label: 'Little forward',
            },
            {
              key: 'very_forward',
              label: 'Very forward',
            },
          ],
          pos: {
            col: 1,
            row: 8,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const OrderBodyPics = {
  title: 'Body pictures',
  key: [],
  sections: [
    {
      columns: 1,
      fields: [
        {
          // lavel: 'ho',
          // isRequired: true,
          type: 'multiupload',
          requestURI: '/files/customer-body',
          storageURI: '/storage/customer-body',
          key: ['bodyPictures'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const DefaultComplexion = {
  fitStyle: 'standard_fit',
  bodyType: 'a_standard',
  shoulderType: 'normal',
  bodyPosition: 'normal',
  chestType: 'normal',
  bellyType: 'normal',
  headType: 'normal',
  neckType: 'standard',
  frontShoulderPosition: 'standard',
  leftShoulder: 'normal',
  rightShoulder: 'normal',
  backType: 'normal',
  bladebone: 'normal',
  armShape: 'normal',
  armPosition: 'normal',
}

export const ProductSizeForm = {
  title: 'Product Size',
  key: ['productSize'],
  sections: [
    {
      canAdd: true,
      key: [],
      addSuffix: 'number',
      columns: 4,
      noDivider: true,
      title: 'Product',
      deleteBtn: { label: 'Delete', style: 'title' },
      addBtn: { label: 'Add', style: 'primary' },
      fields: [
        {
          label: 'Name',
          type: 'select',
          isRequired: true,
          key: ['name'],
          requestOption: getProducts,
        },
        {
          label: 'Size',
          key: ['size'],
          isRequired: true,
          pos: {
            col: 2,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}
