import { Badge, Button, PageHeader } from 'antd';
import moment from 'moment';

import TableBlock from '../../components/tables/TableBlock';
import { getCompanyOrders } from '../../network/B2B';

const CompaniesOrders = ({ _history }) => {
  return (
    <>
      <PageHeader className="site-page-header" title="Companies Orders" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getCompaniesOrders'}
        request={getCompanyOrders}
        infinite
      />
    </>
  );
};

export default CompaniesOrders;

const filters = (history) => ({
  fields: [
    // {
    //   type: 'text',
    //   label: 'Company',
    //   key: 'company',
    //   // noRegex: true,
    // },
  ],
  actions: [
    {
      label: '+ Create an order',
      isActive: true,
      action: () => history.push('/companies/orders/create'),
    },
  ],
});

const columns = (history) => [
  {
    title: 'Order ID',
    key: 'orderId',
    dataIndex: 'orderID',
  },
  {
    title: 'Company',
    key: 'company',
    dataIndex: 'company',
    render: (e) => e.name,
    sorter: true,
  },
  {
    title: 'Date',
    key: 'date',
    render: (e) => moment(e.createdAt).format('YYYY-MM-DD'),
    sorter: true,
  },
  {
    title: 'Scanned',
    render: (e) =>
      `${
        e.customers.filter(
          (c) => c.scanStatus === 'success' || c.scanStatus === 'warning'
        ).length
      }/${e.customers.length}`,
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (e) => {
      const statuses = {
        complete: <Badge status="success" text="Complete" />,
        pending: <Badge status="error" text="Pending" />,
      };

      return statuses[e];
    },
    sorter: true,
  },
  {
    title: '',
    key: 'action',
    width: 25,
    render: (e) => {
      return (
        <Button
          onClick={() => history.push(`/companies/orders/${e.id}`)}
          type="primary"
        >
          Edit
        </Button>
      );
    },
  },
];
