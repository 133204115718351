import { Form, PageHeader } from 'antd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Error from '../../components/Error';
import Loader from '../../components/Loader';
import FormBlock from '../../components/forms/FormBlock';
import FormWrap from '../../components/forms/FormWrap';
import {
  CategoriesDescriptionForm,
  CategoriesFieldsFormEdit,
  CategoriesMediaForm,
  CategoriesPriorityWeightForm,
  CategoriesPublicationForm,
} from '../../forms/Categories';
import { getCategoryDetails, updateCategory } from '../../network/Categories';

const EditCategory = ({ history }) => {
  let { categoryId } = useParams();
  const [form] = Form.useForm();

  const categoryDetails = useQuery(
    [
      'getCategoryDetails',
      {
        id: categoryId,
      },
    ],
    getCategoryDetails
    // {
    //   // onSuccess: (data) => {
    //   //   form.setFieldsValue(data)
    //   // },
    //   // refetchOnWindowFocus: false,
    // }
  );

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Edit collection'}
        onBack={() => history.push('/categories')}
        style={{ backgroundColor: '#fff' }}
      />
      {categoryDetails.isError ? (
        <Error retry={() => categoryDetails.refetch()} />
      ) : categoryDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={categoryId}
          form={form}
          title="update_category"
          request={updateCategory}
          cancelText={'Cancel'}
          okText={'Save'}
          data={categoryDetails.data}
          onSuccess={() => history.push('/categories')}
          onCancel={() => history.push('/categories')}
          cleanBeforeSending={(oldData) => {
            if (oldData.mainPicture) {
              let main = oldData.mainPicture.fileList
                ? oldData.mainPicture?.fileList
                : oldData.mainPicture;

              if (!Array.isArray(main)) {
                main = [main];
              }
              const newPic = main.map((file) =>
                file.response ? file.response : file
              );

              oldData.mainPicture = newPic.length ? newPic[0] : {};
            } else {
              oldData.mainPicture = {};
            }
            return oldData;
          }}
          invalidate={['getProductCategories', 'getCategoryDetails']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={CategoriesDescriptionForm} />
            <FormBlock form={form} formTemplate={CategoriesMediaForm} />
            <FormBlock form={form} formTemplate={CategoriesFieldsFormEdit} />
            <FormBlock.Basic formTemplate={CategoriesPublicationForm} />
            <FormBlock.Basic formTemplate={CategoriesPriorityWeightForm} />
          </div>
        </FormWrap>
      )}
    </>
  );
};

export default EditCategory;
