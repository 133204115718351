import './App.css';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './pages/Login';
import Reset from './pages/Reset';
import Dashboard from './components/Dashboard';
import { useAuth } from './hooks/useAuth';
import ScriptTag from 'react-script-tag';

function App() {
  const { isAuth } = useAuth();

  return (
    <Router>
      <div className="App">
        {isAuth ? (
          <Dashboard />
        ) : (
          <Switch>
            <Route path="/auth/reset" component={Reset} />
            <Route path="/measure" component={MeasurePage} />
            <Route path="/" component={Login} />
          </Switch>
        )}
      </div>
    </Router>
  );
}

export default App;

const MeasurePage = () => {
  return (
    <>
      <div
        style={{
          overflowY: 'scroll',
          height: '100vh',
          backgroundColor: '#ccc',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src="/images/how-to-use.png"
            alt=""
            style={{ width: '100%', maxWidth: 768 }}
          />
          <div
            style={{
              width: '100%',
              maxWidth: 350,
              padding: 16,
              marginTop: 16,
              marginBottom: 100,
            }}
          >
            <div className="saia-widget-container" />
          </div>
        </div>
      </div>
      <ScriptTag
        id="saia-mtm-integration"
        async
        src="https://mtm-widget.3dlook.me/integration.js"
        data-public-key="NjIxOQ:1oaxYx:hG77rzlz6c6HLc3RSVyDx-pai7U"
      />
    </>
  );
};
