import { Button, Form, PageHeader } from 'antd';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useMemo, useState } from 'react';

import FormBlock from '../../components/forms/FormBlock';
import FormWrap from '../../components/forms/FormWrap';
import { formOptions } from '../../forms/blocks';
import {
  ProductsDescriptionForm,
  ProductsMediaForm,
  ProductsOptionsForm,
  ProductsPublicationForm,
} from '../../forms/Products';
import { createProduct } from '../../network/Products';

export const Details = ({ form }) => {
  const options = useMemo(() => {
    const options = cloneDeep(formOptions);
    delete options.collection;
    delete options.suit_2_pieces;
    delete options.suit_3_pieces;
    delete options.default;
    return options;
  }, []);

  const DetailItem = ({ _, fieldIndex, remove }) => {
    const [index, setIndex] = useState(0);
    const [key, setKey] = useState('vest');
    const [template, setTemplate] = useState(null);

    useEffect(() => {
      const tpl = cloneDeep(options[key].forms[0].form[0]);
      for (let i in tpl.sections) {
        for (let j in tpl.sections[i].fields) {
          tpl.sections[i].fields[j].key.splice(0, 0, key);
          tpl.sections[i].fields[j].key.splice(0, 0, fieldIndex);
        }
      }

      setTemplate(tpl);
    }, [fieldIndex, key]);

    useEffect(() => {
      setKey(Object.keys(options)[index]);
    }, [index]);

    useEffect(() => {
      const detail = form.getFieldValue(['details', fieldIndex]);
      if (detail) {
        const detailKeys = Object.keys(detail);
        const index = Object.keys(options).findIndex(
          (key) => detailKeys.includes(key) && !!detail[key]
        );
        setIndex(index);
        setKey(Object.keys(options)[index]);
      }
    }, [fieldIndex]);

    return (
      <>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            width: 'fit-content',
            columnGap: 12,
            rowGap: 12,
          }}
        >
          {/* According to the selected options, render different forms */}
          {Object.keys(options).map((key, i) => (
            <div
              key={i}
              className={`order-btn big ${index === i ? 'selected' : ''}`}
              onClick={() => {
                form.setFieldValue(['details', fieldIndex], {});
                setIndex(i);
                setKey(Object.keys(options)[index]);
              }}
            >
              {options[key].name}
            </div>
          ))}
          <Button
            type="ghost"
            onClick={() => remove(fieldIndex)}
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 5,
              color: '#9e1313',
              border: '1px solid #d1abab',
              '&:hover': {
                background: '#d1abab',
              },
            }}
          >
            Delete
          </Button>
        </div>
        {template && (
          <FormBlock
            form={form}
            formTemplate={template || options[key].forms[0].form[0]}
          />
        )}
      </>
    );
  };

  return (
    <Form.List name="details">
      {(fields, { add, remove }) => (
        <div className="section-from-vertical">
          {fields.map((field, fieldIndex) => {
            return (
              <DetailItem
                key={fieldIndex}
                form={form}
                field={field}
                fieldIndex={fieldIndex}
                remove={remove}
              />
            );
          })}
          <Button
            type="primary"
            style={{
              maxWidth: 200,
            }}
            onClick={() => {
              add({
                vest: options.vest.forms[0].form[0],
              });
            }}
          >
            Add Category
          </Button>
        </div>
      )}
    </Form.List>
  );
};

const CreateProduct = ({ history }) => {
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Create product'}
        onBack={() => history.push('/products')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        form={form}
        title="create_client"
        request={createProduct}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/products')}
        cleanBeforeSending={(oldData) => {
          if (oldData.mainPicture) {
            let main = oldData.mainPicture.fileList
              ? oldData.mainPicture?.fileList
              : oldData.mainPicture;

            if (!Array.isArray(main)) {
              main = [main];
            }
            const newPic = main.map((file) =>
              file.response ? file.response : file
            );

            oldData.mainPicture = newPic.length ? newPic[0] : {};
          } else {
            oldData.mainPicture = {};
          }

          if (oldData.otherPictures) {
            const otherPic = oldData.otherPictures.fileList
              ? oldData.otherPictures?.fileList
              : oldData.otherPictures;
            oldData.otherPictures = otherPic.map((file) =>
              file.response ? file.response : file
            );
          } else {
            oldData.otherPictures = [];
          }

          return oldData;
        }}
        // For debugging form data change
        onValuesChange={(changedValues, allValues) => {
          console.log(allValues);
        }}
        invalidate={['getProducts']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={ProductsDescriptionForm} />
          <Details form={form} />
          <FormBlock form={form} formTemplate={ProductsMediaForm} />
          <FormBlock form={form} formTemplate={ProductsOptionsForm} />
          <FormBlock.Basic formTemplate={ProductsPublicationForm} />
        </div>
      </FormWrap>
    </>
  );
};

export default CreateProduct;
