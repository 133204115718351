import React, { useState, useEffect, useContext, createContext } from 'react';
import { useTimer } from 'react-timer-hook';

import { login, refresh, resetEmail } from '../network/auth';

const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
};

export const useProvideAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [doRemember, setDoRemember] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [expireAt, setExpireAt] = useState();
  const [refreshToken, setRefreshToken] = useState(null);
  const [refreshExpireAt, setRefreshExpireAt] = useState();
  const isAuth = !!user;

  const handleSingIn = (data, remember) => {
    pause();
    let MS_PER_MINUTE = 60000;

    setUser(data.user);
    setToken(data.token);
    let expDate = new Date(new Date(data.expireAt) - 10 * MS_PER_MINUTE);
    setExpireAt(expDate);

    setRefreshToken(data.refreshToken);
    let refreshExpDate = new Date(
      new Date(data.refreshExpireAt).valueOf() - 10 * MS_PER_MINUTE
    );
    setRefreshExpireAt(refreshExpDate);
    setIsLoading(false);

    localStorage.setItem('token', data.token);
    localStorage.setItem('expireAt', expDate);
    if (remember) {
      localStorage.setItem('user', JSON.stringify(data.user));

      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('refreshExpireAt', refreshExpDate);
    }
    restart();
  };

  const onExpire = async () => {
    if (refreshToken && refreshExpireAt) {
      refresh(refreshToken)
        .then((res) => handleSingIn(res, doRemember))
        .catch((error) => {
          setIsLoading(false);
          setIsError(error);
          localStorage.clear();
        });
    } else {
      signout();
    }
  };

  const { days, seconds, minutes, hours, start, restart, pause } = useTimer({
    expiryTimestamp: expireAt,
    autoStart: false,
    onExpire,
  });

  const signin = (email, password, remember = true) => {
    setIsLoading(true);
    setIsError(false);
    setDoRemember(remember);
    login(email, password)
      .then((data) => handleSingIn(data, remember))
      .catch((error) => {
        setIsLoading(false);
        setIsError(error);
        localStorage.clear();
      });
  };

  // const signup = (email, password) => {}

  const signout = () => {
    setUser(null);
    setToken(null);
    setIsError(false);
    setDoRemember(false);
    localStorage.clear();
  };

  const sendPasswordResetEmail = (email) => {
    setIsLoading(true);
    resetEmail(email)
      .then(() => {})
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };
  // const confirmPasswordReset = (code, password) => {}

  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    const savedToken = localStorage.getItem('token');
    const savedExpireAt = localStorage.getItem('expireAt');

    const savedRefreshToken = localStorage.getItem('refreshToken');
    const savedRefreshExpireAt = localStorage.getItem('refreshExpireAt');

    if (
      !!savedUser &&
      !!savedToken &&
      !!savedExpireAt &&
      new Date() < new Date(savedExpireAt)
    ) {
      setUser(JSON.parse(savedUser));
      setToken(savedToken);
      setExpireAt(new Date(savedExpireAt));

      if (!!savedRefreshToken && !!savedRefreshExpireAt) {
        setRefreshToken(savedRefreshToken);
        setRefreshExpireAt(new Date(savedRefreshExpireAt));
      }
    } else if (
      !!savedUser &&
      !!savedRefreshToken &&
      !!savedRefreshExpireAt &&
      new Date() < new Date(savedRefreshExpireAt)
    ) {
      setUser(JSON.parse(savedUser));

      if (!!savedRefreshToken && !!savedRefreshExpireAt) {
        setRefreshToken(savedRefreshToken);
        setRefreshExpireAt(new Date(savedRefreshExpireAt));
      }

      onExpire();
    } else {
      setUser(null);
      setToken(null);
      setExpireAt(null);
      localStorage.clear();
    }
    start();
  }, []);

  return {
    user,
    token,
    isLoading,
    isError,
    isAuth,
    signIn: signin,
    // signup,
    signOut: signout,
    sendPasswordResetEmail,
    // confirmPasswordReset,
    timeBeforeExpire: `${days * 24 + hours}:${
      minutes < 10 ? '0' : ''
    }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`,
  };
};

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
