import React from 'react';
import { Card } from 'antd';
import { Pie } from '@ant-design/charts';

const ChartPie = ({ data, title, noPrefix, noAmount }) => {
  let total = 0;
  for (const stat of data) {
    total += stat.value;
  }

  data = data.map((stat) => ({
    ...stat,
    _id: stat._id,
    legend: `${stat._id}  |  ${Math.round((stat.value * 100) / total)}%  ${
      noPrefix ? '' : '¥'
    }${noAmount ? '' : Math.round(stat.value)} `,
  }));
  var config = {
    appendPadding: 24,
    data: data,
    angleField: 'value',
    colorField: '_id',
    radius: 0.8,
    label: null,
    color: ['#2B77EA', '#DBCA36', '#EE893F', '#8B61E5'],
    legend: {
      offsetX: -75,
      offsetY: -35,
      itemName: {
        formatter: (text, item, index) => data[index].legend,
      },
    },
    tooltip: false,
    interactions: [
      // { type: 'pie-legend-active' },
      // { type: 'element-active' },
    ],
  };
  return (
    <Card style={{ width: 638 }} className="chart-card" title={title}>
      <Pie className="analitycsPie" style={{ height: 250 }} {...config} />
    </Card>
  );
};

export default ChartPie;
