import { PageHeader, Form } from 'antd';
import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import { createMPLandingSection } from '../../network/MP';
import {
  MPLandingSectionForm,
  MPLandingSectionSelectedProductsForm,
  MPLandingSectionTitleGroupForm,
} from '../../forms/MPLandingSection';
import { useStore } from '../../context/store';

export default function CreateSection({ history }) {
  const [form] = Form.useForm();
  const setForm = useStore((state) => state.setMP);
  const resetForm = useStore((state) => state.resetMP);

  return (
    <>
      <PageHeader className="site-page-header" title="Mini Program" />
      <FormWrap
        form={form}
        title="create_section"
        request={createMPLandingSection}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => {
          history.push('/mp');
          resetForm();
        }}
        onCancel={() => {
          history.push('/mp');
          resetForm();
        }}
        onValuesChange={(_, allValues) => setForm(allValues)}
        cleanBeforeSending={(oldData) => {
          if (oldData.image) {
            let main = oldData.image.fileList
              ? oldData.image?.fileList
              : oldData.image;
            if (!Array.isArray(main)) {
              main = [main];
            }
            const newPic = main.map((file) =>
              file.response ? file.response : file
            );

            oldData.image = newPic.length ? newPic[0] : {};
          } else {
            oldData.image = {};
          }
          return oldData;
        }}
        invalidate={['getMPLandingSections']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={MPLandingSectionForm} />
          <FormBlock
            form={form}
            formTemplate={MPLandingSectionTitleGroupForm}
          />
          <FormBlock
            form={form}
            formTemplate={MPLandingSectionSelectedProductsForm}
          />
        </div>
      </FormWrap>
    </>
  );
}
