import { Collapse } from 'antd';
import { useEffect, useRef, useState } from 'react';

const { Panel } = Collapse;

export default function CollapsePanel({ collapse, children, label }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const childrenRef = useRef(null);

  useEffect(() => {
    // If current focus is contains children, ignore collapse from props
    if (childrenRef.current.contains(document.activeElement)) {
      return;
    }
    setIsCollapsed(collapse);
  }, [collapse]);

  return (
    <Collapse
      className={`site-collapse-custom-collapse`}
      ghost
      bordered={false}
      activeKey={isCollapsed ? '' : 1}
      collapsible="header"
      expandIcon={() => null}
    >
      <Panel
        header={
          <span className={'collapse-panel-label'}>{label}{
             (!isCollapsed?
              <div style={{width: 16, height: 16, backgroundImage: "url(/images/fold-icon.png)"}}></div>
              :
              <div style={{width: 16, height: 16,  backgroundImage: "url(/images/unfold.png)"}}></div>
            )
          }</span>
        }
        key={1}
        forceRender
        className={`site-collapse-custom-panel hide-label`}
        onClick={(e) => {
          // if click children then don't collapse
          if (childrenRef.current.contains(e.target)) {
            return;
          }
          setIsCollapsed(!isCollapsed);
        }}
      >
        <div ref={childrenRef}>{children}</div>
      </Panel>
    </Collapse>
  );
}
