import { Button, Space, Typography } from 'antd';
import { FrownOutlined } from '@ant-design/icons';

const { Title } = Typography;

const Loader = ({ retry }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 200,
    }}
  >
    <Space direction="vertical" value="large" style={{ alignItems: 'center' }}>
      <FrownOutlined style={{ fontSize: '27px' }} />
      <Title level={3}>Oops something went wrong.</Title>
      <Button onClick={() => retry()} type="primary">
        Retry
      </Button>
    </Space>
  </div>
);

export default Loader;
