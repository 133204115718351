import { Button, Form, message, Space } from 'antd';
import { useQueryClient } from 'react-query';
import './forms.css';

import { useMutation } from 'react-query';

const FormWrap = ({
  title,
  children,
  request,
  data,
  id,
  onSuccess,
  onCancel,
  invalidate,
  form,
  checkValidity,
  onValuesChange,
  cleanBeforeSending,
  cancelText,
  okText,
  onNext,
  success = 'successfully saved',
}) => {
  const [defaultForm] = Form.useForm();
  const queryClient = useQueryClient();
  const formIntance = form ? form : defaultForm;

  const mutation = useMutation((values) => request(...values), {
    onSuccess: (data) => {
      message.success(success);
      if (onSuccess) onSuccess(data);
      if (invalidate) {
        for (const key of invalidate) {
          queryClient.invalidateQueries(key);
        }
      }
    },
    onError: (error) => {
      message.error(
        error.response?.data?.debugLog?.message ||
          error.response?.data?.message ||
          'An error occurs'
      );
    },
  });

  const onFinish = (values) => {
    if (cleanBeforeSending) {
      values = cleanBeforeSending(values);
    }
    if (checkValidity) {
      let isNotOkay = checkValidity(values);
      if (isNotOkay) {
        message.error(isNotOkay);
        return;
      }
    }
    mutation.mutate([values, id]);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    document.dispatchEvent(new Event('publish-form-failed'));
  };

  // const onReset = () => {
  //     formIntance.resetFields()
  // }

  const onCancelClick = () => {
    if (onCancel) {
      onCancel();
    } else {
      formIntance.resetFields();
    }
  };

  return (
    <Form
      preserve
      onValuesChange={onValuesChange}
      form={formIntance}
      name={title}
      layout="vertical"
      autoComplete="off"
      scrollToFirstError
      onFinish={onFinish}
      initialValues={data}
      onFinishFailed={onFinishFailed}
      style={{ width: '100%', backgroundColor: '#f0f2f5', padding: 24 }}
      requiredMark={false}
    >
      {children}
      <Form.Item>
        <Space
          style={{
            display: 'flex',
            marginTop: 24,
          }}
          align="baseline"
        >
          <Button
            // onClick={onReset}
            onClick={onCancelClick}
            htmlType="button"
            disabled={mutation.isLoading}
          >
            {cancelText || 'Cancel'}
          </Button>
          {!onNext && (
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isLoading}
            >
              {mutation.isLoading ? 'Sending...' : okText || 'Save'}
            </Button>
          )}
          {onNext && (
            <Button type="primary" onClick={onNext}>
              Next
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default FormWrap;
