import { INSTANCELIST, INSTANCE } from './API.js';

export const getClients = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter, tabFilter } = queryKey[1];

    INSTANCELIST({
      method: 'get',
      url: '/customers',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
        ...tabFilter,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getClientDetails = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1];

    INSTANCE({
      method: 'get',
      url: `/customers/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const createClient = (form) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: '/customers',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export const updateClient = (form, id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/customers/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export const resetPassword = (form, id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/customers/${id}/reset`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export const get3Dlook = (id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'get',
      url: `/customers/${id}/3d-look`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const update3Dlook = (id, front_pic, side_pic, height, weight) =>
  new Promise((resolve, reject) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append('front_image', front_pic);
    data.append('side_image', side_pic);
    data.append('height', height);
    data.append('weight', weight);

    INSTANCE({
      method: 'put',
      url: `/customers/${id}/3d-look`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: data,
    })
      .then(resolve)
      .catch(reject);
  });
