import { useEffect } from 'react';
import { Form, PageHeader, notification } from 'antd';
import { useLocation } from 'react-router-dom';
import set from 'lodash.set';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import {
  ClientsDetailsForm,
  ClientsComplexionForm,
  ClientsBodyMeasurementForm,
  ClientsBodyMeasurementFormExt,
  BirthdayForm,
  ClientsFootFormClients,
  ClientsBodyPicForm,
  DefaultComplexion,
  AddressesForm,
  ProductSizeForm,
} from '../../forms/Clients';
import { createClient } from '../../network/Clients';

const getBaseUrl = (path) => {
  let p = path.split('/');
  p.pop();
  return p.join('/');
};

const CreateClient = ({ history }) => {
  let location = useLocation();
  const isRegular = location.pathname === '/clients/create';
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      gender: 'male',
      nationality: 'chinese',
      personae: 'sometimes_wear',
      complexion: { ...DefaultComplexion },
      bodyMeasurements: {
        height: 170,
        weight: 89,
      },
    });
  }, []);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={
          isRegular
            ? 'Create customer'
            : `Create ${
                location.pathname.includes('orders') ? 'order' : 'adjustment'
              }`
        }
        onBack={
          isRegular ? null : () => history.push(getBaseUrl(location.pathname))
        }
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        form={form}
        title="create_client"
        request={createClient}
        cancelText={isRegular ? 'Cancel' : 'Previous'}
        okText={isRegular ? 'Save' : 'Next'}
        cleanBeforeSending={(oldData) => {
          if (oldData.bodyPictures) {
            const otherPic = oldData.bodyPictures.fileList
              ? oldData.bodyPictures?.fileList
              : oldData.bodyPictures;
            oldData.bodyPictures = otherPic.map((file) =>
              file.response ? file.response : file
            );
          } else {
            set(oldData, 'complexion.bodyPictures', []);
          }

          if (oldData.foot && oldData.foot.picturesRight) {
            const otherPic = oldData.foot.picturesRight.fileList
              ? oldData.foot.picturesRight?.fileList
              : oldData.foot.picturesRight;
            oldData.foot.picturesRight = otherPic.map((file) =>
              file.response ? file.response : file
            );
          } else {
            set(oldData, 'foot.picturesRight', []);
          }

          if (oldData.foot && oldData.foot.picturesLeft) {
            const otherPic = oldData.foot.picturesLeft.fileList
              ? oldData.foot.picturesLeft?.fileList
              : oldData.foot.picturesLeft;
            oldData.foot.picturesLeft = otherPic.map((file) =>
              file.response ? file.response : file
            );
          } else {
            set(oldData, 'foot.picturesLeft', []);
          }

          oldData.complexion = { ...DefaultComplexion, ...oldData.complexion };
          return oldData;
        }}
        onSuccess={(data) => {
          notification.info({
            message: 'User Created',
            description: `the user ${data.user.firstName} ${data.user.lastName} has been created with the clientID ${data.user.clientID}`,
            duration: 0,
          });

          if (isRegular) {
            history.push('/clients');
          } else {
            history.push(`${getBaseUrl(location.pathname)}/${data.user.id}`);
          }
        }}
        onCancel={
          isRegular ? null : () => history.push(getBaseUrl(location.pathname))
        }
        // cleanBeforeSending={cleanBeforeSendingArea}
        invalidate={['getClients']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={ClientsDetailsForm} />
          {isRegular && <FormBlock form={form} formTemplate={BirthdayForm} />}
          {isRegular && (
            <>
              <FormBlock form={form} formTemplate={AddressesForm} />
              <FormBlock
                form={form}
                formTemplate={ClientsBodyMeasurementFormExt}
              />
              <FormBlock form={form} formTemplate={ClientsBodyPicForm} />
              <FormBlock form={form} formTemplate={ClientsComplexionForm} />

              <FormBlock
                form={form}
                formTemplate={ClientsBodyMeasurementForm}
              />
              <FormBlock form={form} formTemplate={ClientsFootFormClients} />
              <FormBlock form={form} formTemplate={ProductSizeForm} />
            </>
          )}
        </div>
      </FormWrap>
    </>
  );
};

export default CreateClient;
