import { Form, PageHeader } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import {
  getCompanyProducts,
  getUniformDetails,
  updateUniform,
} from '../../network/B2B';
import Loader from '../../components/Loader';
import Error from '../../components/Error';

const EditUniforms = ({ history }) => {
  let { uniformId } = useParams();
  const [form] = Form.useForm();

  const uniformDetails = useQuery(
    [
      'getUniformDetails',
      {
        id: uniformId,
      },
    ],
    getUniformDetails,
    {
      // onSuccess: (data) => {
      //   form.setFieldsValue(data)
      // },
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Edit uniform'}
        onBack={() => history.push('/uniforms')}
        style={{ backgroundColor: '#fff' }}
      />
      {uniformDetails.isError ? (
        <Error retry={() => uniformDetails.refetch()} />
      ) : uniformDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={uniformId}
          form={form}
          title="update_member"
          request={updateUniform}
          cancelText={'Cancel'}
          okText={'Save'}
          data={uniformDetails.data}
          onCancel={() => history.push('/uniforms')}
          onSuccess={() => history.push('/uniforms')}
          // cleanBeforeSending={cleanBeforeSendingArea}
          invalidate={['getUniforms', 'getUniformDetails']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={UniformDescriptionForm} />
            <FormBlock form={form} formTemplate={ProductsForm} />
          </div>
        </FormWrap>
      )}
    </>
  );
};

export default EditUniforms;

export const UniformDescriptionForm = {
  title: 'Description',
  key: [],
  sections: [
    {
      columns: 4,
      fields: [
        {
          label: 'Name',
          isRequired: true,
          key: ['name'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const ProductsForm = {
  title: 'Products',
  sections: [
    {
      canAdd: true,
      key: ['products'],
      // addSuffix: 'number',
      columns: 4,
      noDivider: true,
      // title: 'Product',
      deleteBtn: { label: 'Delete', style: 'line' },
      addBtn: { label: 'Add product', style: 'primary' },
      fields: [
        {
          label: 'Product',
          type: 'select',
          isRequired: true,
          key: ['product'],
          requestOption: getCompanyProducts,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};
