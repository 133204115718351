import { INSTANCE, INSTANCELIST } from './API';

export const getGenres = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter } = queryKey[1];

    INSTANCELIST({
      method: 'get',
      url: '/genres',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getGenre = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const [, id] = queryKey;
    INSTANCE({
      method: 'get',
      url: `/genres/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const createGenre = (form) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: '/genres',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export const updateGenre = (form, id) => {
  return new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/genres/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });
};

export const deleteGenre = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey;
    INSTANCELIST({
      method: 'delete',
      url: `/genres/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });
