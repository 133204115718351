/* eslint-disable jsx-a11y/anchor-is-valid */
import { Badge, Select } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

const Status = ({
  status,
  id,
  config,
  keyRequest,
  fieldKey,
  editRequest,
  onChange,
  onChangeKey,
  isLoading,
  supData,
  noPoint,
}) => {
  const queryClient = useQueryClient();

  const badgeColor = (key) => config.find((e) => e.key === key)?.color || '';

  const mutation = useMutation((values) => editRequest(...values), {
    onSuccess: (data) => {
      queryClient.setQueryData(keyRequest, (oldData) => {
        let oldDataIndex = oldData.list.findIndex((e) => e.id === data.id);
        oldData.list[oldDataIndex][fieldKey] = data[fieldKey];
        return oldData;
      });
    },
  });

  return (
    <Select
      style={{ width: '100%' }}
      bordered={false}
      className="status-select"
      loading={mutation.isLoading || isLoading}
      value={status}
      onChange={(e) => {
        if (onChangeKey !== e) {
          if (supData) {
            mutation.mutate([{ [fieldKey]: e, ...supData(e) }, id]);
          } else {
            mutation.mutate([{ [fieldKey]: e }, id]);
          }
        } else {
          onChange(e);
        }
      }}
    >
      {config?.map((status) => (
        <Select.Option
          disabled={status.disabled}
          key={status.key}
          value={status.key}
        >
          {noPoint ? (
            status.label || status.key
          ) : (
            <Badge
              color={badgeColor(status.key)}
              text={status.label || status.key}
            />
          )}
        </Select.Option>
      ))}
    </Select>
  );
};

// export const OrderStatus = ({ paymentStatus, id, keyRequest }) => {
//   const queryClient = useQueryClient()

//   if (paymentStatus === 'ccl') {
//     return <Badge color={'red'} text={paymentStatus} />
//   }

//   return (
//     <Status
//       status={paymentStatus}
//       fieldKey={'paymentStatus'}
//       requestName={'getOrders'}
//       keyRequest={keyRequest}
//       editRequest={EditOrders}
//       supData={(e) =>
//         e === 'ccl'
//           ? {
//               status: 'discontinued',
//               packs: [],
//               singleItems: [],
//               vouchers: [],
//             }
//           : {}
//       }
//       id={id}
//       config={[
//         { key: 'pending', color: 'grey', disabled: true },
//         { key: 'paid', color: 'green' },
//         { key: 'ccl', color: 'red' },
//         { key: 'rfd', color: 'blue' },
//       ]}
//     />
//   )
// }

export default Status;
