export const SunglassesDetailsForm = {
  title: 'Sunglasses details',
  display: 'Sunglasses',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Choose frame',
          type: 'select',
          isRequired: true,
          key: ['frame'],
          options: [
            { key: 'gr_1', label: 'GR-1' },
            { key: 'gr_2', label: 'GR-2' },
            { key: 'gr_3', label: 'GR-3' },
            { key: 'gr_4', label: 'GR-4' },
            { key: 'gr_5', label: 'GR-5' },
            { key: 'gr_6', label: 'GR-6' },
            { key: 'gr_7', label: 'GR-7' },
            { key: 'gr_8', label: 'GR-8' },
            { key: 'gr_9', label: 'GR-9' },
            { key: 'gr_10', label: 'GR-10' },
            { key: 'gr_11', label: 'GR-11' },
            { key: 'gr_12', label: 'GR-12' },
            { key: 'gr_13', label: 'GR-13' },
            { key: 'gr_14', label: 'GR-14' },
            { key: 'gr_15', label: 'GR-15' },
            { key: 'gr_16', label: 'GR-16' },
            { key: 'gr_17', label: 'GR-17' },
            { key: 'gr_18', label: 'GR-18' },
          ],
          pos: {
            col: 1,
            row: 1,
            size: 2,
          },
        },
        {
          label: 'Choose lens',
          type: 'select',
          isRequired: true,
          key: ['lens'],
          options: [
            { key: 'l1', label: 'L1 - Classic black' },
            { key: 'l2', label: 'L2 - Mirror black' },
            { key: 'l3', label: 'L3 - Mirror black pink' },
            { key: 'l4', label: 'L4 - Dark purple shade 2 colors' },
            { key: 'l5', label: 'L5 - Café shade 2 colors' },
            { key: 'l6', label: 'L6 - Light purple shade 3 colors' },
            { key: 'l7', label: 'L7 - Others' },
          ],
          pos: {
            col: 1,
            row: 2,
            size: 2,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const SunglassesMeasureForm = {
  title: 'Final measurement Sunglasses',
  display: 'Sunglasses',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [],
    },
  ],
};
