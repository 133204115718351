import { Form, PageHeader } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import { editOrderUser, getOrderUser } from '../../network/B2B';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import queryString from 'query-string';

const EditCompanies = ({ history }) => {
  let { customerId } = useParams();
  const { search } = useLocation();
  const [form] = Form.useForm();

  const { prev } = queryString.parse(search);
  console.log(prev, search);

  const companyDetails = useQuery(
    [
      'getOrderUser',
      {
        id: customerId,
      },
    ],
    () => getOrderUser(customerId),
    {
      onSuccess: (data) => {
        form.setFieldsValue(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Edit User'}
        onBack={() =>
          history.push(prev ? `/companies/orders/${prev}` : '/companies/orders')
        }
        style={{ backgroundColor: '#fff' }}
      />
      {companyDetails.isError ? (
        <Error retry={() => companyDetails.refetch()} />
      ) : companyDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={customerId}
          form={form}
          title="update_member"
          request={editOrderUser}
          cancelText={'Cancel'}
          okText={'Save'}
          data={companyDetails.data}
          onCancel={() =>
            history.push(
              prev ? `/companies/orders/${prev}` : '/companies/orders'
            )
          }
          onSuccess={() =>
            history.push(
              prev ? `/companies/orders/${prev}` : '/companies/orders'
            )
          }
          // cleanBeforeSending={cleanBeforeSendingArea}
          invalidate={['getOrderUser', 'getCompanyOrderDetails']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={UniformDescriptionForm} />
          </div>
        </FormWrap>
      )}
    </>
  );
};

export default EditCompanies;

export const UniformDescriptionForm = {
  title: 'Description',
  key: [],
  sections: [
    {
      columns: 4,
      fields: [
        {
          label: 'First name',
          isRequired: true,
          key: ['firstName'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Last name',
          isRequired: true,
          key: ['lastName'],
          pos: {
            col: 2,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Chinese name',
          isRequired: true,
          key: ['chineseName'],
          pos: {
            col: 3,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Gender',
          isRequired: true,
          key: ['gender'],
          type: 'select',
          options: [
            { key: 'male', label: 'Male' },
            { key: 'female', label: 'Female' },
          ],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Phone',
          isRequired: true,
          key: ['phone'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Email',
          isRequired: true,
          key: ['email'],
          pos: {
            col: 2,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Shoe size',
          isRequired: true,
          type: 'number',
          key: ['shoeSize'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        // {
        //   label: 'Country',
        //   isRequired: true,
        //   key: ['country'],
        //   pos: {
        //     col: 2,
        //     row: 2,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Zip Code',
        //   isRequired: true,
        //   key: ['zipCode'],
        //   pos: {
        //     col: 3,
        //     row: 2,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Address',
        //   type: 'text',
        //   isRequired: true,
        //   key: ['address'],
        //   pos: {
        //     col: 1,
        //     row: 3,
        //     size: 2,
        //   },
        // },
      ],
    },
  ],
};
