import { useState, useMemo } from 'react';
import { Typography, Form as StyleForm, Input, Button } from 'antd';
import { useMutation } from 'react-query';
import { Form, Formik } from 'formik';
import { Redirect, useLocation } from 'react-router-dom';

const { Title, Text } = Typography;

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search).get('token'), [search]);
}

const Reset = ({ history }) => {
  let token = useQuery();
  const [resetStep, setResetStep] = useState(0);

  const resetPassword = useMutation(
    () => {
      /*login(values.pass)*/
    },
    {
      onSuccess: () => setResetStep(1),
    }
  );

  if (!token) {
    return <Redirect to="/" />;
  }

  const FormContent = ({ values, errors, handleChange }) => {
    switch (resetStep) {
      case 0:
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title level={3} className="login-title">
                Reset your password
              </Title>
              <Text className="login-subtitle">
                Please choose a new password
              </Text>
            </div>

            <div className="input-wrap">
              <StyleForm.Item>
                <Input.Password
                  onChange={handleChange}
                  value={values.pass}
                  name="pass"
                  placeholder="Password"
                />
              </StyleForm.Item>
              <StyleForm.Item
                validateStatus={errors.cPass ? 'error' : null}
                help={errors.cPass ? errors.cPass : null}
              >
                <Input.Password
                  onChange={handleChange}
                  value={values.cPass}
                  name="cPass"
                  placeholder="Confirm password"
                />
              </StyleForm.Item>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                loading={resetPassword.isLoading}
                disabled={Object.keys(errors).length}
                // onClick={handleNext}
                type="primary"
                htmlType="submit"
              >
                Confirm new password
              </Button>
            </div>
          </>
        );

      case 1:
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title level={3} className="login-title">
                Your password has been reset
              </Title>
              <Text className="login-subtitle">
                You can now sign in with our new password
              </Text>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button onClick={() => history.pushState('/')} type="primary">
                Sign in
              </Button>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div
      className="login-wrapper"
      style={{ backgroundImage: 'url(/images/login-bg.png)' }}
    >
      <div className="login-window">
        <Formik
          initialValues={{
            passw: '',
            cPass: '',
          }}
          validate={(values) => {
            const errors = {};

            if (values.pass !== values.cPass) {
              errors.cPass = 'Passwords don’t match';
            }

            return errors;
          }}
          onSubmit={async (values) => resetPassword.mutate(values)}
        >
          {(formProps) => (
            <Form>
              <div
                className="login-logo"
                style={{ backgroundImage: 'url(/images/whitelogo.svg)' }}
              />
              <FormContent {...formProps} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Reset;
