import { Card, Checkbox, Form, Space, Typography } from 'antd';
import { useState } from 'react';

import FormSection from './FormSection';

const { Title } = Typography;

const FormBlock = ({
  formTemplate,
  className,
  weight = 1,
  form,
  blockKey = [],
  _name = [],
}) => {
  const { isOptional, title, key = [] } = formTemplate;
  const [displayForm, setDisplayForm] = useState(true);

  function onChange(e) {
    setDisplayForm(e.target.checked);
  }

  const dataReceived = (data) => data; // console.log(data)

  return (
    <Card
      key="FormBlock"
      className={'form-wrapper-card ' + className}
      style={{
        height: 'fit-content',
        flex: weight,
      }}
      title={
        <Space align="baseline" className="title-row">
          <Title level={4}>{title}</Title>
          {isOptional && (
            <Form.Item
              name={[...blockKey, ...isOptional]}
              valuePropName="checked"
            >
              <Checkbox checked={displayForm} onChange={onChange} />
            </Form.Item>
          )}
        </Space>
      }
    >
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          JSON.stringify(prevValues) !== JSON.stringify(currentValues)
        }
      >
        {({ getFieldValue }) => {
          const parentValue = getFieldValue(
            isOptional ? [...blockKey, ...isOptional] : []
          );

          return parentValue ? (
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                JSON.stringify(prevValues) !== JSON.stringify(currentValues)
              }
            >
              {formTemplate.sections?.map((section, index) => (
                <FormSection
                  blockKey={[...blockKey, ...key]}
                  channel={blockKey}
                  section={{
                    ...section,
                    noDivider:
                      index === formTemplate.sections.length - 1 &&
                      !section.canAdd
                        ? true
                        : section.canAdd,
                  }}
                  key={`section_${index}`}
                  dataRecieved={dataReceived}
                  form={form}
                  prev={[...blockKey, ...key]}
                />
              ))}
            </Form.Item>
          ) : null;
        }}
      </Form.Item>
      {/* {displayForm &&
                formTemplate.sections?.map((section, index) => (
                    <FormSection
                        blockKey={blockKey}
                        section={section}
                        key={`section_${index}`}
                        dataRecieved={dataRecieved}
                        form={form}
                    />
                ))} */}
    </Card>
  );
};

FormBlock.Basic = ({ formTemplate, blockKey = [], form }) => {
  const dataReceived = () => {}; //console.log(data)
  const { key = [] } = formTemplate;
  return (
    <>
      {formTemplate.sections?.map((section, index) => (
        <FormSection
          blockKey={[...blockKey, ...key]}
          channel={blockKey}
          section={{ ...section, noDivider: true }}
          key={`section_${index}`}
          dataRecieved={dataReceived}
          form={form}
          prev={[...blockKey, ...key]}
        />
      ))}
    </>
  );
};

export default FormBlock;
