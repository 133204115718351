import { Form, PageHeader } from 'antd';
import FormBlock from '../../components/forms/FormBlock';
import FormWrap from '../../components/forms/FormWrap';
import { createProductType } from '../../network/ProductTypes';
import { ProductTypesForm } from './ProductTypes';

export default function CreateProductType({ history }) {
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader className="site-page-header" title="Product Family" />
      <FormWrap
        form={form}
        title="create-product-types"
        request={createProductType}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/product-types')}
        onCancel={() => history.push('/product-types')}
        cleanBeforeSending={(oldData) => {
          if (oldData.image) {
            let main = oldData.image.fileList
              ? oldData.image?.fileList
              : oldData.image;
            if (!Array.isArray(main)) {
              main = [main];
            }
            const newPic = main.map((file) =>
              file.response ? file.response : file
            );

            oldData.image = newPic.length ? newPic[0] : {};
          } else {
            oldData.image = {};
          }
          return oldData;
        }}
        invalidate={['getProductTypes']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={ProductTypesForm} />
        </div>
      </FormWrap>
    </>
  );
}
