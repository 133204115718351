import { Form, PageHeader } from 'antd';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import FormBlock from '../../components/forms/FormBlock';
import FormWrap from '../../components/forms/FormWrap';
import { useStore } from '../../context/store';
import {
  MPLandingSectionForm,
  MPLandingSectionSelectedProductsForm,
  MPLandingSectionTitleGroupForm,
} from '../../forms/MPLandingSection';
import { getMPLandingSection, updateMPLandingSection } from '../../network/MP';

export default function EditSection({ history }) {
  const { sectionId } = useParams();
  const [form] = Form.useForm();
  const mp = useStore((state) => state.mp);
  const setForm = useStore((state) => state.setMP);
  const resetForm = useStore((state) => state.resetMP);

  const { data, isLoading } = useQuery(
    ['getSection', sectionId],
    getMPLandingSection,
    {
      onSuccess: (data) => {
        form.setFieldsValue(data);
        setForm(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    console.log(mp);
  }, [mp])

  return (
    <>
      <PageHeader className="site-page-header" title="Mini Program" />
      {isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={sectionId}
          title="edit_section"
          request={updateMPLandingSection}
          data={data}
          cancelText={'Cancel'}
          okText={'Save'}
          onSuccess={() => {
            history.push('/mp');
            resetForm();
          }}
          onCancel={() => {
            history.push('/mp');
            resetForm();
          }}
          onValuesChange={(_, allValues) => setForm(allValues)}
          cleanBeforeSending={(oldData) => {
            if (oldData.image) {
              let main = oldData.image.fileList
                ? oldData.image?.fileList
                : oldData.image;
              if (!Array.isArray(main)) {
                main = [main];
              }
              const newPic = main.map((file) =>
                file.response ? file.response : file
              );

              oldData.image = newPic.length ? newPic[0] : {};
            } else {
              oldData.image = {};
            }
            return oldData;
          }}
          invalidate={['getMPLandingSections']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={MPLandingSectionForm} />
            <FormBlock
              form={form}
              formTemplate={MPLandingSectionTitleGroupForm}
            />
            <FormBlock
              form={form}
              formTemplate={MPLandingSectionSelectedProductsForm}
            />
          </div>
        </FormWrap>
      )}
    </>
  );
}
