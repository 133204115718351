import { Form, PageHeader } from 'antd';
import { ProductTagForm } from './ProductTags';
import FormBlock from '../../components/forms/FormBlock';
import {
  createProductTag,
  getProductTag,
  updateProductTag,
} from '../../network/Tags';
import FormWrap from '../../components/forms/FormWrap';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loader from '../../components/Loader';

export default function EditProductTag({ history }) {
  let { tagId } = useParams();
  const [form] = Form.useForm();

  const { data, isLoading } = useQuery(
    ['getProductTag', { id: tagId }],
    getProductTag,
    {
      onSuccess: (data) => {
        form.setFieldsValue(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <PageHeader
        className={'site-page-header'}
        title={'Edit Tag'}
        subTitle={'Edit a tag'}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          title="edit_tag"
          id={tagId}
          form={form}
          data={data}
          request={updateProductTag}
          cancelText={'Cancel'}
          okText={'Save'}
          onSuccess={() => history.push('/product-tags')}
          onCancel={() => history.push('/product-tags')}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={ProductTagForm} />
          </div>
        </FormWrap>
      )}
    </>
  );
}
