import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import NavigationDrawer from './NavigationDrawer';

import Home from '../../pages/Home';
import Orders from '../../pages/Orders/Orders';
import EditOrder from '../../pages/Orders/EditOrder';
import ChooseTypeOrder from '../../pages/Orders/ChooseTypeOrder';
import CreateOrder from '../../pages/Orders/CreateOrder';
import ModifyOrder from '../../pages/Orders/ModifyOrder';
import CreateAdjustment from '../../pages/Orders/CreateAdjustment';
import Clients from '../../pages/Clients/Clients';
import CreateClient from '../../pages/Clients/CreateClient';
import EditClient from '../../pages/Clients/EditClient';
import ViewClient from '../../pages/Clients/ViewClient';
import Products from '../../pages/Products/Products';
import CreateProduct from '../../pages/Products/CreateProduct';
import EditProduct from '../../pages/Products/EditProduct';
import Categories from '../../pages/Categories/Categories';
import CreateCategory from '../../pages/Categories/CreateCategory';
import EditCategories from '../../pages/Categories/EditCategories';
import Stores from '../../pages/Stores';
import Members from '../../pages/Members/Members';
import CreateMember from '../../pages/Members/CreateMember';
import EditMember from '../../pages/Members/EditMember';
import Metrics from '../../pages/Metrics';

import Companies from '../../pages/Companies/Companies';
import CreateCompany from '../../pages/Companies/CreateCompany';
import EditCompany from '../../pages/Companies/EditCompany';

import EditUser from '../../pages/Companies/EditUser';
import CompaniesOrders from '../../pages/Companies/Orders';
import CreateCompanyOrder from '../../pages/Companies/CreateOrder';
import EditCompanyOrder from '../../pages/Companies/EditOrder';

import Uniforms from '../../pages/Uniforms/Uniforms';
import CreateUniform from '../../pages/Uniforms/CreateUniform';
import EditUniform from '../../pages/Uniforms/EditUniform';

import Genre from '../../pages/Genre/Genre';
import CreateGenre from '../../pages/Genre/CreateGenre';
import EditGenre from '../../pages/Genre/EditGenre';

import { useAuth } from '../../hooks/useAuth';
import AuthRoute from '../Routes/AuthRoute';
import { ROLES } from '../../constants/common';
import MiniProgram from '../../pages/MiniProgram/MiniProgram';
import CreateSection from '../../pages/MiniProgram/CreateSection';
import EditSection from '../../pages/MiniProgram/EditSection';
import CreateProductTag from '../../pages/Tags/CreateProductTag';
import EditProductTag from '../../pages/Tags/EditProductTag';
import ProductTags from '../../pages/Tags/ProductTags';
import CreateProductType from '../../pages/ProductTypes/CreateProductType';
import EditProductType from '../../pages/ProductTypes/EditProductType';
import ProductTypes from '../../pages/ProductTypes/ProductTypes';

const { Content } = Layout;

const Dashboard = () => {
  const { signOut, user } = useAuth();

  return (
    <>
      <div
        style={{
          backgroundColor: '#000',
          height: '50px',
          padding: '0.5rem 1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundImage: 'url(/images/whitelogo.svg)',
            backgroundRepeat: 'no-repeat',
            // backgroundPosition: 'center',
            backgroundSize: 'contain',
            height: '100%',
            width: '100%',
          }}
        />
        {/* <Tooltip title={timeBeforeExpire}> */}
        <LogoutOutlined
          onClick={signOut}
          style={{ color: '#fff', cursor: 'pointer' }}
        />
        {/* </Tooltip> */}
      </div>
      <Layout style={{ height: 'calc(100vh - 50px)' }}>
        <NavigationDrawer />
        <Layout
          className="site-layout"
          style={{ height: '95vh', overflowX: 'hidden', overflowY: 'scroll' }}
        >
          <Content>
            <Switch>
              <AuthRoute
                path="/metrics"
                component={Metrics}
                user={user}
                role={[
                  ROLES.TAILOR,
                  ROLES.STYLIST,
                  ROLES.STORE_ASSISTANT,
                  ROLES.MARKETING,
                  ROLES.PRODUCTION_DEPARTMENT,
                ]}
              />

              <AuthRoute
                path="/members/create"
                component={CreateMember}
                user={user}
              />
              <AuthRoute
                path="/members/:memberId"
                component={EditMember}
                user={user}
              />
              <AuthRoute path="/members" component={Members} user={user} />

              <AuthRoute path="/stores" component={Stores} user={user} />

              <AuthRoute
                path="/categories/create"
                component={CreateCategory}
                user={user}
                role={[
                  ROLES.TAILOR,
                  ROLES.MARKETING,
                  ROLES.PRODUCTION_DEPARTMENT,
                ]}
              />
              <AuthRoute
                path="/categories/:categoryId"
                component={EditCategories}
                user={user}
                role={[
                  ROLES.TAILOR,
                  ROLES.MARKETING,
                  ROLES.PRODUCTION_DEPARTMENT,
                ]}
              />
              <AuthRoute
                path="/categories"
                component={Categories}
                user={user}
                role={[
                  ROLES.TAILOR,
                  ROLES.MARKETING,
                  ROLES.PRODUCTION_DEPARTMENT,
                ]}
              />

              <AuthRoute
                path="/product-types/create"
                component={CreateProductType}
                user={user}
                role={[]}
              />
              <AuthRoute
                path="/product-types/:productTypeId"
                component={EditProductType}
                user={user}
                role={[]}
              />
              <AuthRoute
                path="/product-types"
                component={ProductTypes}
                user={user}
                role={[]}
              />

              <AuthRoute
                path="/products/create"
                component={CreateProduct}
                user={user}
                role={[
                  ROLES.TAILOR,
                  ROLES.MARKETING,
                  ROLES.PRODUCTION_DEPARTMENT,
                ]}
              />
              <AuthRoute
                path="/products/:productId"
                component={EditProduct}
                user={user}
                role={[
                  ROLES.TAILOR,
                  ROLES.MARKETING,
                  ROLES.PRODUCTION_DEPARTMENT,
                ]}
              />
              <AuthRoute
                path="/products"
                component={Products}
                user={user}
                role={[
                  ROLES.TAILOR,
                  ROLES.MARKETING,
                  ROLES.PRODUCTION_DEPARTMENT,
                ]}
              />

              <AuthRoute
                path="/clients/create"
                component={CreateClient}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST, ROLES.STORE_ASSISTANT]}
              />
              <Route path="/clients/:clientId/view" component={ViewClient} />
              <AuthRoute
                path="/clients/:clientId"
                component={EditClient}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST, ROLES.STORE_ASSISTANT]}
              />
              <Route path="/clients" component={Clients} />

              <AuthRoute
                path="/orders/create/new"
                component={CreateClient}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST]}
              />
              <AuthRoute
                path="/orders/create/select"
                component={Clients}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST]}
              />
              <AuthRoute
                path="/orders/create/:userId"
                component={CreateOrder}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST]}
              />
              <AuthRoute
                path="/orders/create"
                exact
                component={ChooseTypeOrder}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST]}
              />

              <AuthRoute
                path="/adjustments/create/new"
                component={CreateClient}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST, ROLES.STORE_ASSISTANT]}
              />
              <AuthRoute
                path="/adjustments/create/select"
                component={Clients}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST, ROLES.STORE_ASSISTANT]}
              />
              <AuthRoute
                path="/adjustments/create/:userId"
                component={CreateAdjustment}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST, ROLES.STORE_ASSISTANT]}
              />
              <AuthRoute
                path="/adjustments/create"
                exact
                component={ChooseTypeOrder}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST, ROLES.STORE_ASSISTANT]}
              />

              <AuthRoute
                path="/orders/:orderId/edit"
                component={ModifyOrder}
                user={user}
                role={[ROLES.TAILOR, ROLES.STYLIST]}
              />
              <Route path="/orders/:orderId" component={EditOrder} />
              <Route path="/orders" component={Orders} />

              <AuthRoute
                path="/uniforms/create"
                component={CreateUniform}
                user={user}
                role={[ROLES.PRODUCTION_DEPARTMENT]}
              />
              <AuthRoute
                path="/uniforms/:uniformId"
                component={EditUniform}
                user={user}
                role={[ROLES.PRODUCTION_DEPARTMENT]}
              />
              <AuthRoute
                path="/uniforms"
                component={Uniforms}
                user={user}
                role={[ROLES.PRODUCTION_DEPARTMENT]}
              />

              <AuthRoute
                path="/companies/create"
                component={CreateCompany}
                user={user}
                role={[ROLES.PRODUCTION_DEPARTMENT]}
              />
              <AuthRoute
                path="/companies/orders/create"
                component={CreateCompanyOrder}
                user={user}
                role={[ROLES.PRODUCTION_DEPARTMENT]}
              />
              <AuthRoute
                path="/companies/orders/:orderId"
                component={EditCompanyOrder}
                user={user}
                role={[ROLES.PRODUCTION_DEPARTMENT]}
              />
              <AuthRoute
                path="/companies/orders"
                component={CompaniesOrders}
                user={user}
                role={[ROLES.PRODUCTION_DEPARTMENT]}
              />
              <AuthRoute
                path="/companies/customers/:customerId"
                component={EditUser}
                user={user}
                role={[ROLES.PRODUCTION_DEPARTMENT]}
              />
              <AuthRoute
                path="/companies/:companyId"
                component={EditCompany}
                user={user}
                role={[ROLES.PRODUCTION_DEPARTMENT]}
              />
              <AuthRoute
                path="/companies"
                component={Companies}
                user={user}
                role={[ROLES.PRODUCTION_DEPARTMENT]}
              />
              <AuthRoute
                path="/genres"
                exact
                component={Genre}
                user={user}
                role={[]}
              />
              <AuthRoute
                path="/genres/create"
                component={CreateGenre}
                user={user}
                role={[]}
              />
              <AuthRoute
                path="/genres/:genreId"
                component={EditGenre}
                user={user}
                role={[]}
              />
              <AuthRoute
                path="/mp/create"
                component={CreateSection}
                user={user}
                role={[]}
              />
              <AuthRoute
                path="/mp/:sectionId"
                component={EditSection}
                user={user}
                role={[]}
              />
              <AuthRoute
                path="/mp"
                component={MiniProgram}
                user={user}
                role={[]}
              />
              <AuthRoute
                path={'/product-tags/create'}
                component={CreateProductTag}
                user={user}
                role={[]}
              />
              <AuthRoute
                path={'/product-tags/:tagId'}
                component={EditProductTag}
                user={user}
                role={[]}
              />
              <AuthRoute
                path={'/product-tags'}
                component={ProductTags}
                user={user}
                role={[]}
              />

              {/* <Route path="/auth/reset" component={Reset} /> */}
              <Route path="/" component={Home} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Dashboard;
