import { Form, PageHeader } from 'antd';

import FormBlock from '../../components/forms/FormBlock';
import FormWrap from '../../components/forms/FormWrap';
import {
  CategoriesDescriptionForm,
  CategoriesFieldsFormCreate,
  CategoriesMediaForm,
  CategoriesPriorityWeightForm,
  CategoriesPublicationForm,
} from '../../forms/Categories';
import { createCategory as requestCat } from '../../network/Categories';

const CreateCategory = ({ history }) => {
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Create collection"
        onBack={() => history.push('/categories')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        title="create_category"
        request={requestCat}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/categories')}
        onCancel={() => history.push('/categories')}
        cleanBeforeSending={(oldData) => {
          if (oldData.mainPicture) {
            let main = oldData.mainPicture.fileList
              ? oldData.mainPicture?.fileList
              : oldData.mainPicture;

            if (!Array.isArray(main)) {
              main = [main];
            }
            const newPic = main.map((file) =>
              file.response ? file.response : file
            );

            oldData.mainPicture = newPic.length ? newPic[0] : {};
          } else {
            oldData.mainPicture = {};
          }
          return oldData;
        }}
        invalidate={['getProductCategories']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={CategoriesDescriptionForm} />
          <FormBlock form={form} formTemplate={CategoriesMediaForm} />
          <FormBlock form={form} formTemplate={CategoriesFieldsFormCreate} />
          <FormBlock.Basic formTemplate={CategoriesPublicationForm} />
          <FormBlock.Basic formTemplate={CategoriesPriorityWeightForm} />
        </div>
      </FormWrap>
    </>
  );
};

export default CreateCategory;
