import { PageHeader } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const CreateOrder = ({ _history }) => {
  const location = useLocation();

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={`Create ${
          location.pathname.includes('orders') ? 'order' : 'adjustment'
        }`}
        style={{ backgroundColor: '#fff' }}
      />
      <div className="order-wrapper">
        <Link className="order-tile" to={`${location.pathname}/new`}>
          New customer
        </Link>
        <Link className="order-tile" to={`${location.pathname}/select`}>
          Existing customer
        </Link>
      </div>
    </>
  );
};

export default CreateOrder;
