import { Form, PageHeader } from 'antd';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import { MembersDescriptionForm } from '../../forms/Users';
import { createUser } from '../../network/Users';

const CreateMember = ({ history }) => {
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Create team member'}
        onBack={() => history.push('/members')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        form={form}
        title="create_client"
        request={createUser}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/members')}
        onCancel={() => history.push('/members')}
        // cleanBeforeSending={cleanBeforeSendingArea}
        invalidate={['getUsers']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={MembersDescriptionForm} />
        </div>
      </FormWrap>
    </>
  );
};

export default CreateMember;
