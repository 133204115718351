import {
  Button,
  Card,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Space,
  Form as StyleForm,
  Typography,
  message,
} from 'antd'
import { FieldArray, Form, Formik } from 'formik'
import get from 'lodash.get'
import merge from 'lodash.merge'
import set from 'lodash.set'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { Payment, formOptions } from '../../forms/blocks'
import { getProductsBasic } from '../../network/API'
import {
  get3Dlook,
  getClientDetails,
  updateClient,
} from '../../network/Clients'
import {
  createOrder as createOrderRequest,
  getOrdersMine,
} from '../../network/Orders'
import { findLastIndex, threeDParsing, PRIORITY_MAP } from '../../utils/utils'
import FormContent from './FormContent'

import Error from '../../components/Error'
import Loader from '../../components/Loader'
import {
  BirthdayFormOrder,
  ClientsBodyMeasurementFormExt,
  ClientsBodyMeasurementFormOrder,
  ClientsFootForm,
  DefaultComplexion,
  OrderBodyPics,
  OrderComplexionFormP1,
  OrderComplexionFormP2,
  TDLookForm,
} from '../../forms/Clients'

const { Text, Title } = Typography

const getFromKey = (prod) =>
  prod.orderProductType === 'custom'
    ? prod.orderCustom?.type
    : prod.orderProductType

// const get3DLookPerson = () => {
//   var axios = require('axios')

//   var config = {
//     method: 'get',
//     url: 'https://saia.3dlook.me/api/v2/persons/485803/',
//     headers: {
//       Authorization: 'APIKey c6860cd7460cb09e1eb2d52db46d7d0329386a04',
//     },
//   }

//   return axios(config)
//     .then(function (response) {
//       return response.data
//     })
//     .catch(function (error) {
//       return error
//     })
// }

const CreateOrder = ({ history }) => {
  let { userId } = useParams()
  const myRef = useRef(null)
  const [step, setStep] = useState(1)
  const [innerStep, setInnerStep] = useState(0)
  const [afterStep3, setAfterStep3] = useState(false)
  const queryClient = useQueryClient()
  const [haveOldValue, setHaveOldValue] = useState(false)

  const clientDetails = useQuery(
    [
      'getClientDetails',
      {
        id: userId,
      },
    ],
    getClientDetails,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )

  const client3DLDetails = useQuery(
    [
      '3DLook',
      {
        id: userId,
      },
    ],
    () => (process.env.NODE_ENV === 'production' ? get3Dlook(userId) : {}),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )

  const clientOrders = useQuery(
    [
      'getOrdersMine',
      {
        sort: ['createdAt', 'DESC'],
        filter: {
          customer: userId,
        },
      },
    ],
    getOrdersMine,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  const createOrder = useMutation(createOrderRequest, {
    onSuccess: () => history.push('/orders'),
    onError: (error) => {
      // console.log('err', error.response)
      message.error(
        error.response?.data?.debugLog?.message ||
          error.response?.data?.message ||
          'An error occurs'
      )
    },
  })

  const saveUser = useMutation((val) => updateClient(val, userId), {})

  const handleBack = () => {
    if (step === 1) {
      history.push('/orders/create/select')
    } else {
      myRef.current?.scrollIntoView()
      setStep(step - 1)
      // if (step === 4) setAfterStep3(false)
    }
  }

  const handleNext = () => {
    if (step === 5) {
      history.push('/orders')
    } else {
      myRef.current?.scrollIntoView()
      setStep(step + 1)
      // if (step === 3) setAfterStep3(true)
    }
  }

  const saveUserInfos = async (values) => {
    let clientInfos = get(clientDetails, 'data', {})

    let bodyMeasurements = undefined
    if (get(values, 'bodyMeasures.measurementSource', '') === 'af') {
      bodyMeasurements = {
        ...get(clientInfos, 'bodyMeasurements', {}),
        ...get(values, 'bodyMeasures', {}),
      }
    }

    // add foot measurements data to client profile
    let footMeasurements = {
      ...get(clientInfos, 'foot', {}),
      ...get(values, 'foot', {}),
    }
    values.orderProducts.forEach((product) => {
      if (
        product.orderProductType === 'custom' &&
        product.orderCustom.type === 'shoes'
      ) {
        const skuMeasurementShoes =
          product.skuMeasurements[0].skuMeasurementShoes
        if (skuMeasurementShoes) {
          footMeasurements = {
            footMeasure: skuMeasurementShoes.footMeasure,
            strongFoot: skuMeasurementShoes.strongFoot,
            picturesLeft: skuMeasurementShoes.picturesLeft,
            picturesRight: skuMeasurementShoes.picturesRight,
          }
        }
      }
    })

    const newInfos = {
      ...clientInfos,
      complexion: {
        ...get(clientInfos, 'complexion', {}),
        ...get(values, 'complexion', {}),
      },
      bodyPictures: get(values, 'bodyPictures', []),
      bodyMeasurements,
      foot: footMeasurements,

      birthDate: get(values, 'birthDate', undefined),
      country: get(values, 'country', undefined),
      city: get(values, 'city', undefined),
      nationality: get(values, 'nationality', undefined),
    }

    saveUser.mutate(newInfos)
  }

  const saveBeforeSwitch = (values) => {
    let clientInfos = get(clientDetails, 'data', {})

    const newInfos = {
      ...clientInfos,
      complexion: {
        ...get(clientInfos, 'complexion', {}),
        ...get(values, 'complexion', {}),
      },
      bodyPictures: get(values, 'bodyPictures', []),
      bodyMeasurements: get(values, 'bodyMeasures', {}),
      foot: { ...get(clientInfos, 'foot', {}), ...get(values, 'foot', {}) },

      birthDate: get(values, 'birthDate', undefined),
      country: get(values, 'country', undefined),
      city: get(values, 'city', undefined),
      nationality: get(values, 'nationality', undefined),
    }

    saveUser.mutate(newInfos)

    queryClient.setQueryData(
      [
        'getClientDetails',
        {
          id: userId,
        },
      ],
      (oldData) => ({ ...oldData, ...newInfos })
    )
  }

  return (
    <div className="" ref={myRef}>
      <PageHeader
        className="site-page-header"
        title="Create order"
        onBack={() => history.push('/orders/create/select')}
        style={{ backgroundColor: '#fff' }}
      />
      {clientDetails.isError ? (
        <Error retry={() => clientDetails.refetch()} />
      ) : clientDetails.isLoading || client3DLDetails.isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            channel: 'store',
            customer: userId,
            address: get(clientDetails, 'data.addresses.0', {}),
            // personaeType: 'special_event',
            bodyMeasures: {
              height: 170,
              weight: 89,
              measurementSource: 'af',
              ...get(clientDetails, 'data.bodyMeasurements', {}),
            },
            bodyPictures: get(clientDetails, 'data.bodyPictures', []),
            complexion: {
              ...DefaultComplexion,
              ...get(clientDetails, 'data.complexion', DefaultComplexion),
            },
            foot: { ...get(clientDetails, 'data.foot', {}) },
            orderProducts: [],
            discount: 0,

            birthDate: clientDetails?.data?.birthDate
              ? moment(clientDetails?.data?.birthDate)
              : undefined,
            country: get(clientDetails, 'data.country', undefined),
            city: get(clientDetails, 'data.city', undefined),
            nationality: get(clientDetails, 'data.nationality', undefined),

            validated: false,
          }}
          validate={(values) => {
            const errors = {}
            if (step === 1) {
              if (!values.orderProducts.length) {
                errors.orderProducts = 'At least a product is required'
              } else {
                for (let i = 0; i < values.orderProducts.length; i++) {
                  const path = ['orderProducts', i]
                  const currentProduct = values.orderProducts[i]
                  if (
                    currentProduct.orderProductType !== 'collection' &&
                    currentProduct.orderProductType !== 'adjustment'
                  ) {
                    if (!get(currentProduct, `orderCustom.price`, null)) {
                      set(
                        errors,
                        `orderProducts.${i}.orderCustom.price`,
                        'Required'
                      )
                    }

                    formOptions[getFromKey(currentProduct)]?.forms.forEach(
                      (form) =>
                        form.form.forEach((prodForm) =>
                          prodForm.sections.forEach((section) => {
                            if (section.canAdd) {
                              section.fields.forEach((field) => {
                                const type = getFromKey(currentProduct)
                                if (type === 'shirt') {
                                  const extraShirts =
                                    currentProduct.orderCustom.orderCustomShirt
                                      .extraShirts
                                  if (extraShirts) {
                                    //validation
                                    extraShirts.forEach((item, index) => {
                                      const fieldValue = get(
                                        values,
                                        [
                                          ...path,
                                          form.key,
                                          ...prodForm.key,
                                          ...section.key,
                                          index,
                                          ...field.key,
                                        ].join('.'),
                                        ''
                                      )

                                      const name =
                                        [
                                          ...path,
                                          form.key,
                                          ...prodForm.key,
                                          ...section.key,
                                          index,
                                        ].join('.') + '.'
                                      const fieldIsRequired =
                                        field.isConditionalRequired
                                          ? field.isConditionalRequired(
                                              values,
                                              name
                                            )
                                          : !!field.isRequired
                                      const valueIsOk =
                                        !!fieldValue || fieldValue === 0
                                      const valueIsDisplay =
                                        !field.isHiding ||
                                        (field.isHiding &&
                                          !field.isHiding(values, name))

                                      if (
                                        fieldIsRequired &&
                                        !valueIsOk &&
                                        valueIsDisplay
                                      ) {
                                        set(
                                          errors,
                                          [
                                            ...path,
                                            form.key,
                                            ...prodForm.key,
                                            ...section.key,
                                            index,
                                            ...field.key,
                                          ].join('.'),
                                          'This field is required'
                                        )
                                      }
                                    })
                                  }
                                }
                              })
                            } else {
                              section.fields.forEach((field) => {
                                const isOldField = field.isOldField
                                if (isOldField) {
                                  setHaveOldValue(true)
                                }
                                const fieldValue = get(
                                  values,
                                  [
                                    ...path,
                                    form.key,
                                    ...prodForm.key,
                                    ...field.key,
                                  ].join('.'),
                                  ''
                                )
                                const name =
                                  [...path, form.key, ...prodForm.key].join(
                                    '.'
                                  ) + '.'
                                const fieldIsRequired =
                                  field.isConditionalRequired
                                    ? field.isConditionalRequired(values, name)
                                    : !!field.isRequired
                                const valueIsOk =
                                  !!fieldValue || fieldValue === 0
                                const valueIsDisplay =
                                  !field.isHiding ||
                                  (field.isHiding &&
                                    !field.isHiding(values, name))

                                const isNoValid = field.isNoValid
                                  ? field.isNoValid(values, name)
                                  : null

                                if (
                                  fieldIsRequired &&
                                  !valueIsOk &&
                                  valueIsDisplay
                                ) {
                                  set(
                                    errors,
                                    [
                                      ...path,
                                      form.key,
                                      ...prodForm.key,
                                      ...field.key,
                                    ].join('.'),
                                    'This field is required'
                                  )
                                } else if (isNoValid) {
                                  set(
                                    errors,
                                    [
                                      ...path,
                                      form.key,
                                      ...prodForm.key,
                                      ...field.key,
                                    ].join('.'),
                                    isNoValid
                                  )
                                }
                              })
                            }
                          })
                        )
                    )
                  } else if (currentProduct.orderProductType === 'adjustment') {
                    const adjustmentProduct = get(
                      values,
                      `orderProducts.${i}.orderAdjustment`,
                      []
                    )
                    if (!adjustmentProduct.type) {
                      set(
                        errors,
                        `orderProducts.${i}.orderAdjustment.type`,
                        'Select a product type'
                      )
                    } else {
                      formOptions[adjustmentProduct.type].forms.forEach(
                        (form) =>
                          form.form.forEach((prodForm) =>
                            prodForm.sections.forEach((section) =>
                              section.fields.forEach((field) => {
                                const fieldValue = get(
                                  values,
                                  [
                                    ...path,
                                    form.key.replace(
                                      'orderCustom.',
                                      'orderAdjustment.'
                                    ),
                                    ...prodForm.key,
                                    ...field.key,
                                  ].join('.'),
                                  ''
                                )

                                const name =
                                  [
                                    ...path,
                                    form.key.replace(
                                      'orderCustom.',
                                      'orderAdjustment.'
                                    ),
                                    ...prodForm.key,
                                  ].join('.') + '.'
                                const fieldIsRequired =
                                  field.isConditionalRequired
                                    ? field.isConditionalRequired(values, name)
                                    : !!field.isRequired
                                const valueIsOk =
                                  !!fieldValue || fieldValue === 0
                                const valueIsDisplay =
                                  !field.isHiding ||
                                  (field.isHiding &&
                                    !field.isHiding(values, name))
                                if (
                                  fieldIsRequired &&
                                  !valueIsOk &&
                                  valueIsDisplay
                                ) {
                                  set(
                                    errors,
                                    [
                                      ...path,
                                      form.key.replace(
                                        'orderCustom.',
                                        'orderAdjustment.'
                                      ),
                                      ...prodForm.key,
                                      ...field.key,
                                    ].join('.'),
                                    'This field is required'
                                  )
                                }
                              })
                            )
                          )
                      )
                    }
                  } else {
                    const collectionProduct = get(
                      values,
                      `orderProducts.${i}.orderCollection`,
                      []
                    )

                    if (!collectionProduct.product) {
                      set(
                        errors,
                        `orderProducts.${i}.orderCollection.product`,
                        'Select product'
                      )
                    } else {
                      get(collectionProduct, 'optionsTemp', []).forEach(
                        (option, index) => {
                          if (!option) {
                            set(
                              errors,
                              `orderProducts.${i}.orderCollection.optionsTemp.${index}`,
                              'All options are required'
                            )
                          }
                        }
                      )
                    }

                    if (
                      !collectionProduct.price &&
                      collectionProduct.price !== 0
                    ) {
                      set(
                        errors,
                        `orderProducts.${i}.orderCollection.price`,
                        'The price is required'
                      )
                    }
                  }
                }
              }

              if (
                get(values, 'orderProducts.0.orderCustom.type', 0) ===
                'suit_3_pieces'
              ) {
                /// Pant
                // at least one of waist option 1 and waist option 2 should be filled
                const pantWaistOption1 = get(
                  values,
                  `orderProducts.0.orderCustom.orderCustomPant.waistOption1`,
                  0
                )
                const pantWaistOption2 = get(
                  values,
                  `orderProducts.0.orderCustom.orderCustomPant.waistOption2`,
                  0
                )
                if (!pantWaistOption1 && !pantWaistOption2) {
                  set(
                    errors,
                    `orderProducts.0.orderCustom.orderCustomPant.waistOption1`,
                    'At least one of waist option 1 and waist option 2 should be filled'
                  )
                }
              }
            }

            if (step === 2) {
              if (!values.bodyMeasures?.height) {
                set(errors, 'bodyMeasures.height', 'Required')
              }
              if (!values.bodyMeasures?.weight) {
                set(errors, 'bodyMeasures.weight', 'Required')
              }
              if (!values.bodyMeasures?.shoulder) {
                set(errors, 'bodyMeasures.shoulder', 'Required')
              }
              if (!values.bodyMeasures?.chest) {
                set(errors, 'bodyMeasures.chest', 'Required')
              }

              if (!values.bodyMeasures?.waistJacket) {
                set(errors, 'bodyMeasures.waistJacket', 'Required')
              }

              if (!values.bodyMeasures?.stomach) {
                set(errors, 'bodyMeasures.stomach', 'Required')
              }
              if (!values.bodyMeasures?.hips) {
                set(errors, 'bodyMeasures.hips', 'Required')
              }
              if (!values.bodyMeasures?.biceps) {
                set(errors, 'bodyMeasures.biceps', 'Required')
              }
              if (!values.bodyMeasures?.forearm) {
                set(errors, 'bodyMeasures.forearm', 'Required')
              }
              if (!values.bodyMeasures?.wrist) {
                set(errors, 'bodyMeasures.wrist', 'Required')
              }
              if (!values.bodyMeasures?.armHole) {
                set(errors, 'bodyMeasures.armHole', 'Required')
              }
              if (!values.bodyMeasures?.thigh) {
                set(errors, 'bodyMeasures.thigh', 'Required')
              }
              if (!values.bodyMeasures?.knee) {
                set(errors, 'bodyMeasures.knee', 'Required')
              }
              if (!values.bodyMeasures?.calf) {
                set(errors, 'bodyMeasures.calf', 'Required')
              }
              if (!values.bodyMeasures?.neck) {
                set(errors, 'bodyMeasures.neck', 'Required')
              }

              if (!values.bodyMeasures?.waistPant) {
                set(errors, 'bodyMeasures.waistPant', 'Required')
              }
              if (!values.bodyMeasures?.sideNeckPoint) {
                set(errors, 'bodyMeasures.sideNeckPoint', 'Required')
              }

              if (!values.foot?.footMeasure && values.foot?.footMeasure !== 0) {
                set(errors, 'foot.footMeasure', 'Required')
              }
              // if (!values.foot?.strongFoot) {
              //   set(errors, 'foot.strongFoot', 'Required')
              // }

              if (!values.bodyMeasures?.ankle) {
                set(errors, 'bodyMeasures.ankle', 'Required')
              }
            }

            if (step === 3) {
              // innerStep
              const path = ['orderProducts', innerStep]
              const currentProduct = values.orderProducts[innerStep]

              if (!currentProduct) return

              if (currentProduct.orderProductType === 'collection') {
                // to do check collection errors
                const collectionType = get(
                  currentProduct,
                  'orderCollection.productType',
                  []
                )

                for (const prodKey of collectionType) {
                  get(formOptions, `${prodKey}.measuresForms`, []).forEach(
                    (form) =>
                      // formOptions[prodKey].measuresForms.forEach((form) =>
                      form.form.forEach((prodForm) =>
                        prodForm.sections.forEach((section) =>
                          section.fields.forEach((field) => {
                            const name = [
                              ...path,
                              form.key,
                              ...prodForm.key,
                            ].join('.')
                            const fieldValue = get(
                              values,
                              name + '.' + field.key.join('.'),
                              ''
                            )

                            const fieldIsRequired = field.isConditionalRequired
                              ? field.isConditionalRequired(values, name + '.')
                              : !!field.isRequired

                            if (
                              fieldIsRequired &&
                              !fieldValue &&
                              fieldValue !== 0
                            ) {
                              set(
                                errors,
                                name + '.' + field.key.join('.'),
                                'This field is required'
                              )
                            }
                          })
                        )
                      )
                  )
                }
              } else if (currentProduct.orderProductType === 'adjustment') {
                // formOptions[currentProduct.type].measuresForms.forEach((form) =>
                //   form.form.forEach((prodForm) =>
                //     prodForm.sections.forEach((section) =>
                //       section.fields.forEach((field) => {
                //         const fieldValue = get(
                //           values,
                //           [
                //             ...path,
                //             form.key.replace(
                //               'orderCustom.',
                //               'orderAdjustment.'
                //             ),
                //             ...prodForm.key,
                //             ...field.key,
                //           ].join('.'),
                //           ''
                //         )
                //         if (
                //           field.isRequired &&
                //           !fieldValue &&
                //           fieldValue !== 0
                //         ) {
                //           set(
                //             errors,
                //             [
                //               ...path,
                //               form.key.replace(
                //                 'orderCustom.',
                //                 'orderAdjustment.'
                //               ),
                //               ...prodForm.key,
                //               ...field.key,
                //             ].join('.'),
                //             'This field is required'
                //           )
                //         }
                //       })
                //     )
                //   )
                // )
              } else {
                // TODO: refactor this in the future, this is ugly

                /// Jacket
                // "Waist" and "Chest" should have less than 18 cm difference
                const jacketWaist = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementJacketBlazer.waist`,
                  0
                )
                const jacketChest = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementJacketBlazer.chest`,
                  0
                )
                if (Math.abs(jacketWaist - jacketChest) > 18) {
                  set(
                    errors,
                    `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementJacketBlazer.waist`,
                    'Waist and chest difference should be less than 18cm'
                  )
                }

                /// Vest
                // The difference between "vest length front straight"
                // and "vest length back straight" should be less than 1cm
                const vestLengthFrontStraight = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementVest.lengthFrontStraight`,
                  0
                )
                const vestLengthBackStraight = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementVest.lengthBackStraight`,
                  0
                )
                const vestBottomShape = get(
                  currentProduct,
                  'orderCustom.orderCustomVest.vestBottomShape',
                  ''
                )
                if (
                  vestBottomShape === 'straight_bottom' &&
                  vestLengthFrontStraight &&
                  vestLengthBackStraight &&
                  Math.abs(vestLengthFrontStraight - vestLengthBackStraight) > 1
                ) {
                  set(
                    errors,
                    `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementVest.lengthFrontStraight`,
                    'Front and back length difference should be less than 1cm'
                  )
                }
                // The difference between "vest length front cross"
                // and "vest length back cross" should be less than 6cm
                const vestLengthFrontCross = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementVest.lengthFrontCross`,
                  0
                )
                const vestLengthBackCross = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementVest.lengthBackCross`,
                  0
                )
                if (
                  vestBottomShape === 'cross_arrow' &&
                  vestLengthFrontCross &&
                  vestLengthBackCross &&
                  Math.abs(vestLengthFrontCross - vestLengthBackCross) > 6
                ) {
                  set(
                    errors,
                    `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementVest.lengthFrontCross`,
                    'Front and back length difference should be less than 6cm'
                  )
                }
                // The difference between "chest" and "waist" should be larger than 12 cm
                const vestChest = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementVest.chest`,
                  0
                )
                const vestWaist = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementVest.waist`,
                  0
                )
                if (
                  vestChest &&
                  vestWaist &&
                  Math.abs(vestChest - vestWaist) > 12
                ) {
                  set(
                    errors,
                    `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementVest.chest`,
                    'Chest and waist difference cannot be larger than 12cm'
                  )
                }

                /// Shirt
                // The diff between lengthFront and lengthBack should be less than 18cm
                const shirtLengthFront = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementShirt.lengthFront`,
                  0
                )
                const shirtLengthBack = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementShirt.lengthBack`,
                  0
                )
                if (Math.abs(shirtLengthFront - shirtLengthBack) > 18) {
                  set(
                    errors,
                    `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementShirt.lengthFront`,
                    'Front and back length difference should be less than 18cm'
                  )
                }
                // Prefill "shirt length back" depending on "shirt length front"
                if (shirtLengthFront && !shirtLengthBack) {
                  set(
                    values,
                    `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementShirt.lengthBack`,
                    shirtLengthFront
                  )
                }
                // The diff between chest and waist should be less than 18cm
                const shirtChest = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementShirt.chest`,
                  0
                )
                const shirtWaist = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementShirt.waist`,
                  0
                )
                if (
                  shirtChest &&
                  shirtWaist &&
                  Math.abs(shirtChest - shirtWaist) > 18
                ) {
                  set(
                    errors,
                    `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementShirt.chest`,
                    'Chest and waist difference should be less than 18cm'
                  )
                }
                //  Prefill the "Shirt sleeve wrist (left)"
                //  depending on the field "Shirt sleeve wrist (right)" -> Can be modified
                const shirtSleeveWristRight = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementShirt.sleeveWristRight`,
                  0
                )
                const shirtSleeveWristLeft = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementShirt.sleeveWristLeft`,
                  0
                )
                if (shirtSleeveWristLeft && !shirtSleeveWristRight) {
                  set(
                    values,
                    `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementShirt.sleeveWristRight`,
                    shirtSleeveWristLeft
                  )
                }

                /// Jeans
                // The sum of "front crotch" and "back crotch" need to be equal with "whole crotch"
                const jeansFrontCrotch = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementJean.frontCrotch`,
                  0
                )
                const jeansBackCrotch = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementJean.backCrotch`,
                  0
                )
                const jeansWholeCrotch = get(
                  currentProduct,
                  `skuMeasurements.0.skuMeasurementJean.crotch`,
                  0
                )
                if (
                  jeansFrontCrotch &&
                  jeansBackCrotch &&
                  jeansWholeCrotch &&
                  jeansFrontCrotch + jeansBackCrotch !== jeansWholeCrotch
                ) {
                  set(
                    errors,
                    `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementJean.crotch`,
                    'The sum of "front crotch" and "back crotch" need to be equal with "whole crotch"'
                  )
                }

                get(
                  formOptions,
                  `${getFromKey(currentProduct)}.measuresForms`,
                  []
                ).forEach(
                  // formOptions[getFromKey(currentProduct)].measuresForms.forEach(
                  (form) =>
                    form.form.forEach((prodForm) =>
                      prodForm.sections.forEach((section) =>
                        section.fields.forEach((field) => {
                          const name = [
                            ...path,
                            form.key,
                            ...prodForm.key,
                          ].join('.')

                          const fieldValue = get(
                            values,
                            name + '.' + field.key.join('.'),
                            ''
                          )

                          const fieldIsRequired = field.isConditionalRequired
                            ? field.isConditionalRequired(values, name + '.')
                            : !!field.isRequired

                          const fieldIsDisplay =
                            !field.isHiding ||
                            (field.isHiding && !field.isHiding(values, name))

                          if (
                            fieldIsRequired &&
                            !fieldValue &&
                            fieldValue !== 0 &&
                            fieldIsDisplay
                          ) {
                            set(
                              errors,
                              name + '.' + field.key.join('.'),
                              'This field is required'
                            )
                          }
                        })
                      )
                    )
                )
              }
            }
            return errors
          }}
          onSubmit={async (values) => {
            let paymentStatus = 'unpaid'

            if (values.balance === 0) {
              paymentStatus = 'paid'
            } else if (values.deposit || values.deposit2) {
              paymentStatus = 'deposit'
            }

            const orderProducts = values.orderProducts.map((prod) =>
              prod.orderProductType === 'collection'
                ? {
                    ...prod,
                    orderCollection: {
                      ...prod.orderCollection,
                      options: prod.orderCollection.options?.map((option) => ({
                        ...option,
                        subOptions: option.subOptions.map((sub) => ({
                          ...sub,
                          selected: prod.orderCollection.optionsTemp.includes(
                            sub._id
                          ),
                        })),
                      })),
                    },
                  }
                : prod
            )

            /* not used code but overwirte useful data
            saveUserInfos({
              detailedAddress: get(values, 'detailedAddress', ''),
            })
            */
            createOrder.mutate({ ...values, orderProducts, paymentStatus })
          }}
        >
          {({ values, errors, handleChange, validateForm }) => (
            <Form style={{ padding: 24 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {step === 1 && (
                  <Step1
                    values={values}
                    errors={errors}
                    validateForm={validateForm}
                    handleChange={handleChange}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                )}
                {step === 2 && (
                  <Step2
                    values={values}
                    errors={errors}
                    validateForm={validateForm}
                    handleChange={handleChange}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    saveUserInfos={() => saveUserInfos(values)}
                    saveBeforeSwitch={() => saveBeforeSwitch(values)}
                    clientDetails={{
                      af: clientDetails.data,
                      dlook: client3DLDetails.data,
                    }}
                  />
                )}
                {step === 3 && (
                  <Step3
                    values={values}
                    errors={errors}
                    validateForm={validateForm}
                    innerStep={innerStep}
                    setInnerStep={setInnerStep}
                    afterStep3={afterStep3}
                    setAfterStep3={setAfterStep3}
                    handleChange={handleChange}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    clientOrders={clientOrders}
                  />
                )}
                {step === 4 && (
                  <Step4
                    values={values}
                    errors={errors}
                    // validateForm={validateForm}
                    handleChange={handleChange}
                  />
                )}
                {step === 5 && (
                  <Step5
                    values={values}
                    errors={errors}
                    // validateForm={validateForm}
                    handleChange={handleChange}
                  />
                )}
                {step !== 1 && step !== 2 && step !== 3 && (
                  <Space>
                    <Button
                      disabled={createOrder.isLoading}
                      onClick={handleBack}
                      type="secondary"
                    >
                      Previous
                    </Button>
                    {step !== 5 && (
                      <Button
                        disabled={Object.keys(errors).length}
                        onClick={() => {
                          if (step === 4) saveUserInfos(values)
                          handleNext()
                        }}
                        type="primary" /*htmlType="submit"*/
                      >
                        {step === 4 ? 'Payment' : 'Next'}
                      </Button>
                    )}
                    {step === 5 && (
                      <Button
                        loading={createOrder.isLoading}
                        disabled={Object.keys(errors).length}
                        // onClick={handleNext}
                        type="primary"
                        htmlType="submit"
                      >
                        Finish order
                      </Button>
                    )}
                  </Space>
                )}
              </Space>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default CreateOrder

const Step1 = ({
  values,
  errors,
  handleChange,
  handleBack,
  handleNext,
  validateForm,
}) => {
  const [isValidated, setIsValidated] = useState(false)
  const goNextPage = () => {
    // for (let i = 0; i < values.orderProducts.length; i++) {
    //   const currentProduct = values.orderProducts[i]
    //   const type = getFromKey(currentProduct)
    //   const forms =
    //     formOptions[
    //       type === 'adjustment' ? currentProduct.orderAdjustment.type : type
    //     ].forms

    //   let total = 0
    //   for (let j = 0; j < forms.length; j++) {
    //     const curPrice = get(
    //       values,
    //       `orderProducts.${i}.${forms[j].key.replace(
    //         'orderCustom.',
    //         type === 'adjustment' ? 'orderAdjustment.' : 'orderCustom.'
    //       )}.price`
    //     )

    //     const addMore = get(
    //       values,
    //       `orderProducts.${i}.${forms[j].key}.addMore`,
    //       0
    //     )

    //     total += curPrice * (addMore + 1)
    //   }

    //   total = parseFloat(total.toFixed(2))
    //   if (type === 'collection') {
    //     handleChange({
    //       target: {
    //         name: `orderProducts.${i}.orderCollection.price`,
    //         value: total,
    //       },
    //     })
    //   } else if (type === 'adjustment') {
    //     handleChange({
    //       target: {
    //         name: `orderProducts.${i}.orderAdjustment.price`,
    //         value: total,
    //       },
    //     })
    //   } else {
    //     handleChange({
    //       target: {
    //         name: `orderProducts.${i}.orderCustom.price`,
    //         value: total,
    //       },
    //     })
    //   }
    // }
    handleNext()
  }

  return (
    <>
      <FieldArray name="orderProducts">
        {({ remove, push }) => (
          <Space direction="vertical" style={{ width: '100%' }}>
            {values.orderProducts.length > 0 &&
              values.orderProducts.map((orderProduct, index) => (
                <>
                  <Space>
                    <Title level={3}>Product {index + 1}</Title>
                    <Button onClick={() => remove(index)} type="link">
                      Delete
                    </Button>
                  </Space>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 12,
                    }}
                  >
                    {Object.keys(formOptions).map((key) => (
                      <div
                        onClick={() => {
                          if (key === 'collection' || key === 'adjustment') {
                            handleChange({
                              target: {
                                name: `orderProducts.${index}`,
                                value: {
                                  orderProductType: key,
                                  [key === 'collection'
                                    ? 'orderCollection'
                                    : 'orderAdjustment']: {},
                                },
                              },
                            })
                          } else {
                            handleChange({
                              target: {
                                name: `orderProducts.${index}`,
                                value: formOptions[key].default,
                              },
                            })
                          }
                        }}
                        className={`order-btn auto${
                          getFromKey(values.orderProducts[index]) === key
                            ? ' selected'
                            : ''
                        }`}
                      >
                        <Text type="secondary" strong>
                          {formOptions[key].name}
                        </Text>
                      </div>
                    ))}
                  </div>
                  {getFromKey(values.orderProducts[index]) === 'collection' ? (
                    <CollectionForm
                      values={values}
                      errors={errors}
                      hideErrors={!isValidated}
                      path={`orderProducts.${index}`}
                      handleChange={handleChange}
                    />
                  ) : getFromKey(values.orderProducts[index]) ===
                    'adjustment' ? (
                    <AdjustmentForm
                      values={values}
                      errors={errors}
                      hideErrors={!isValidated}
                      path={`orderProducts.${index}`}
                      handleChange={handleChange}
                    />
                  ) : getFromKey(values.orderProducts[index]) ? (
                    <>
                      <StyleForm layout="vertical" style={{ width: '100%' }}>
                        <Card title={'Price'}>
                          <Space
                            size={0}
                            direction="vertical"
                            style={{ width: '100%' }}
                          >
                            {formOptions[
                              getFromKey(values.orderProducts[index])
                            ]?.priceList ? (
                              <StyleForm.Item>
                                <Select
                                  // name={`orderProducts.${index}.orderCustom.quality`}
                                  style={{ width: '50%' }}
                                  onChange={(e) => {
                                    handleChange({
                                      target: {
                                        name: `orderProducts.${index}.orderCustom.quality`,
                                        value: e,
                                      },
                                    })

                                    handleChange({
                                      target: {
                                        name: `orderProducts.${index}.orderCustom.price`,
                                        value:
                                          formOptions[
                                            getFromKey(
                                              values.orderProducts[index]
                                            )
                                          ].priceList[e].price,
                                      },
                                    })
                                  }}
                                  value={get(
                                    values,
                                    `orderProducts.${index}.orderCustom.quality`,
                                    ''
                                  )}
                                >
                                  {Object.keys(
                                    formOptions[
                                      getFromKey(values.orderProducts[index])
                                    ].priceList
                                  ).map((key) => (
                                    <Select.Option key={key} value={key}>
                                      {
                                        formOptions[
                                          getFromKey(
                                            values.orderProducts[index]
                                          )
                                        ].priceList[key].label
                                      }
                                    </Select.Option>
                                  ))}
                                </Select>
                              </StyleForm.Item>
                            ) : null}
                            <StyleForm.Item
                              validateStatus={
                                get(
                                  errors,
                                  `orderProducts.${index}.orderCustom.price`,
                                  false
                                )
                                  ? 'error'
                                  : null
                              }
                              help={get(
                                errors,
                                `orderProducts.${index}.orderCustom.price`,
                                null
                              )}
                            >
                              <Input
                                name={`orderProducts.${index}.orderCustom.price`}
                                type="text"
                                placeholder={'-'}
                                style={{ width: '50%' }}
                                onChange={handleChange}
                                value={get(
                                  values,
                                  `orderProducts.${index}.orderCustom.price`,
                                  ''
                                )}
                                disabled={
                                  !formOptions[
                                    getFromKey(values.orderProducts[index])
                                  ]?.isPriceEditable
                                }
                              />
                            </StyleForm.Item>
                          </Space>
                        </Card>
                      </StyleForm>

                      <FormContent
                        haveOldValue={true}
                        values={values}
                        errors={errors}
                        hideErrors={!isValidated}
                        path={`orderProducts.${index}`}
                        handleChange={handleChange}
                        forms={
                          formOptions[getFromKey(values.orderProducts[index])]
                            ?.forms
                        }
                      />
                    </>
                  ) : null}
                  {getFromKey(values.orderProducts[index]) && (
                    <div>
                      <StyleForm layout="vertical" style={{ width: '100%' }}>
                        <Card title={'Persona Type'}>
                          <Space
                            size={0}
                            direction="vertical"
                            style={{ width: '100%' }}
                          ></Space>
                          <Button
                            type={
                              get(
                                values,
                                `orderProducts.${index}.orderCustom.personaeType`
                              ) === 'special_event'
                                ? 'primary'
                                : 'secondary'
                            }
                            onClick={() =>
                              handleChange({
                                target: {
                                  name: `orderProducts.${index}.orderCustom.personaeType`,
                                  value: 'special_event',
                                },
                              })
                            }
                          >
                            Special Event
                          </Button>
                          <Button
                            type={
                              get(
                                values,
                                `orderProducts.${index}.orderCustom.personaeType`
                              ) === 'wardrobe_update'
                                ? 'primary'
                                : 'secondary'
                            }
                            onClick={() =>
                              handleChange({
                                target: {
                                  name: `orderProducts.${index}.orderCustom.personaeType`,
                                  value: 'wardrobe_update',
                                },
                              })
                            }
                          >
                            Wardrobe Update
                          </Button>
                        </Card>
                      </StyleForm>
                    </div>
                  )}
                </>
              ))}

            <Space>
              <Button onClick={handleBack} type="secondary">
                Previous
              </Button>
              <Button
                type="primary"
                // className=
                onClick={() =>
                  push({
                    orderProductType: undefined,
                  })
                }
                // danger={errors.orderProducts ? true : false}
              >
                Add Product
              </Button>
              <Button
                // disabled={Object.keys(errors).length}
                onClick={() => {
                  validateForm().then((res) => {
                    if (Object.keys(res).length) {
                      setIsValidated(true)

                      let t = []
                      while (
                        typeof get(res, t.join('.')) === 'object' ||
                        !t.length
                      ) {
                        let curr = !t.length ? res : get(res, t.join('.'))

                        let kk = Object.keys(curr)

                        t.push(kk[0])
                      }

                      var getMeTo = document.getElementById(t.join('.'))
                      getMeTo.scrollIntoView({ behavior: 'smooth' }, true)
                    } else {
                      goNextPage()
                    }
                  })
                }}
                type="secondary"
              >
                Next
              </Button>
            </Space>
          </Space>
        )}
      </FieldArray>
    </>
  )
}

const AdjustmentForm = ({ values, errors, path, handleChange, hideErrors }) => {
  let productTypes = Object.keys(formOptions).map((key) => ({
    key: key,
    label: formOptions[key].name,
  }))

  const adjustmentForms = formOptions[
    get(values, `${path}.orderAdjustment.type`, '')
  ]?.forms.map((e) => ({
    ...e,
    key: e.key.replace('orderCustom.', ''),
  }))

  const errType = get(errors, `${path}.orderAdjustment.type`, null)

  return (
    <StyleForm layout="vertical" style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card title="Choose product">
          <StyleForm.Item
            label="Product type"
            validateStatus={errType && !hideErrors ? 'error' : null}
            help={hideErrors ? null : errType}
          >
            <Select
              value={get(values, `${path}.orderAdjustment.type`, '')}
              style={{ width: '50%' }}
              onChange={(id) =>
                handleChange({
                  target: {
                    name: `${path}.orderAdjustment`,
                    value: formOptions[id].default.orderCustom,
                  },
                })
              }
            >
              {productTypes.map((item) =>
                item.key === 'collection' ||
                item.key === 'adjustment' ? null : (
                  <Select.Option value={item.key}>{item.label}</Select.Option>
                )
              )}
            </Select>
          </StyleForm.Item>
        </Card>
        {get(values, `${path}.orderAdjustment.type`, false) && (
          <FormContent
            values={values}
            errors={errors}
            hideErrors={hideErrors}
            path={`${path}.orderAdjustment`}
            handleChange={handleChange}
            forms={adjustmentForms}
          />
        )}
      </Space>
    </StyleForm>
  )
}

const CollectionForm = ({ values, errors, path, handleChange, hideErrors }) => {
  const products = useQuery(['getProductsBasic'], getProductsBasic, {
    refetchOnWindowFocus: false,
  })

  const productName = `${path}.orderCollection.product`
  const productDisplayName = `${path}.orderCollection.productName`
  const productType = `${path}.orderCollection.productType`
  const productPrice = `${path}.orderCollection.price`
  const productSavedOptions = `${path}.orderCollection.options`
  const productTempOptions = `${path}.orderCollection.optionsTemp`
  const product = get(values, productName, null)
  const price = get(values, productPrice, null)
  const productSelected = (id) =>
    get(products, 'data.list', []).find((e) => e.id === id)

  const errProd = get(errors, `${path}.orderCollection.product`, null)
  const errPrice = get(errors, `${path}.orderCollection.price`, null)

  return (
    <StyleForm layout="vertical" style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card title="Choose product">
          <Space size={0} direction="vertical" style={{ width: '100%' }}>
            <StyleForm.Item
              label="Product name"
              validateStatus={
                errProd && !hideErrors
                  ? 'error'
                  : products.isLoading
                  ? 'validating'
                  : null
              }
              help={hideErrors ? null : errProd}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption
                value={product}
                style={{ width: '50%' }}
                onChange={(id) => {
                  handleChange({
                    target: {
                      name: productName,
                      value: id,
                    },
                  })

                  handleChange({
                    target: {
                      name: productType,
                      value: productSelected(id)?.category.blocks,
                    },
                  })

                  handleChange({
                    target: {
                      name: productDisplayName,
                      value: `${productSelected(id)?.sku} ${
                        productSelected(id)?.name?.en ||
                        productSelected(id)?.name?.zh
                      }`,
                    },
                  })

                  handleChange({
                    target: {
                      name: productPrice,
                      value: productSelected(id)?.retailPrice,
                    },
                  })

                  if (productSelected(id)?.options?.length) {
                    handleChange({
                      target: {
                        name: productSavedOptions,
                        value: productSelected(id).options,
                      },
                    })
                    handleChange({
                      target: {
                        name: productTempOptions,
                        value: new Array(
                          productSelected(id).options.length
                        ).fill(null),
                      },
                    })
                  }
                }}
              >
                {get(products, 'data.list', []).map((prod) => (
                  <Select.Option value={prod.id}>
                    {prod.name?.en || prod.name?.zh}
                  </Select.Option>
                ))}
              </Select>
            </StyleForm.Item>
            {product && (
              <StyleForm.Item
                label="Price"
                validateStatus={errPrice && !hideErrors ? 'error' : null}
                help={hideErrors ? null : errPrice}
              >
                <InputNumber
                  min={0}
                  type="tel"
                  name={productPrice}
                  style={{ width: '50%' }}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: productPrice,
                        value: e,
                      },
                    })
                  }
                  value={price}
                  // disabled={field.isInactive}
                />
              </StyleForm.Item>
            )}
          </Space>
        </Card>
        {product && !!productSelected(product)?.options?.length && (
          <Card title="Options">
            <Space size={0} direction="vertical" style={{ width: '100%' }}>
              {productSelected(product)?.options.map((option, index) => {
                const err = get(errors, `${productTempOptions}.${index}`, null)

                return (
                  <StyleForm.Item
                    label={option?.name?.en || option?.name?.zh}
                    validateStatus={err && !hideErrors ? 'error' : null}
                    help={hideErrors ? null : err}
                  >
                    <Select
                      value={get(values, `${productTempOptions}.${index}`, '')}
                      style={{ width: '50%' }}
                      onChange={(id) => {
                        handleChange({
                          target: {
                            name: `${productTempOptions}.${index}`,
                            value: id,
                          },
                        })
                      }}
                    >
                      {option.subOptions?.map((subOption) => (
                        <Select.Option
                          key={subOption._id}
                          value={subOption._id}
                        >
                          {subOption.name?.en || subOption.name?.zh}
                        </Select.Option>
                      ))}
                    </Select>
                  </StyleForm.Item>
                )
              })}
            </Space>
          </Card>
        )}
      </Space>
    </StyleForm>
  )
}

const Step2 = ({
  values,
  errors,
  handleChange,
  handleBack,
  handleNext,
  validateForm,
  saveUserInfos,
  clientDetails,
  saveBeforeSwitch,
}) => {
  const [isValidated, setIsValidated] = useState(false)
  const didMountRef = useRef(false)

  const client3DLDetails = useQuery(
    [
      '3DLooks',
      {
        id: values.customer,
      },
    ],
    () =>
      process.env.NODE_ENV === 'production' ? get3Dlook(values.customer) : {},
    {
      initialData: clientDetails.dlook,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      onSuccess: (res) => {
        // // console.log('resok', res)
        // if (first) {
        //   setFirst(false)
        //   return
        // }

        // const { back_neck_to_hip_length, neck, chest, bust_height } =
        //   res?.front_params || {}

        // const {
        //   // ankle,
        //   // bicep,
        //   // waist,
        //   // calf,
        //   // abdomen,
        //   // low_hips,
        //   // thigh,
        //   // knee,
        //   // armscye_girth,
        //   // elbow_girth,
        //   // wrist,
        //   // high_hips,
        // } = res?.volume_params || {}

        // const { side_neck_point_to_upper_hip } = res?.side_params || {}
        if (res?.task_set?.is_ready === false) {
          message.warning('Measure not fully ready yet, refresh again later.')
        }

        const value = {
          measurementSource: '3dlook',

          height: get(values, 'bodyMeasures.height', res?.height),
          weight: get(values, 'bodyMeasures.weight', res?.weight),

          ...threeDParsing(res),
        }

        handleChange({
          target: {
            name: 'bodyMeasures',
            value,
          },
        })
      },
    }
  )

  const formsBody = [
    {
      form: [OrderComplexionFormP1],
      key: 'complexion',
    },
    {
      form: [BirthdayFormOrder],
      key: '',
    },
    {
      form: [
        ClientsBodyMeasurementFormExt,
        TDLookForm,
        ClientsBodyMeasurementFormOrder,
      ],
      key: 'bodyMeasures',
    },
    { form: [ClientsFootForm], key: '' },
  ]

  const handleGoNext = () => {
    saveUserInfos()
    values.orderProducts.forEach((product, index) => {
      if (product.orderProductType === 'custom') {
        // formOptions[getFromKey(product)].measuresForms.forEach((form) => {
        handleChange({
          target: {
            name: `orderProducts.${index}.skuMeasurements.0`,
            value: {
              ...formOptions[getFromKey(product)].measureDefault(
                get(values, `bodyMeasures`, {}),
                get(client3DLDetails, 'data', {}),
                get(values, `foot`, {}),
                get(values, 'complexion.fitStyle', 'standard_fit'),
                get(values, 'complexion.shoulderType', 'normal'),
                get(values, `orderProducts.${index}.orderCustom`, {})
              ),
              ...get(values, `orderProducts.${index}.skuMeasurements.0`, {}),
            },
          },
          // })
        })
      } else if (product.orderProductType === 'collection') {
        let newDefaultMeasures = {}

        product.orderCollection.productType.forEach((e) => {
          newDefaultMeasures = {
            ...formOptions[e].measureDefault(
              get(values, `bodyMeasures`, {}),
              get(client3DLDetails, 'data', {}),
              get(values, `foot`, {}),
              get(values, 'complexion.fitStyle', 'standard_fit'),
              get(values, 'complexion.shoulderType', 'normal')
            ),
            ...newDefaultMeasures,
          }
        })

        newDefaultMeasures = merge(
          newDefaultMeasures,
          get(values, `orderProducts.${index}.skuMeasurements.0`, {})
        )

        handleChange({
          target: {
            name: `orderProducts.${index}.skuMeasurements.0`,
            value: newDefaultMeasures,
          },
        })
      }
    })

    handleNext()
  }

  useEffect(() => {
    if (values?.bodyMeasures?.measurementSource === 'af') {
      handleChange({
        target: {
          name: 'bodyMeasures',
          value: {
            ...get(clientDetails, 'af.bodyMeasurements', {}),
            measurementSource: 'af',
            height: values.bodyMeasures.height,
            weight: values.bodyMeasures.weight,
          },
        },
      })
    } else {
      if (didMountRef.current) {
        saveBeforeSwitch(values)
      }

      if (!!client3DLDetails?.data) {
        handleChange({
          target: {
            name: 'bodyMeasures',
            value: {
              measurementSource: '3dlook',

              height: values?.bodyMeasures?.height,
              weight: values?.bodyMeasures?.weight,

              ...threeDParsing(client3DLDetails?.data),
            },
          },
        })
      }
    }
    didMountRef.current = true
  }, [values?.bodyMeasures?.measurementSource])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FormContent
        values={values}
        errors={errors}
        hideErrors={!isValidated}
        path=""
        handleChange={handleChange}
        forms={formsBody}
      />
      <Space>
        <Button onClick={handleBack} type="secondary">
          Previous
        </Button>
        <Button
          // disabled={Object.keys(errors).length}
          // onClick={handleGoNext}
          onClick={() => {
            validateForm().then((res) =>
              Object.keys(res).length ? setIsValidated(true) : handleGoNext()
            )
          }}
          type="primary" /*htmlType="submit"*/
        >
          Next
        </Button>
      </Space>
    </Space>
  )
}

const Step3 = ({
  values,
  errors,
  handleChange,
  handleBack,
  handleNext,
  afterStep3,
  setAfterStep3,
  innerStep,
  setInnerStep,
  validateForm,
  clientOrders,
}) => {
  const [isValidated, setIsValidated] = useState(false)

  const measureValues = values.orderProducts
    .map((prod, index) => {
      const priority = PRIORITY_MAP[getFromKey(prod)]
      return {
        product: prod,
        index,
        key: getFromKey(prod),
        priority,
      }
    })
    .sort((a, b) => (b.priority || 0) - (a.priority || 0))

  const maxStep = measureValues.length - 1
  const [measureStep, setMeasureStep] = useState(afterStep3 ? maxStep : 0)

  useEffect(() => {
    validateForm()
  }, [measureStep])

  useEffect(() => {
    if (!measureValues.length) {
      if (afterStep3) {
        handleBack()
        setAfterStep3(false)
      } else {
        handleNext()
        setAfterStep3(true)
      }
    } else if (afterStep3) {
      setInnerStep(measureValues[maxStep].index)
    } else {
      setInnerStep(measureValues[0].index)
    }
  }, [])

  const handleMeasureNext = () => {
    if (measureStep >= maxStep) {
      handleNext()
      setAfterStep3(true)
    } else {
      setInnerStep(measureValues[measureStep + 1].index)
      setMeasureStep(measureStep + 1)
    }
  }

  const handleMeasureBack = () => {
    if (measureStep <= 0) {
      handleBack()
      setAfterStep3(false)
    } else {
      setInnerStep(measureValues[measureStep - 1].index)
      setMeasureStep(measureStep - 1)
    }
  }

  const stepKey = get(
    measureValues,
    `${measureStep}.product.orderProductType`,
    false
  )

  const checkValidity = (id) => {
    validateForm().then((res) => {
      if (Object.keys(res).length) {
        setIsValidated(true)
      } else {
        if (!!id || id === 0) {
          setMeasureStep(id)
        } else {
          handleMeasureNext()
        }
        setIsValidated(false)
      }
    })
  }

  // const handleApplyPreviousSize = () => {
  //   let value = null
  //   if (lastMatching !== -1) {
  //     const skuMeasurements = get(
  //       measureValues,
  //       `${lastMatching}.product.skuMeasurements`
  //     )
  //     value = get(
  //       measureValues,
  //       `${lastMatching}.product.skuMeasurements.${skuMeasurements.length - 1}`,
  //       {}
  //     )
  //     console.log('lastMatching', value)
  //   } else if (lastOrderMatching !== -1) {
  //     const skuMeasurements = get(
  //       historySkuMeasurements,
  //       `${lastOrderMatching}.product.skuMeasurements`
  //     )
  //     value = get(
  //       historySkuMeasurements,
  //       `${lastOrderMatching}.product.skuMeasurements.${
  //         skuMeasurements.length - 1
  //       }`,
  //       {}
  //     )
  //   }

  //   let newValue = get(values, `orderProducts.${innerStep}.skuMeasurements.0`)
  //   console.log('lastOrderMatching', value, newValue)
  //   Object.keys(newValue).forEach((productKey) => {
  //     if (Object.keys(value).includes(productKey)) {
  //       newValue[productKey] = value[productKey]
  //     }
  //   })

  //   handleChange({
  //     target: {
  //       name: `orderProducts.${innerStep}.skuMeasurements.0`,
  //       value: newValue,
  //     },
  //   })

  //   // To auto-fill default value for sku measurements
  //   const currentProduct = values.orderProducts[innerStep]

  //   get(formOptions, `${getFromKey(currentProduct)}.measuresForms`, []).forEach(
  //     (form) => {
  //       let key = form.key
  //       let skuMeasurement = {
  //         ...get(values, `orderProducts.${innerStep}.${key}`),
  //       }

  //       form.form.forEach((prodForm) => {
  //         prodForm.sections.forEach((section) => {
  //           section.fields.forEach((field) => {
  //             if (
  //               field.default &&
  //               !get(
  //                 values,
  //                 `orderProducts.${innerStep}.${key}.${field.key.join('.')}`
  //               )
  //             ) {
  //               skuMeasurement[field.key.join('.')] = field.default
  //             }
  //           })
  //         })
  //       })

  //       set(values, `orderProducts.${innerStep}.${key}`, skuMeasurement)
  //     }
  //   )
  // }

  // const _isSameProduct = (e) => {
  //   let productType = get(e, 'product.orderCustom.type', '')
  //   if (productType === currentMeasureProductType) {
  //     return true
  //   }

  //   const suit3 = ['vest', 'jacket_blazer', 'pant', 'suit_2_pieces']
  //   const suit2 = ['jacket_blazer', 'pant']
  //   if (
  //     productType === 'suit_3_pieces' &&
  //     suit3.includes(currentMeasureProductType)
  //   ) {
  //     return true
  //   }
  //   if (
  //     productType === 'suit_2_pieces' &&
  //     suit2.includes(currentMeasureProductType)
  //   ) {
  //     return true
  //   }
  //   if (
  //     currentMeasureProductType === 'suit_3_pieces' &&
  //     suit3.includes(productType)
  //   ) {
  //     return true
  //   }
  //   return (
  //     currentMeasureProductType === 'suit_2_pieces' &&
  //     suit2.includes(productType)
  //   )
  // }

  // // befores is the products add before
  // let befores = [...measureValues]
  // befores.splice(measureStep, measureValues?.length - measureStep)

  // // get product type
  // let currentMeasureProductType = get(
  //   measureValues,
  //   `${measureStep}.product.orderCustom.type`,
  //   []
  // )

  // // get previous size
  // const lastMatching = findLastIndex(befores, _isSameProduct)

  // // No last matching size, find in the history orders
  // let historySkuMeasurements = []
  // if (lastMatching === -1) {
  //   // push the product ordered before into history
  //   if (clientOrders.data?.length > 0) {
  //     clientOrders.data.forEach((order) => {
  //       if (order.orderProducts.length > 0) {
  //         order.orderProducts.forEach((product) => {
  //           let currType = get(product, 'orderCustom.type', '')
  //           historySkuMeasurements.push({
  //             product: product,
  //             index: historySkuMeasurements.length,
  //             key: currType,
  //           })
  //         })
  //       }
  //     })
  //   }
  // }
  // // get previous size from history orders
  // const lastOrderMatching = findLastIndex(
  //   historySkuMeasurements,
  //   _isSameProduct
  // )

  const handleApplyPreviousSize = () => {
    const cpyMeasure = JSON.parse(JSON.stringify([...measureValues]))
    let prevMeasureValues = JSON.parse(JSON.stringify(cpyMeasure))
      .reverse()
      .toSpliced(0, cpyMeasure?.length - measureStep)
    const currentMeasureProductType = get(cpyMeasure, `${measureStep}.key`, '')
    console.log('kkkk', cpyMeasure)

    let keys = []
    if (currentMeasureProductType === 'collection') {
      keys = Object.keys(
        get(cpyMeasure, `${measureStep}.product.skuMeasurements.0`, {})
      ).filter((e) => {
        if (
          (e === 'skuMeasurementShoes' &&
            !cpyMeasure[measureStep].product.skuMeasurements[0]
              ?.skuMeasurementShoes?.footMeasure) ||
          (e === 'skuMeasurementSkirt' &&
            !cpyMeasure[measureStep].product.skuMeasurements[0]
              ?.skuMeasurementSkirt?.waist) ||
          e === '_id'
        ) {
          return false
        } else {
          return true
        }
      })
    } else {
      const forms = get(
        formOptions,
        `${currentMeasureProductType}.measuresForms`
      )
      keys = forms.map((e) => {
        const key = e.key.split('.')

        return key[key.length - 1]
      })
    }

    const productValues = []
    for (const key of keys) {
      productValues.push({
        pathKey: key,
        value: null,
        order: null,
      })
    }

    let filled = 0
    for (const prevMeasure of prevMeasureValues) {
      if (filled === keys.length) break

      // const objectKeys = Object.keys(  prevMeasure.product.skuMeasurements[0])
      const objectKeys = Object.keys(
        values.orderProducts[prevMeasure.index].skuMeasurements[0]
      )

      for (const key of objectKeys) {
        const idx = productValues.findIndex((prod) => prod.pathKey === key)

        if (
          idx !== -1 &&
          !JSON.parse(JSON.stringify(productValues[idx].value))
        ) {
          set(
            productValues,
            `${idx}.value`,
            prevMeasure.product.skuMeasurements[0][key]
          )
          filled++
        }
      }
    }

    if (filled < keys.length) {
      for (const order of clientOrders.data) {
        if (filled === keys.length) break

        const reverseOrder = [...(order.orderProducts || [])].reverse()

        for (const prod of reverseOrder) {
          const lastMeasureIdx = prod.skuMeasurements?.length - 1

          if (!!prod.skuMeasurements?.length) {
            const currKeys = Object.keys(
              prod.skuMeasurements[lastMeasureIdx]
            ).filter((e) => {
              if (
                (e === 'skuMeasurementShoes' &&
                  !prod.skuMeasurements[lastMeasureIdx]?.skuMeasurementShoes
                    ?.footMeasure) ||
                (e === 'skuMeasurementSkirt' &&
                  !prod.skuMeasurements[lastMeasureIdx]?.skuMeasurementSkirt
                    ?.waist) ||
                e === '_id'
              ) {
                return false
              } else {
                return true
              }
            })

            for (const key of currKeys) {
              const idx = productValues.findIndex(
                (prod) => prod.pathKey === key
              )

              if (
                idx !== -1 &&
                !productValues[idx].value &&
                !!prod.skuMeasurements[lastMeasureIdx][key]
              ) {
                set(
                  productValues,
                  `${idx}.value`,
                  prod.skuMeasurements[lastMeasureIdx][key]
                )
                set(productValues, `${idx}.order`, order.orderID)
                filled++
              }
            }
          }
        }
      }
    }

    for (const info of productValues) {
      if (!!info.value) {
        handleChange({
          target: {
            name: `orderProducts.${innerStep}.skuMeasurements.0.${info.pathKey}`,
            value: info.value,
          },
        })
      }
    }
  }

  // const handleUsePreviousSize = () => {
  //   console.log('kkkk handleUsePreviousSize')
  //   const prevMeasureValues = [...measureValues]
  //   let currentMeasureProductType = get(
  //     measureValues,
  //     `${measureStep}.product.orderCustom.type`,
  //     []
  //   )
  //   const forms = [...formOptions[currentMeasureProductType].measuresForms]

  //   // const keys = formOptions[currentMeasureProductType].measuresForms.map((e) =>
  //   //   e.key.split('.')
  //   // )
  //   // let productInfos = []

  //   const productInfos = get(
  //     formOptions,
  //     `${currentMeasureProductType}.measuresForms`,
  //     []
  //   ).map((e) => {
  //     const key = e.key.split('.')

  //     return {
  //       key: key[key.length - 1],
  //       values: undefined,
  //     }
  //   })
  //   console.log('kkkk productInfos old 1', productInfos)
  //   for (const form of forms) {
  //     const key = form.key.split('.')

  //     productInfos.push({
  //       key: key[key.length - 1],
  //       values: undefined,
  //     })
  //   }

  //   console.log('kkkk productInfos old 2', productInfos)

  //   prevMeasureValues
  //     .splice(measureStep, measureValues?.length - measureStep)
  //     .reverse()
  //   console.log('kkkk prevMeasureValues', prevMeasureValues)

  //   console.log('kkkk productInfos old 2.1', productInfos)
  //   for (const prevMeasure of prevMeasureValues) {
  //     const objectKeys = Object.keys(prevMeasure.product.skuMeasurements[0])

  //     for (const key of objectKeys) {
  //       const idx = productInfos.findIndex((prod) => prod.key === key)

  //       if (idx !== -1 && !productInfos[idx].values) {
  //         set(
  //           productInfos,
  //           `${idx}.values`,
  //           prevMeasure.product.skuMeasurements[0][key]
  //         )
  //         // productInfos[idx].values = prevMeasure.product.skuMeasurements[0][key]
  //       }
  //     }

  //     // Object.keys(prevMeasure.product.skuMeasurements[0]).forEach((key) => {

  //     // })
  //   }
  //   console.log('kkkk productInfos old 2.2', productInfos)
  //   // prevMeasureValues.forEach((e) => {
  //   //   Object.keys(e.product.skuMeasurements[0]).forEach((key) => {
  //   //     const idx = productInfos.findIndex((prod) => prod.key === key)

  //   //     if (idx !== -1 && !productInfos[idx].values) {
  //   //       productInfos[idx].values = e.product.skuMeasurements[0][key]
  //   //     }
  //   //   })
  //   // })

  //   console.log('kkkk productInfos 2.3', productInfos)
  //   let isFilled = true
  //   for (const info of productInfos) {
  //     if (!info.values) {
  //       isFilled = false
  //     }
  //   }
  //   // productInfos.forEach((e) => {
  //   //   if (!e.values) {
  //   //     isFilled = false
  //   //   }
  //   // })
  //   console.log('kkkk isFilled', isFilled)

  //   if (!isFilled) {
  //     // const ordersSorted =
  //     for (const order of clientOrders.data) {
  //       console.log('kkkk order', order.createdAt)
  //       const reverseOrder = [...(order.orderProducts || [])].reverse()

  //       for (const prod of reverseOrder) {
  //         // console.log('kkkk prod', prod)
  //         const lastMeasureIdx = prod.skuMeasurements?.length - 1

  //         if (!!prod.skuMeasurements?.length) {
  //           const keys = Object.keys(prod.skuMeasurements[lastMeasureIdx])
  //           // console.log('kkkk prod', prod)

  //           for (const key of keys) {
  //             const idx = productInfos.findIndex((prod) => prod.key === key)

  //             if (idx !== -1 && !productInfos[idx].values) {
  //               console.log('kkkk prod', key, order.orderID, order)
  //               productInfos[idx].values =
  //                 prod.skuMeasurements[lastMeasureIdx][key]
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   for (const info of productInfos) {
  //     if (!!info.values) {
  //       handleChange({
  //         target: {
  //           name: `orderProducts.${innerStep}.skuMeasurements.0.${info.key}`,
  //           value: info.values,
  //         },
  //       })
  //     }
  //   }
  // }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          flexDirection: 'row',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Text strong>{formOptions[measureValues[measureStep].key].name}</Text>
          <div
            style={{
              color: '#1890ff',
            }}
          >
            {measureValues.map((e, idx) =>
              idx === measureStep ? (
                <span key={idx}>&#9679;</span>
              ) : (
                <span
                  key={idx}
                  style={{ cursor: 'pointer' }}
                  onClick={() => checkValidity(idx)}
                >
                  &#9675;
                </span>
              )
            )}
          </div>
        </div>
        <Button
          loading={clientOrders.isLoading}
          // disabled={lastMatching === -1 && lastOrderMatching === -1}
          onClick={handleApplyPreviousSize}
          type="primary"
        >
          Use previous size
        </Button>
      </div>
      {measureValues.length > 0 &&
        (stepKey === 'collection' ? (
          get(
            measureValues,
            `${measureStep}.product.orderCollection.productType`,
            []
          ).map((e) =>
            formOptions[e]?.measuresForms ? (
              <FormContent
                key={`orderProducts.${measureValues[measureStep]?.index}`}
                values={values}
                errors={errors}
                hideErrors={!isValidated}
                path={`orderProducts.${measureValues[measureStep]?.index}`}
                handleChange={handleChange}
                forms={formOptions[e]?.measuresForms}
              />
            ) : (
              <div
                key={`orderProducts.${measureValues[measureStep]?.index}`}
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 21,
                  margin: 16,
                }}
              >
                No measurement.
              </div>
            )
          )
        ) : stepKey === 'adjustment' ? (
          <FormContent
            values={values}
            errors={errors}
            hideErrors={!isValidated}
            path={`orderProducts.${measureValues[measureStep]?.index}`}
            handleChange={handleChange}
            forms={
              formOptions[
                measureValues[measureStep]?.product?.orderAdjustment?.type
              ]?.measuresForms
            }
          />
        ) : formOptions[measureValues[measureStep]?.key]?.measuresForms ? (
          <FormContent
            values={values}
            errors={errors}
            hideErrors={!isValidated}
            path={`orderProducts.${measureValues[measureStep]?.index}`}
            handleChange={handleChange}
            forms={formOptions[measureValues[measureStep]?.key]?.measuresForms}
          />
        ) : (
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 21,
              margin: 16,
            }}
          >
            No measurement.
          </div>
        ))}

      <Space>
        <Button onClick={handleMeasureBack} type="secondary">
          Previous
        </Button>
        <Button
          // disabled={Object.keys(errors).length}
          // onClick={handleMeasureNext}
          onClick={() => checkValidity()}
          type="primary" /*htmlType="submit"*/
        >
          Next
        </Button>
      </Space>
    </Space>
  )
}

const Step4 = ({ values, errors, handleChange }) => {
  const formsComplexion = [
    { form: [OrderComplexionFormP2], key: 'complexion' },
    { form: [OrderBodyPics], key: '' },
  ]

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FormContent
        values={values}
        errors={errors}
        path=""
        handleChange={handleChange}
        forms={formsComplexion}
      />
    </Space>
  )
}

const Step5 = ({ values, errors, handleChange }) => {
  const formsPayment = [{ form: Payment, keys: '' }]

  useEffect(() => {
    let total = 0

    for (const prod of values.orderProducts) {
      switch (prod.orderProductType) {
        case 'collection':
          total += get(prod, 'orderCollection.price', 0)
          break
        case 'custom': {
          // extra shirts
          let extraShirts = get(
            prod,
            `orderCustom.orderCustomShirt.extraShirts`,
            []
          )
          extraShirts.forEach((item) => {
            total += get(item, 'priceNum', 0)
          })

          total += get(prod, 'orderCustom.price', 0)
          break
        }
        case 'adjustment':
          total += get(prod, 'orderAdjustment.price', 0)
          break

        default:
          break
      }
    }
    total = parseFloat(total.toFixed(2))

    handleChange({
      target: {
        name: 'totalSpent',
        value: total,
      },
    })
    handleChange({
      target: {
        name: 'balance',
        value:
          total -
          get(values, 'discount', 0) -
          get(values, 'deposit', 0) -
          get(values, 'deposit2', 0),
      },
    })
  }, [])

  useEffect(() => {
    let val = get(values, 'totalSpent', 0)
    val = parseFloat((val - get(values, 'discount', 0)).toFixed(2))
    val = parseFloat(
      (val - get(values, 'deposit', 0) - get(values, 'deposit2', 0)).toFixed(2)
    )

    handleChange({
      target: {
        name: 'balance',
        value: val,
      },
    })
  }, [values.totalSpent, values.discount, values.deposit, values.deposit2])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card title="Total Summary">
        {values.orderProducts.map((prod, index) => {
          const prodType = getFromKey(prod)
          let name
          let price = 0

          if (prodType === 'collection') {
            price = prod.orderCollection.price
            name = get(prod, `orderCollection.productName`, 'Collection')
          } else if (prodType === 'adjustment') {
            price = prod.orderAdjustment.price
            name = 'Adjustment'
          } else {
            price = prod.orderCustom.price
            //extra shirts
            let extraShirts = get(
              prod,
              'orderCustom.orderCustomShirt.extraShirts',
              []
            )
            extraShirts.forEach((item) => {
              price += get(item, 'priceNum', 0)
            })
            name = formOptions[prodType].name
          }

          return (
            <p key={index}>
              <strong>{`x${
                get(prod, 'orderCustom.orderCustomShirt.extraShirts', [])
                  .length + 1
              } ${name}:`}</strong>
              {` CNY ${price}`}
            </p>
          )
        })}
      </Card>
      <FormContent
        values={values}
        errors={errors}
        path=""
        handleChange={handleChange}
        forms={formsPayment}
      />
    </Space>
  )
}
