export const SkirtDetailsForm = {
  title: 'Skirt details',
  display: 'Skirt',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['fabricRef'],
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
        {
          label: 'Skirt length',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['length'],
          pos: {
            col: 1,
            // row: 3,
            size: 1,
          },
        },
        {
          label: 'Zipper',
          type: 'basicRadio',
          isRequired: true,
          key: ['zipper'],
          options: [
            { key: 'left', label: 'Left side' },
            { key: 'right', label: 'Right side' },
            { key: 'back', label: 'Back' },
          ],
          pos: {
            col: 1,
            // row: 4,
            size: 1,
          },
        },
        {
          label: 'Pleat',
          type: 'basicRadio',
          isRequired: true,
          key: ['pleat'],
          options: [
            { key: 'no_pleat', label: 'No pleat' },
            { key: 'single_pleat', label: 'Single pleat' },
            { key: 'double_pleats', label: 'Double pleats' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Pocket',
          type: 'basicRadio',
          isRequired: true,
          key: ['pocket'],
          options: [
            { key: 'yes', label: 'Yes' },
            { key: 'no', label: 'No' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Vents',
          type: 'basicRadio',
          isRequired: true,
          key: ['vents'],
          options: [
            { key: 'no_vent', label: 'No vent' },
            { key: 'back', label: 'Back' },
            { key: 'left', label: 'Left side' },
            { key: 'right', label: 'Right side' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Waistband',
          type: 'basicRadio',
          isRequired: true,
          key: ['waistband'],
          options: [
            { key: 'none', label: 'None' },
            { key: '3cm', label: '3 cm' },
            { key: '5cm', label: '5 cm' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const SkirtMeasureForm = {
  title: 'Final measurement Skirt',
  display: 'Skirt',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Waist',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['waist'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Hips',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['hips'],
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
        //
        {
          label: 'Skirt length',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['skirtLength'],
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
        {
          label: 'Skirt position',
          type: 'basicRadio',
          isRequired: true,
          default: 'yes',
          key: ['skirtPosition'],
          options: [
            {
              key: 'high',
              label: 'High',
            },
            {
              key: 'middle',
              label: 'Middle',
            },
            {
              key: 'low',
              label: 'Low',
            },
          ],
          pos: {
            col: 1,
            // row: 11,
            size: 1,
          },
        },
      ],
    },
  ],
};
