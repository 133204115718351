import { Form, PageHeader, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import { MembersDescriptionForm } from '../../forms/Users';
import { getUserDetails, updateUser, deleteUser } from '../../network/Users';
import Loader from '../../components/Loader';
import Error from '../../components/Error';

const EditMember = ({ history }) => {
  let { memberId } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const memberDetails = useQuery(
    [
      'getMemberDetails',
      {
        id: memberId,
      },
    ],
    getUserDetails,
    {
      // onSuccess: (data) => {
      //   form.setFieldsValue(data)
      // },
      refetchOnWindowFocus: false,
    }
  );

  const deleteMember = useMutation((id) => deleteUser(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('getUsers');
      history.push('/members');
    },
  });

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Edit team member'}
        onBack={() => history.push('/members')}
        style={{ backgroundColor: '#fff' }}
        extra={[
          <Button onClick={() => deleteMember.mutate(memberId)} danger>
            Delete
          </Button>,
        ]}
      />
      {memberDetails.isError ? (
        <Error retry={() => memberDetails.refetch()} />
      ) : memberDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={memberId}
          form={form}
          title="update_member"
          request={updateUser}
          cancelText={'Cancel'}
          okText={'Save'}
          data={memberDetails.data}
          onCancel={() => history.push('/members')}
          onSuccess={() => history.push('/members')}
          // cleanBeforeSending={cleanBeforeSendingArea}
          invalidate={['getUsers', 'getMemberDetails']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={MembersDescriptionForm} />
          </div>
        </FormWrap>
      )}
    </>
  );
};

export default EditMember;
