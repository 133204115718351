import { Button, PageHeader } from 'antd';
import TableBlock from '../../components/tables/TableBlock';
import { ROLE_PERMISSIONS } from '../../constants/common';
import { useAuth } from '../../hooks/useAuth';
import { getProductTags } from '../../network/Tags';

export default function ProductTags() {
  const { user } = useAuth();
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : [];

  const columns = (history, keyRequest) => [
    {
      title: 'Name',
      key: 'name.en',
      dataIndex: 'name',
      render: (name) => name.en,
    },
    {
      title: 'Name (Chinese)',
      key: 'name.zh',
      dataIndex: 'name',
      render: (name) => name.zh,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      render: (e) => {
        return (
          <div className={e.status}>
            <Button
              type="primary"
              onClick={() => {
                history.push(`product-tags/${e.id}`);
              }}
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];

  const filters = (history) => ({
    actions: [
      {
        label: '+ New Tag',
        isActive: permissions.includes('product-tags'),
        action: () => {
          history.push('/product-tags/create');
        },
      },
    ],
  });

  return (
    <>
      <PageHeader
        className={'site-page-header'}
        title={'Product Tags'}
        subTitle={'Manage product tags'}
      />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getProductTags'}
        request={getProductTags}
      />
    </>
  );
}

export const ProductTagForm = {
  title: 'Tag',
  key: [],
  sections: [
    {
      fields: [
        {
          label: 'Name',
          isRequired: true,
          key: ['name', 'en'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Name (Chinese)',
          isRequired: true,
          key: ['name', 'zh'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Family',
          isRequired: true,
          key: ['genre'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
      ],
    },
  ],
};
