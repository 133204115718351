import { Form, PageHeader } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';
import set from 'lodash.set';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import {
  ClientsDetailsForm,
  ClientsComplexionForm,
  ClientsBodyMeasurementForm,
  ClientsBodyMeasurementFormExt,
  BirthdayForm,
  ClientsBodyPicForm,
  ClientsFootFormClients,
  AddressesForm,
  ProductSizeForm,
} from '../../forms/Clients';
import { getClientDetails, updateClient } from '../../network/Clients';
import Loader from '../../components/Loader';
import Error from '../../components/Error';

const EditClient = ({ history }) => {
  let { clientId } = useParams();
  const [form] = Form.useForm();

  const clientDetails = useQuery(
    [
      'getClientDetails',
      {
        id: clientId,
      },
    ],
    getClientDetails,
    {
      // onSuccess: (data) => {
      //   data.birthDate = moment(data.birthDate)
      //   form.setFieldsValue(data)
      // },
      refetchOnWindowFocus: false,
    }
  );

  const formatData = (oldData) => {
    return { ...oldData, birthDate: moment(oldData.birthDate) };
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Customer information'}
        onBack={() => history.push('/clients')}
        style={{ backgroundColor: '#fff' }}
      />
      {clientDetails.isError ? (
        <Error retry={() => clientDetails.refetch()} />
      ) : clientDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={clientId}
          form={form}
          title="create_client"
          request={updateClient}
          data={formatData(clientDetails.data)}
          cancelText={'Cancel'}
          cleanBeforeSending={(oldData) => {
            if (oldData.bodyPictures) {
              const otherPic = oldData.bodyPictures.fileList
                ? oldData.bodyPictures?.fileList
                : oldData.bodyPictures;
              oldData.bodyPictures = otherPic.map((file) =>
                file.response ? file.response : file
              );
            } else {
              set(oldData, 'complexion.bodyPictures', []);
            }

            if (oldData.foot.picturesRight) {
              const otherPic = oldData.foot.picturesRight.fileList
                ? oldData.foot.picturesRight?.fileList
                : oldData.foot.picturesRight;
              oldData.foot.picturesRight = otherPic.map((file) =>
                file.response ? file.response : file
              );
            } else {
              set(oldData, 'foot.picturesRight', []);
            }
            if (oldData.foot.picturesLeft) {
              const otherPic = oldData.foot.picturesLeft.fileList
                ? oldData.foot.picturesLeft?.fileList
                : oldData.foot.picturesLeft;
              oldData.foot.picturesLeft = otherPic.map((file) =>
                file.response ? file.response : file
              );
            } else {
              set(oldData, 'foot.picturesLeft', []);
            }

            return oldData;
          }}
          okText={'Save'}
          onCancel={() => history.push('/clients')}
          onSuccess={() => history.push('/clients')}
          // cleanBeforeSending={cleanBeforeSendingArea}
          invalidate={['getClients', 'getClientDetails']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={ClientsDetailsForm} />
            <FormBlock form={form} formTemplate={BirthdayForm} />
            <FormBlock form={form} formTemplate={AddressesForm} />
            <FormBlock
              form={form}
              formTemplate={ClientsBodyMeasurementFormExt}
            />
            <FormBlock form={form} formTemplate={ClientsBodyPicForm} />
            <FormBlock form={form} formTemplate={ClientsComplexionForm} />
            <FormBlock form={form} formTemplate={ClientsBodyMeasurementForm} />
            <FormBlock form={form} formTemplate={ClientsFootFormClients} />
            <FormBlock form={form} formTemplate={ProductSizeForm} />
          </div>
        </FormWrap>
      )}
    </>
  );
};

export default EditClient;
