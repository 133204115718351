import { PageHeader, Button } from 'antd';
import { useQueryClient } from 'react-query';

import { getUsers } from '../../network/Users';
import TableBlock from '../../components/tables/TableBlock';

const Members = () => {
  const queryClient = useQueryClient();
  queryClient.invalidateQueries('getMemberDetails');

  return (
    <>
      <PageHeader className="site-page-header" title="Team member" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getUsers'}
        request={getUsers}
        infinite
      />
    </>
  );
};

export default Members;

const filters = (history) => ({
  fields: [
    {
      type: 'text',
      label: 'Name',
      key: 'name',
    },
  ],
  actions: [
    {
      label: '+ New user',
      isActive: true,
      action: () => history.push('/members/create'),
    },
  ],
});

const columns = (history) => [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Role',
    key: 'role',
    dataIndex: 'role',
  },
  {
    title: 'Store',
    key: 'store',
    dataIndex: 'store',
  },
  {
    title: '',
    key: 'action',
    width: 25,
    render: (e) => {
      return (
        <Button onClick={() => history.push(`members/${e.id}`)} type="primary">
          Edit
        </Button>
      );
    },
  },
];
