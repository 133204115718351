import { PageHeader } from 'antd';
import { Link } from 'react-router-dom';
import { ROLE_PERMISSIONS } from '../constants/common';
import { useAuth } from '../hooks/useAuth';

const Home = ({ _history }) => {
  const { user } = useAuth();

  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : [];

  return (
    <>
      <PageHeader className="site-page-header" title="Home" />
      <div className="home-wrapper">
        {permissions.includes('clients') && (
          <Link className="home-tile" to={'/clients'}>
            Clients
          </Link>
        )}

        <Link className="home-tile" to={'/orders'}>
          Client follow up
        </Link>

        {permissions.includes('new_orders') && (
          <Link className="home-tile" to={'/orders/create'}>
            Create New Order
          </Link>
        )}

        {permissions.includes('products') && (
          <Link className="home-tile" to={'/products'}>
            Products
          </Link>
        )}

        {permissions.includes('metrics') && (
          <Link className="home-tile" to={'/metrics'}>
            Metrics
          </Link>
        )}

        {permissions.includes('new_adjustments') && (
          <Link className="home-tile" to={'/adjustments/create'}>
            New adjustment
          </Link>
        )}
        {permissions.includes('companies_orders') && (
          <Link className="home-tile" to={'/companies/orders'}>
            Companies Orders
          </Link>
        )}
      </div>
    </>
  );
};

export default Home;
