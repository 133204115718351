import { INSTANCE, INSTANCELIST } from './API';

export function getMPLandingSections({ queryKey }) {
  return new Promise((resolve, reject) => {
    const { sort, range, filter } = queryKey[1];

    INSTANCELIST({
      method: 'get',
      url: '/mp',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
      },
    })
      .then(resolve)
      .catch(reject);
  });
}

export function getMPLandingSection({ queryKey }) {
  return new Promise((resolve, reject) => {
    const [, id] = queryKey;
    INSTANCE({
      method: 'get',
      url: `/mp/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });
}

export const createMPLandingSection = (form) =>
  new Promise((resolve, reject) => {
    INSTANCELIST({
      method: 'post',
      url: '/mp',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export function updateMPLandingSection(form, id) {
  return new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/mp/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });
}

export function deleteMPLandingSection({ queryKey }) {
  return new Promise((resolve, reject) => {
    const [, id] = queryKey;
    INSTANCE({
      method: 'delete',
      url: `/mp/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });
}
