import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  DownloadOutlined,
  FileTextOutlined,
  MailOutlined,
  SyncOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import FormWrap from '../../components/forms/FormWrap';
import FormBlock from '../../components/forms/FormBlock';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import { useFormik } from 'formik';
import {
  createOrderUser,
  getCompanies,
  getCompanyOrderDetails,
  getCompanyTheme,
  getOrderExcel,
  getUniforms,
  sendBatchEmail,
  sendOneEmail,
  sync3dLook,
  updateCompanyOrder,
  uploadClientList,
} from '../../network/B2B';
import { get } from 'lodash.get';
// import JSZip from 'jszip'

const { Text } = Typography;
const { Option } = Select;

export const isEmail = (e_mail) => {
  if (typeof e_mail !== 'string') {
    return false;
  }

  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/;

  return emailRegex.test(e_mail);
};

const EditOrder = ({ history }) => {
  let { orderId } = useParams();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    validateForm,
  } = useFormik({
    initialValues: { customer: {} },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    enableReinitialize: true,
    validate: (values) => {
      let errors = {};

      if (!values.customer.lastName) {
        errors.lastName = 'Required';
      }

      if (!values.customer.firstName) {
        errors.firstName = 'Required';
      }

      if (!values.customer.chineseName) {
        errors.chineseName = 'Required';
      }

      if (!values.customer.gender) {
        errors.gender = 'Required';
      }

      if (!values.customer.phone) {
        errors.phone = 'Required';
      }

      if (!values.customer.email) {
        errors.email = 'Required';
      } else if (!isEmail(values.customer.email)) {
        errors.email = 'Not valid';
      }

      if (!values.customer.shoeSize) {
        errors.shoeSize = 'Required';
      }

      if (!values.theme) {
        errors.theme = 'Required';
      }

      if (!values.position) {
        errors.position = 'Required';
      }

      return errors;
    },
    onSubmit: async (values) => {
      createUser.mutate(values);
    },
  });

  const orderDetails = useQuery(
    [
      'getCompanyOrderDetails',
      {
        id: orderId,
      },
    ],
    getCompanyOrderDetails,
    {
      // onSuccess: (data) => {
      //   form.setFieldsValue(data)
      // },
      refetchOnWindowFocus: false,
      select: (data) => {
        data.customers = data.customers.map((cus, idx) => ({
          nb: idx + 1,
          ...cus,
        }));

        return data;
      },
    }
  );

  const companyTheme = useQuery(
    [
      'getthemeSelect',
      {
        filter: { company: form.getFieldValue(['company']) },
      },
    ],
    getCompanyTheme,
    {
      enabled: !!form.getFieldValue('company'),
      refetchOnWindowFocus: false,
    }
  );

  const createUser = useMutation((vals) => createOrderUser(orderId, vals), {
    onSuccess: (data) => {
      setValues({ customer: {} });
      queryClient.setQueryData(
        [
          'getCompanyOrderDetails',
          {
            id: orderId,
          },
        ],
        (oldData) => {
          return { ...oldData, customers: data.customers };
        }
      );
    },
    onError: (error) => {
      message.error(
        error.response?.data?.debugLog?.message ||
          error.response?.data?.message ||
          'An error occurs'
      );
    },
  });

  const columns = [
    {
      title: '',
      dataIndex: 'nb',
    },
    {
      title: 'First name',
      dataIndex: 'customer',
      render: (customer) => customer?.firstName,
    },
    {
      title: 'Last name',
      dataIndex: 'customer',
      render: (customer) => customer?.lastName,
    },
    {
      title: 'Chinese name',
      dataIndex: 'customer',
      render: (customer) => customer?.chineseName,
    },
    {
      title: 'Gender',
      dataIndex: 'customer',
      render: (customer) => customer?.gender,
    },
    {
      title: 'Shoe size',
      dataIndex: 'customer',
      render: (customer) => customer?.shoeSize,
    },
    {
      title: 'Theme collection',
      dataIndex: 'theme',
      render: (theme) => theme.name,
    },
    {
      title: 'Role',
      dataIndex: 'position',
    },
    {
      title: 'Uniform',
      render: (e) => {
        const idx = orderDetails?.data?.uniforms?.findIndex(
          (uni) => uni.role === e.position && uni.theme === e.theme.id
        );

        if (idx === -1) {
          return '';
        }

        return `Option ${idx + 1}`;
      },
    },
    {
      title: 'Email',
      dataIndex: 'customer',
      render: (customer) => customer?.email,
    },
    {
      title: 'Phone',
      dataIndex: 'customer',
      render: (customer) => customer?.phone,
    },
    {
      title: 'Scanned status',
      width: 240,
      render: (e) => {
        let color = undefined;
        let text = 'Pending';

        switch (e.scanStatus) {
          case 'success':
            color = 'green';
            text = 'Success';
            break;
          case 'error':
            color = 'red';
            text = 'Error';
            break;
          case 'warning':
            color = 'orange';
            text = 'Warning';
            break;

          default:
            break;
        }

        return (
          <Space>
            <Tag style={{ margin: 0 }} color={color}>
              {text}
            </Tag>
            <a href={`/companies/customers/${e.customer.id}?prev=${orderId}`}>
              <Button type="primary" size="small">
                Edit
              </Button>
            </a>
            <UniqueEmailBtn orderId={orderId} userId={e.customer.id} />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Edit order'}
        onBack={() => history.push('/companies/orders')}
        style={{ backgroundColor: '#fff' }}
      />
      {orderDetails.isError ? (
        <Error retry={() => orderDetails.refetch()} />
      ) : orderDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={orderId}
          form={form}
          title="update_member"
          request={updateCompanyOrder}
          cancelText={'Cancel'}
          okText={'Save'}
          data={orderDetails.data}
          onCancel={() => history.push('/companies/orders')}
          onSuccess={() => history.push('/companies/orders')}
          // cleanBeforeSending={cleanBeforeSendingArea}
          invalidate={['getCompaniesOrders', 'getCompanyOrderDetails']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={OrderDescriptionForm} />
            <FormBlock form={form} formTemplate={UniformsForm} />
            <Card
              title="Customers"
              extra={
                <Space>
                  <Sync3DLookBtn orderId={orderId} />
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      getOrderExcel(orderId).then((file) => {
                        const url = window.URL.createObjectURL(
                          new Blob([file])
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                          'download',
                          `ORDER-${orderDetails?.data?.orderID || ''}.zip`
                        );
                        document.body.appendChild(link);
                        link.click();
                      });
                    }}
                  >
                    All excels
                  </Button>
                  <Button
                    icon={<FileTextOutlined />}
                    onClick={() => {
                      var url = `${process.env.REACT_APP_API_URL}/assets/template.csv`;
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', 'template.csv');
                      document.body.appendChild(link);
                      link.click();
                      window.URL.revokeObjectURL(url);
                      link.remove();
                    }}
                  >
                    CSV Template
                  </Button>
                  <BatchEmailBtn orderId={orderId} />
                  <UploadBtn orderId={orderId} />
                </Space>
              }
            >
              {/* {
                orderDetails?.data?.customers?.map((customer) => )
              } */}
              <div
                style={{
                  gap: 8,
                  display: 'flex',
                  marginBottom: 16,
                  alignItems: 'end',
                  flexWrap: 'wrap',
                }}
              >
                <Space
                  direction="vertical"
                  style={{ flex: 1, width: '100%', minWidth: 100 }}
                >
                  <Space size={3}>
                    <Text strong>Last name</Text>
                    {!!errors.lastName && (
                      <Text type="danger">{errors.lastName}</Text>
                    )}
                  </Space>
                  <Input
                    name="customer.lastName"
                    value={values.customer.lastName}
                    onChange={handleChange}
                    status="warning"
                    disabled={createUser.isLoading}
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{ flex: 1, width: '100%', minWidth: 100 }}
                >
                  <Space size={3}>
                    <Text strong>First name</Text>
                    {!!errors.lastName && (
                      <Text type="danger">{errors.firstName}</Text>
                    )}
                  </Space>
                  <Input
                    name="customer.firstName"
                    value={values.customer.firstName}
                    onChange={handleChange}
                    disabled={createUser.isLoading}
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{ flex: 1, width: '100%', minWidth: 100 }}
                >
                  <Space size={3}>
                    <Text strong>Chinese name</Text>
                    {!!errors.chineseName && (
                      <Text type="danger">{errors.chineseName}</Text>
                    )}
                  </Space>
                  <Input
                    name="customer.chineseName"
                    value={values.customer.chineseName}
                    onChange={handleChange}
                    disabled={createUser.isLoading}
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{ flex: 1, width: '100%', minWidth: 100 }}
                >
                  <Space size={3}>
                    <Text strong>Gender</Text>
                    {!!errors.gender && (
                      <Text type="danger">{errors.gender}</Text>
                    )}
                  </Space>
                  <Select
                    name="customer.gender"
                    style={{ width: '100%' }}
                    value={values.customer.gender}
                    onChange={(e) =>
                      handleChange({
                        target: { name: 'customer.gender', value: e },
                      })
                    }
                    disabled={createUser.isLoading}
                  >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                  </Select>
                </Space>
                <Space
                  direction="vertical"
                  style={{ flex: 1, width: '100%', minWidth: 100 }}
                >
                  <Space size={3}>
                    <Text strong>Phone</Text>
                    {!!errors.phone && (
                      <Text type="danger">{errors.phone}</Text>
                    )}
                  </Space>
                  <Input
                    name="customer.phone"
                    value={values.customer.phone}
                    onChange={handleChange}
                    disabled={createUser.isLoading}
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{ flex: 1, width: '100%', minWidth: 100 }}
                >
                  <Space size={3}>
                    <Text strong>Email</Text>
                    {!!errors.email && (
                      <Text type="danger">{errors.email}</Text>
                    )}
                  </Space>
                  <Input
                    name="customer.email"
                    value={values.customer.email}
                    onChange={handleChange}
                    disabled={createUser.isLoading}
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{ flex: 1, width: '100%', minWidth: 100 }}
                >
                  <Space size={3}>
                    <Text strong>Shoe size</Text>
                    {!!errors.shoeSize && (
                      <Text type="danger">{errors.shoeSize}</Text>
                    )}
                  </Space>
                  <InputNumber
                    style={{ width: '100%' }}
                    name="customer.shoeSize"
                    value={values.customer.shoeSize}
                    onChange={(e) =>
                      handleChange({
                        target: { name: 'customer.shoeSize', value: e },
                      })
                    }
                    disabled={createUser.isLoading}
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{ flex: 1, width: '100%', minWidth: 100 }}
                >
                  <Space size={3}>
                    <Text strong>Theme collection</Text>
                    {!!errors.theme && (
                      <Text type="danger">{errors.theme}</Text>
                    )}
                  </Space>
                  <Select
                    name="customer.theme"
                    style={{ width: '100%' }}
                    value={values.theme}
                    onChange={(e) =>
                      handleChange({
                        target: { name: 'theme', value: e },
                      })
                    }
                    disabled={createUser.isLoading}
                    loading={companyTheme.isLoading}
                  >
                    {companyTheme?.data?.list?.map((theme) => (
                      <Option key={theme.id} value={theme.id}>
                        {theme.name}
                      </Option>
                    ))}
                  </Select>
                </Space>
                <Space
                  direction="vertical"
                  style={{ flex: 1, width: '100%', minWidth: 100 }}
                >
                  <Text strong>Position</Text>
                  <Select
                    name="position"
                    style={{ width: '100%' }}
                    value={values.position}
                    onChange={(e) =>
                      handleChange({ target: { name: 'position', value: e } })
                    }
                    disabled={createUser.isLoading || !values.theme}
                  >
                    {companyTheme?.data?.list
                      ?.find((e) => e.id === values.theme)
                      ?.roles?.map((role) => (
                        <Option key={role} value={role}>
                          {role}
                        </Option>
                      ))}
                  </Select>
                </Space>
                <div style={{ height: '100%', minWidth: 100 }}>
                  <Button
                    onClick={() => {
                      validateForm();
                      handleSubmit();
                    }}
                    type="primary"
                    // disabled={!!Object.keys(errors).length}
                    loading={createUser.isLoading}
                  >
                    Add customer
                  </Button>
                </div>
              </div>
              <Table
                size="small"
                columns={columns}
                dataSource={orderDetails?.data?.customers}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: orderDetails?.data?.customers?.length,
                }}
              />
            </Card>
          </div>
        </FormWrap>
      )}
    </>
  );
};

export default EditOrder;

const BatchEmailBtn = ({ orderId }) => {
  const mutation = useMutation(() => sendBatchEmail(orderId), {
    onSuccess: () => {
      message.success('Emails sent');
    },
    onError: () => message.error('An error occurs sending emails'),
  });

  return (
    <Button
      // type="primary"
      icon={<MailOutlined />}
      onClick={mutation.mutate}
      loading={mutation.isLoading}
    >
      Send emails
    </Button>
  );
};

const UniqueEmailBtn = ({ orderId, userId }) => {
  const mutation = useMutation(() => sendOneEmail(orderId, userId), {
    onSuccess: () => {
      message.success('Email sent');
    },
    onError: () => message.error('An error occurs sending email'),
  });

  return (
    <Button
      // type="primary"
      size="small"
      icon={<MailOutlined />}
      onClick={mutation.mutate}
      loading={mutation.isLoading}
    >
      Send email
    </Button>
  );
};

const Sync3DLookBtn = ({ orderId }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(() => sync3dLook(orderId), {
    onSuccess: () => {
      message.success('Synchronisation done');
      queryClient.invalidateQueries('getCompanyOrderDetails');
    },
    onError: () =>
      message.error('An error occurs synchronisating with 3D Look'),
  });

  return (
    <Button
      // type="primary"
      icon={<SyncOutlined />}
      onClick={mutation.mutate}
      loading={mutation.isLoading}
    >
      3D Look sync
    </Button>
  );
};

const UploadBtn = ({ orderId }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((file) => uploadClientList(orderId, file), {
    onSuccess: () => {
      queryClient.invalidateQueries('getCompanyOrderDetails');
    },
    onError: () => message.error('An error occurs'),
  });

  return (
    <Button
      type="primary"
      icon={<UploadOutlined />}
      style={{ position: 'relative' }}
      disabled={mutation.isLoading}
    >
      Upload CSV
      <input
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          fontSize: 0,
          backgroundColor: 'transparent',
          border: 'none',
          opacity: 0,
          zIndex: 1,
        }}
        type="file"
        name="file"
        accept=".csv"
        onChange={(event) => {
          const file = event.target.files[0];
          if (!file) return;

          mutation.mutate(file);
        }}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
    </Button>
  );
};

export const OrderDescriptionForm = {
  title: 'Description',
  key: [],
  sections: [
    {
      columns: 4,
      fields: [
        {
          label: 'Company',
          type: 'select',
          isRequired: true,
          key: ['company'],
          requestOption: getCompanies,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const UniformsForm = {
  title: 'Uniforms',
  sections: [
    {
      canAdd: true,
      key: ['uniforms'],
      addSuffix: 'number',
      columns: 4,
      // noDivider: true,
      title: 'Option',
      deleteBtn: { label: 'Delete', style: 'line' },
      addBtn: { label: 'Add uniform', style: 'primary' },
      fields: [
        {
          label: 'Uniform',
          type: 'select',
          isRequired: true,
          key: ['uniform'],
          requestOption: getUniforms,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Theme collection',
          type: 'select',
          isRequired: true,
          key: ['theme'],
          conditionalFilter: (form) => {
            let company = form.getFieldValue(['company']);
            return { company: company ? company : null };
          },
          requestOption: getCompanyTheme,
          onChange: (name, form) => {
            let uniforms = form.getFieldValue(['uniforms']);
            uniforms[name].role = undefined;
            form.setFieldsValue({ uniforms });
          },
          pos: {
            col: 2,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Position',
          type: 'select',
          isRequired: true,
          key: ['role'],
          keySearch: 'getthemeSelect',
          conditionalFilter: (form) => {
            let company = form.getFieldValue(['company']);
            return { company: company ? company : null };
          },
          requestOption: getCompanyTheme,
          optionParser: (options, fieldKey, form) => {
            let theme = form.getFieldValue(['uniforms', fieldKey, 'theme']);
            if (!theme) return [];
            const matching = options?.find((e) => e.id === theme);

            return matching?.roles?.map((e) => ({
              id: e,
              name: e,
            }));
          },
          pos: {
            col: 3,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};
