import get from 'lodash.get';

export const AdjustmentForm = (options, _onChangeRef, _onChange) => ({
  title: 'Details adjustment',
  display: 'Details adjustment',
  key: ['orderAdjustment'],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Fitting number',
          type: 'select',
          key: ['fittingNumber'],
          options: [
            {
              key: '1',
              label: '1',
            },
            {
              key: '2',
              label: '2',
            },
            {
              key: '3',
              label: '3',
            },
            {
              key: '4',
              label: '4',
            },
          ],
          isRequired: true,
        },
        {
          label: 'Item descritpion',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['itemDescritpion'],
          options: [
            { key: 'af', label: 'AF' },
            { key: 'other', label: 'Other' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Order number',
          isRequired: true,
          placeholder: '-',
          key: ['orderNumber'],
          isHiding: (values, name) =>
            get(values, `${name}itemDescritpion`, '') !== 'af',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Colors',
          isRequired: true,
          placeholder: '-',
          key: ['colors'],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Pictures',
          isRequired: true,
          placeholder: '-',
          type: 'multiupload',
          key: ['pictures'],
          requestURI: '/files/order-adjustment',
          storageURI: '/storage/order-adjustment',
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
    {
      columns: 2,
      canAdd: true,
      display: 'Position',
      key: ['adjustmentPositions'],
      fields: [
        {
          label: 'Details ref',
          type: 'select',
          key: ['positionRef'],
          isRequired: true,
          options,
        },
        {
          label: 'Adjustment',
          isRequired: true,
          key: ['adjustment'],
          type: 'basicRadio',
          options: [
            { key: 'smaller', label: 'Make smaller' },
            { key: 'bigger', label: 'Make bigger' },
            { key: 'fix', label: 'Fix' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'centimeter',
          isRequired: false,
          placeholder: '-',
          key: ['cm'],
          isHiding: (values, name) =>
            get(values, `${name}adjustment`, '') === 'fix',
          type: 'number',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Final measurement',
          isRequired: false,
          placeholder: '-',
          key: ['finalMeasurment'],
          isHiding: (values, name) =>
            get(values, `${name}adjustment`, '') === 'fix',
          type: 'number',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Picture',
          type: 'multiupload',
          isRequired: false,
          key: ['pictures'],
          requestURI: '/files/order-adjustment',
          storageURI: '/storage/order-adjustment',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Comment',
          isRequired: false,
          key: ['comment'],
          type: 'text',
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
});

const ShoesForm = {
  title: 'Details adjustment',
  display: 'Details adjustment',
  key: ['orderAdjustment'],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Fitting number',
          type: 'select',
          key: ['fittingNumber'],
          options: [
            {
              key: '1',
              label: '1',
            },
            {
              key: '2',
              label: '2',
            },
            {
              key: '3',
              label: '3',
            },
            {
              key: '4',
              label: '4',
            },
          ],
          isRequired: true,
        },
        {
          label: 'Item descritpion',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['itemDescritpion'],
          options: [
            { key: 'af', label: 'AF' },
            { key: 'other', label: 'Other' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Order number',
          isRequired: true,
          placeholder: '-',
          key: ['orderNumber'],
          isHiding: (values, name) =>
            get(values, `${name}itemDescritpion`, '') === 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Colors',
          isRequired: true,
          placeholder: '-',
          key: ['colors'],
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
    {
      columns: 2,
      canAdd: true,
      default: {},
      display: 'Position',
      key: ['adjustmentPositions'],
      fields: [
        {
          label: 'Details ref',
          type: 'select',
          key: ['positionRef'],
          isRequired: true,
          options: [
            { key: 'opening', label: 'Opening' },
            { key: 'length', label: 'length' },
            { key: 'width', label: 'width' },
          ],
        },
        {
          label: 'Choose your adjustment',
          key: ['adjustmentType'],
          type: 'basicRadio',
          options: [
            { key: 'size', label: 'Size' },
            { key: 'color', label: 'Color' },
            { key: 'fix', label: 'Fix' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Color',
          key: ['color'],
          type: 'basicRadio',
          isHiding: (values, name) =>
            get(values, `${name}adjustmentType`, '') !== 'color',
          options: [
            { key: 'darker', label: 'Make darker' },
            { key: 'lighter', label: 'Make lighter' },
            { key: 'shine', label: 'Just shine' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Size',
          key: ['adjustment'],
          type: 'basicRadio',
          isHiding: (values, name) =>
            get(values, `${name}adjustmentType`, '') !== 'size',
          options: [
            { key: 'smaller', label: 'Make smaller' },
            { key: 'bigger', label: 'Make bigger' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Fix',
          key: ['fix'],
          type: 'basicRadio',
          isHiding: (values, name) =>
            get(values, `${name}adjustmentType`, '') !== 'fix',
          options: [
            { key: 'sole', label: 'Replace sole' },
            { key: 'lace', label: 'Replace lace' },
          ],
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Pictures',
          isRequired: true,
          placeholder: '-',
          type: 'multiupload',
          key: ['pictures'],
          requestURI: '/files/order-adjustment',
          storageURI: '/storage/order-adjustment',
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
};

export const adjustmentInfos = {
  vest: {
    name: 'Vest',
    forms: [
      {
        form: [
          AdjustmentForm([
            { key: 'waist', label: 'Waist vest' },
            { key: 'length_front', label: 'Length front' },
            { key: 'length_back', label: 'Length back' },
            { key: 'arm_hole', label: 'Arm hole' },
            { key: 'button', label: 'Button' },
            { key: 'back_belt', label: 'Back belt' },
          ]),
        ],
        key: 'orderAdjustment',
      },
    ],
  },
  pant: {
    name: 'Pants',
    forms: [
      {
        form: [
          AdjustmentForm([
            { key: 'waist', label: 'waist pant' },
            { key: 'whole_crotch', label: 'whole crotch' },
            { key: 'pant_length', label: 'pant length' },
            { key: 'cuff_pant', label: 'cuff pant' },
            { key: 'leg_widness', label: 'leg widness' },
            { key: 'pant_opening', label: 'pant opening' },
            { key: 'change_zipper', label: 'change zipper' },
            { key: 'button', label: 'button' },
          ]),
        ],
        key: 'orderAdjustment',
      },
    ],
  },
  skirt: {
    name: 'Skirt',
    forms: [
      {
        form: [
          AdjustmentForm([
            { key: 'waist', label: 'Waist skirt' },
            { key: 'length_skirt', label: 'Length skirt' },
            { key: 'widness_skirt', label: 'Widness skirt' },
            { key: 'change_zipper', label: 'Change zipper' },
            { key: 'button', label: 'Button' },
          ]),
        ],
        key: 'orderAdjustment',
      },
    ],
  },
  jacket_blazer: {
    name: 'Jacket / Blazer',
    forms: [
      {
        form: [
          AdjustmentForm([
            { key: 'waist', label: 'Waist jacket' },
            { key: 'sleeve_widness', label: 'Sleeve widness' },
            { key: 'sleeve_length', label: 'Sleeve length' },
            { key: 'shoulder', label: 'Shoulder' },
            { key: 'button', label: 'Button' },
            { key: 'length_front', label: 'Length front' },
            { key: 'length_back', label: 'Length back' },
            { key: 'arm_hole', label: 'Arm hole' },
            { key: 'back_wrinkle', label: 'Back wrinkle' },
          ]),
        ],
        key: 'orderAdjustment',
      },
    ],
  },
  shirt: {
    name: 'Shirt',
    forms: [
      {
        form: [
          AdjustmentForm([
            { key: 'waist', label: 'Waist shirt' },
            { key: 'sleeve_widness', label: 'Sleeve widness' },
            { key: 'sleeve_length', label: 'Sleeve length' },
            { key: 'shoulder', label: 'Shoulder' },
            { key: 'button', label: 'Button' },
            { key: 'length_front', label: 'Length front' },
            { key: 'length_back', label: 'Length back' },
            { key: 'arm_hole', label: 'Arm hole' },
          ]),
        ],
        key: 'orderAdjustment',
      },
    ],
  },
  jean: {
    name: 'Jeans',
    forms: [
      {
        form: [
          AdjustmentForm([
            { key: 'waist', label: 'waist pant' },
            { key: 'whole_crotch', label: 'whole crotch' },
            { key: 'pant_length', label: 'pant length' },
            { key: 'cuff_pant', label: 'cuff pant' },
            { key: 'leg_widness', label: 'leg widness' },
            { key: 'pant_opening', label: 'pant opening' },
            { key: 'change_zipper', label: 'change zipper' },
            { key: 'button', label: 'button' },
          ]),
        ],
        key: 'orderAdjustment',
      },
    ],
  },
  shoes: {
    name: 'Shoes',
    forms: [{ form: [ShoesForm], key: 'orderAdjustment' }],
  },
  coat: {
    name: 'Coat',
    forms: [
      {
        form: [
          AdjustmentForm([
            { key: 'waist', label: 'Waist coat' },
            { key: 'sleeve_widness', label: 'Sleeve widness' },
            { key: 'sleeve_length', label: 'Sleeve length' },
            { key: 'shoulder', label: 'Shoulder' },
            { key: 'button', label: 'Button' },
            { key: 'length_front', label: 'Length front' },
            { key: 'length_back', label: 'Length back' },
            { key: 'arm_hole', label: 'Arm hole' },
            { key: 'back_wrinkle', label: 'Back wrinkle' },
          ]),
        ],
        key: 'orderAdjustment',
      },
    ],
  },
};

export const calcPrice = (type, adjType, shoesCalculate) => {
  switch (type) {
    case 'vest': {
      switch (adjType) {
        case 'waist':
          return 150;

        case 'length_front':
          return 100;

        case 'length_back':
          return 100;

        case 'arm_hole':
          return 100;

        case 'button':
          return 50;

        case 'back_belt':
          return 50;

        default:
          return 0;
      }
    }
    case 'pant': {
      switch (adjType) {
        case 'waist':
          return 150;

        case 'whole_crotch':
          return 100;

        case 'pant_length':
          return 100;

        case 'cuff_pant':
          return 100;

        case 'leg_widness':
          return 150;

        case 'pant_opening':
          return 100;

        case 'change_zipper':
          return 150;

        case 'button':
          return 50;

        default:
          return 0;
      }
    }
    case 'skirt': {
      switch (adjType) {
        case 'waist':
          return 150;

        case 'length_skirt':
          return 100;

        case 'widness_skirt':
          return 150;

        case 'change_zipper':
          return 150;

        case 'button':
          return 50;

        default:
          return 0;
      }
    }
    case 'jacket_blazer': {
      switch (adjType) {
        case 'waist':
          return 200;

        case 'sleeve_widness':
          return 100;

        case 'sleeve_length':
          return 150;

        case 'shoulder':
          return 450;

        case 'button':
          return 50;

        case 'length_front':
          return 150;

        case 'length_back':
          return 150;

        case 'arm_hole':
          return 450;

        case 'back_wrinkle':
          return 300;

        default:
          return 0;
      }
    }
    case 'shirt': {
      switch (adjType) {
        case 'waist':
          return 150;

        case 'sleeve_widness':
          return 150;

        case 'sleeve_length':
          return 150;

        case 'shoulder':
          return 150;

        case 'button':
          return 50;

        case 'length_front':
          return 150;

        case 'length_back':
          return 150;

        case 'arm_hole':
          return 150;

        default:
          return 0;
      }
    }
    case 'jean': {
      switch (adjType) {
        case 'waist':
          return 150;

        case 'whole_crotch':
          return 100;

        case 'pant_length':
          return 100;

        case 'cuff_pant':
          return 100;

        case 'leg_widness':
          return 150;

        case 'pant_opening':
          return 100;

        case 'change_zipper':
          return 150;

        case 'button':
          return 50;

        default:
          return 0;
      }
    }
    case 'shoes': {
      let price = 0;
      switch (adjType) {
        case 'opening':
          price += 0;
          break;

        case 'length':
          price += 0;
          break;

        case 'width':
          price += 0;
          break;

        case 'replace_sole':
          price += 200;
          break;

        case 'replace_lace':
          price += 100;
          break;

        default:
          price += 0;
      }
      switch (shoesCalculate?.adjustmentType) {
        case 'color':
          switch (shoesCalculate?.color) {
            case 'darker':
              price += 200;
              break;

            case 'lighter':
              price += 200;
              break;

            case 'shine':
              price += 200;
              break;

            default:
              price += 0;
              break;
          }
          break;

        case 'size':
          switch (shoesCalculate?.size) {
            case 'smaller':
              price += 0;
              break;

            case 'bigger':
              price += 0;
              break;

            default:
              price += 0;
              break;
          }
          break;

        case 'fix':
          switch (shoesCalculate?.fix) {
            case 'sole':
              price += 200;
              break;

            case 'lace':
              price += 100;
              break;

            default:
              price += 0;
              break;
          }
          break;

        default:
          price += 0;
          break;
      }
      return price;
    }
    case 'coat': {
      switch (adjType) {
        case 'waist':
          return 200;

        case 'sleeve_widness':
          return 100;

        case 'sleeve_length':
          return 150;

        case 'shoulder':
          return 450;

        case 'button':
          return 50;

        case 'length_front':
          return 150;

        case 'length_back':
          return 150;

        case 'arm_hole':
          return 450;

        case 'back_wrinkle':
          return 300;

        default:
          return 0;
      }
    }

    default:
      return 0;
  }
};
