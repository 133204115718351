import {
  Card,
  DatePicker,
  Form,
  PageHeader,
  Select,
  Space,
  Statistic,
  Table,
  Typography,
} from 'antd';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import ChartPie from '../components/ChartPie';
import { getMetrics } from '../network/API';

const { Title } = Typography;
const { Option } = Select;

const Metrics = () => {
  const [filterType, setFilterType] = useState('month');
  const [filterDate, setFilterDate] = useState();
  const metrics = useQuery(
    [
      'getMetrics',
      {
        type: !filterDate ? null : filterType === 'month' ? 'm' : 'y',
        date: filterDate?.toDate(),
      },
    ],
    getMetrics,
    {
      refetchOnWindowFocus: false,
    }
  );

  const skuSalesValues = useMemo(() => {
    let values = [{}];
    let columns = [];

    if (metrics.data?.amountByCategories) {
      for (const sku of metrics.data.amountByCategories) {
        if (sku.type === 'collection') {
          values[0][sku.id] =
            Math.round((sku.amount + Number.EPSILON) * 100) / 100;
          columns.push({
            title: sku.name?.en || sku.name?.zh,
            dataIndex: sku.id,
          });
        }
      }
    }
    return { values, columns };
  }, [metrics.data?.amountByCategories]);

  const totalRevenueValues = useMemo(() => {
    let values = [{}];
    let columns = [];

    if (metrics.data?.incomeByStores) {
      for (const shop of metrics.data.incomeByStores) {
        values[0][shop._id?.storeID] =
          Math.round(
            (shop.amount - shop.amountDiscount + Number.EPSILON) * 100
          ) / 100;
        columns.push({
          title: shop._id?.name?.en || shop._id?.name?.zh,
          dataIndex: shop._id?.storeID,
        });
      }
    }
    return { values, columns };
  }, [metrics.data?.incomeByStores]);

  const personaeAverageValues = useMemo(() => {
    let values = [
      {
        total: metrics.data?.averageIncome,
      },
    ];
    let columns = [
      {
        title: 'Total',
        dataIndex: 'total',
      },
    ];
    const statuses = {
      special_event: 'Special event (Persona 1)',
      wardrobe_update: 'Wardrobe update (Persona 2)',
    };

    if (metrics.data?.averageIncomeByPersonae) {
      for (const personae of metrics.data.averageIncomeByPersonae) {
        if (personae._id !== null) {
          // values[0].total += parseFloat(personae.amount)
          values[0][personae._id] = personae.amount.toFixed(2);
          columns.push({
            title: statuses[personae._id],
            dataIndex: personae._id,
          });
        }
      }
    }
    return { values, columns };
  }, [metrics.data?.averageIncomeByPersonae]);

  const orderAmountValues = useMemo(() => {
    let values = [{}];
    let columns = [];

    if (metrics.data?.orderAmountByMonths) {
      for (const month of metrics.data.orderAmountByMonths /*.sort(
        (a, b) => a.month - b.month
      )*/) {
        values[0][month.month.toString()] = month.amount.length
          ? Math.round(
              (month.amount[0].amount -
                month.amount[0].amountDiscount +
                Number.EPSILON) *
                100
            ) / 100
          : 0;
        let ddd = moment(`${month.month}/01/${month.year}`);
        columns.push({
          title: ddd.format('MMM YYYY'),
          dataIndex: month.month.toString(),
        });
      }
    }
    return { values, columns };
  }, [metrics.data?.orderAmountByMonths]);

  const reorderNewClientsValues = useMemo(() => {
    let values = [];

    if (metrics.data?.firstOrders) {
      for (const reorders of metrics.data.firstOrders) {
        values.push({
          _id: reorders._id ? 'New client' : 'Reorder',
          value: Math.round((reorders.amount + Number.EPSILON) * 100) / 100,
        });
      }
    }
    return values;
  }, [metrics.data?.firstOrders]);

  const howKnowValues = useMemo(() => {
    let values = [];
    const statuses = {
      friend_recommendation: 'From A Friend',
      online: 'Online',
      wechat: 'Wechat',
      ad: 'Ad',
    };

    if (metrics.data?.incomeByAcquaintedChannel) {
      for (const knows of metrics.data.incomeByAcquaintedChannel) {
        values.push({
          _id: statuses[knows._id],
          value: Math.round((knows.amount + Number.EPSILON) * 100) / 100,
        });
      }
    }
    return values;
  }, [metrics.data?.incomeByAcquaintedChannel]);

  const perCategoryValues = useMemo(() => {
    let values = [];
    const statuses = {
      special_event: 'Special event',
      wardrobe_update: 'Wardrobe update',
      others: 'Others',
    };

    if (metrics.data?.incomeByPersonae) {
      for (const personae of metrics.data.incomeByPersonae) {
        values.push({
          _id: statuses[personae._id || 'others'],
          value:
            Math.round(
              (personae.amount - personae.amountDiscount + Number.EPSILON) * 100
            ) / 100,
        });
      }
    }

    return values;
  }, [metrics.data?.incomeByPersonae]);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Metrics"
        style={{ backgroundColor: '#fff' }}
      />
      <Form layout={'vertical'}>
        <Space className="metric-filters">
          <Form.Item label="Filter by">
            <Select
              value={filterType}
              onChange={(value) => setFilterType(value)}
            >
              <Option value="month">Month</Option>
              <Option value="year">Year</Option>
            </Select>
          </Form.Item>
          {filterType === 'month' && (
            <Form.Item label="Select a month">
              <DatePicker
                value={filterDate}
                // monthCellRender={(date) => date.format('MMMM')}
                format="MMMM"
                disabledDate={(date) => date > moment()}
                onChange={(value) => setFilterDate(value)}
                picker="month"
              />
            </Form.Item>
          )}
          <Form.Item label="Select a year">
            <DatePicker
              value={filterDate}
              disabledDate={(date) => date > moment()}
              onChange={(value) => setFilterDate(value)}
              picker="year"
            />
          </Form.Item>
        </Space>
      </Form>
      <Space direction="vertical" className="metrics-wrapper" size={15}>
        <Title level={4}>Sku sales (volume)</Title>
        <Table
          dataSource={skuSalesValues.values}
          columns={skuSalesValues.columns}
          pagination={{ hideOnSinglePage: true }}
        />
        <Title level={4}>Total revenue (store)</Title>
        <Table
          dataSource={totalRevenueValues.values}
          columns={totalRevenueValues.columns}
          pagination={{ hideOnSinglePage: true }}
        />
        <Title level={4}>Orders</Title>
        <Card className="stat-card">
          <Statistic
            title="Total revenue"
            value={metrics.data?.totalIncome}
            precision={0}
            valueStyle={{ color: '#1890FF' }}
            prefix="¥"
          />
        </Card>
        <Title level={4}>Order amount (last 12 months)</Title>
        <Table
          dataSource={orderAmountValues.values}
          columns={orderAmountValues.columns}
          pagination={{ hideOnSinglePage: true }}
        />
        <Title level={4}>Average cart</Title>
        <Table
          dataSource={personaeAverageValues.values}
          columns={personaeAverageValues.columns}
          pagination={{ hideOnSinglePage: true }}
        />
        <ChartPie
          data={perCategoryValues}
          title="Order amount per categories"
          noPrefix
          noAmount
        />
        <Title level={4}>Client knowledge</Title>
        <Space>
          <ChartPie
            data={howKnowValues}
            title="How do clients know Atelier Fusari"
            noPrefix
            noAmount
          />
          <ChartPie
            data={reorderNewClientsValues}
            title="Reorder vs new clients"
            noPrefix
            noAmount
          />
        </Space>
      </Space>
    </>
  );
};

export default Metrics;
