export const ROLES = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super-admin',
  TAILOR: 'tailor',
  STYLIST: 'stylist',
  STORE_ASSISTANT: 'store_assistant',
  MARKETING: 'marketing',
  ADJUSTMENT_STAFF: 'adjustment_staff',
  PRODUCTION_DEPARTMENT: 'production_department',
};

export const ROLE_PERMISSIONS = {
  admin: [
    'new_orders',
    'new_adjustments',
    'clients',
    'new_clients',
    'products',
    'types',
    'stores',
    'members',
    'metrics',
    'companies',
    'uniforms',
    'companies_orders',
    'genres',
    'mp',
    'product-tags',
    'categories',
    'types',
  ],
  'super-admin': [
    'new_orders',
    'new_adjustments',
    'clients',
    'new_clients',
    'products',
    'types',
    'stores',
    'members',
    'metrics',
    'companies',
    'uniforms',
    'companies_orders',
    'genres',
    'mp',
    'product-tags',
    'categories',
    'types',
  ],
  tailor: [
    'new_orders',
    'new_adjustments',
    'clients',
    'new_clients',
    'products',
    'types',
    'metrics',
  ],
  stylist: [
    'new_orders',
    'new_adjustments',
    'clients',
    'new_clients',
    'metrics',
  ],
  store_assistant: ['new_adjustments', 'clients', 'new_clients', 'metrics'],
  marketing: ['clients', 'products', 'types', 'metrics'],
  adjustment_staff: ['clients'],
  production_department: [
    'clients',
    'products',
    'types',
    'metrics',
    'companies',
    'uniforms',
    'companies_orders',
    'genres',
  ],
};

export const SCREEN_SIZE = {
  TABLET: 1100,
};
