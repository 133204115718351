import { useState } from 'react';
import { Typography, Form, Input, Button } from 'antd';

import { useAuth } from '../hooks/useAuth';

const { Title, Text } = Typography;

const Login = () => {
  const [forgotStep, setForgotStep] = useState(0);
  const { signIn, sendPasswordResetEmail, isError, isLoading } = useAuth();

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const FormContent = () => {
    switch (forgotStep) {
      case 0:
        return (
          <>
            <Title level={3} className="login-title">
              Login
            </Title>
            <div className="input-wrap">
              <Form.Item
                validateStatus={isError ? 'error' : null}
                name="username"
                rules={[
                  {
                    required: true,
                    // message: 'Please input your username!',
                  },
                ]}
              >
                <Input placeholder="Email" autoComplete="username" />
              </Form.Item>
              <Form.Item
                validateStatus={isError ? 'error' : null}
                help={isError ? 'Wrong credential, please try again' : ''}
                name="password"
                rules={[
                  {
                    required: true,
                    // message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  autoComplete="password"
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Form.Item>
                <Button
                  className="submit-button"
                  style={{ marginBottom: '10px !important' }}
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Sign in
                </Button>
              </Form.Item>
              <Text
                style={{
                  color: 'rgba(255, 255, 255, 0.45)',
                  cursor: 'pointer',
                }}
                onClick={() => setForgotStep(1)}
              >
                Forgot password?
              </Text>
            </div>
          </>
        );

      case 1:
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title level={3} className="login-title">
                Forgot your password?
              </Title>
              <Text className="login-subtitle">
                Please enter your email address to request a password reset
              </Text>
            </div>

            <div className="input-wrap">
              <Form.Item
                // validateStatus={signIn.isError ? 'error' : null}
                name="email"
                rules={[
                  {
                    required: true,
                    // message: 'Please input your username!',
                  },
                ]}
              >
                <Input placeholder="Email address" />
              </Form.Item>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Form.Item>
                <Button
                  className="submit-button"
                  style={{ marginBottom: '10px !important' }}
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Reset password
                </Button>
              </Form.Item>
              <Text
                style={{
                  color: 'rgba(255, 255, 255, 0.45)',
                  cursor: 'pointer',
                }}
                onClick={() => setForgotStep(0)}
              >
                Back to login
              </Text>
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title level={3} className="login-title">
                Check your mails
              </Title>
              <Text className="login-subtitle">
                A link to reset your password has been sent to your email
                address.
              </Text>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  color: 'rgba(255, 255, 255, 0.45)',
                  cursor: 'pointer',
                }}
                onClick={() => setForgotStep(0)}
              >
                Back to login
              </Text>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div
      className="login-wrapper"
      style={{ backgroundImage: 'url(/images/login-bg.png)' }}
    >
      <div className="login-window">
        <Form
          name="login"
          initialValues={{
            remember: true,
          }}
          onFinish={(values) => {
            switch (forgotStep) {
              case 0:
                signIn(values.username, values.password);
                break;

              case 1:
                sendPasswordResetEmail(values.email);
                break;

              default:
                break;
            }
          }}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div
            className="login-logo"
            style={{ backgroundImage: 'url(/images/whitelogo.svg)' }}
          />
          <FormContent />
        </Form>
      </div>
    </div>
  );
};

export default Login;
