import { Button, PageHeader } from 'antd';

import TableBlock from '../../components/tables/TableBlock';
import { getUniforms } from '../../network/B2B';

const Uniforms = () => {
  return (
    <>
      <PageHeader className="site-page-header" title="Uniforms" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getUniforms'}
        request={getUniforms}
        infinite
      />
    </>
  );
};

export default Uniforms;

const filters = (history) => ({
  fields: [
    {
      type: 'text',
      label: 'Name',
      key: 'name',
      // noRegex: true,
    },
  ],
  actions: [
    {
      label: '+ Create an uniform',
      isActive: true,
      action: () => history.push('/uniforms/create'),
    },
  ],
});

const columns = (history) => [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: '',
    key: 'action',
    width: 25,
    render: (e) => {
      return (
        <Button
          onClick={() => history.push(`/uniforms/${e.id}`)}
          type="primary"
        >
          Edit
        </Button>
      );
    },
  },
];
