import { Form, PageHeader } from 'antd';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Error from '../../components/Error';
import FormBlock from '../../components/forms/FormBlock';
import FormWrap from '../../components/forms/FormWrap';
import Loader from '../../components/Loader';
import { formOptions } from '../../forms/blocks';
import {
  ProductsDescriptionForm,
  ProductsMediaForm,
  ProductsOptionsForm,
  ProductsPublicationForm,
} from '../../forms/Products';
import { getProductDetails, updateProduct } from '../../network/Products';
import { Details } from './CreateProduct';

const EditProduct = ({ history }) => {
  let { productId } = useParams();
  const [form] = Form.useForm();

  const [index, setIndex] = useState(0);
  const [key, setKey] = useState('vest');
  const [_, setTemplate] = useState(null);

  const options = useMemo(() => {
    const options = cloneDeep(formOptions);
    delete options.collection;
    delete options.suit_2_pieces;
    delete options.suit_3_pieces;
    delete options.default;
    return options;
  }, []);

  const productDetails = useQuery(
    [
      'getProductDetails',
      {
        id: productId,
      },
    ],
    getProductDetails,
    {
      // onSuccess: (data) => {
      //   form.setFieldsValue(data)
      // },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (productDetails.isFetched && options && form) {
      const optionKeys = Object.keys(options);
      const detailKeys = Object.keys(productDetails.data);
      const index = optionKeys.findIndex(
        (key) => detailKeys.includes(key) && !!productDetails.data[key]
      );
      setIndex(index);
      setKey(optionKeys[index]);
    }
  }, [form, options, productDetails.data, productDetails.isFetched]);

  useEffect(() => {
    const tpl = cloneDeep(options[key]?.forms[0].form[0]);
    for (let i in tpl?.sections) {
      for (let j in tpl.sections[i].fields) {
        tpl.sections[i].fields[j].key.splice(0, 0, key);
      }
    }

    setTemplate(tpl);
  }, [key, options, index]);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Edit product'}
        onBack={() => history.push('/products')}
        style={{ backgroundColor: '#fff' }}
      />
      {productDetails.isError ? (
        <Error retry={() => productDetails.refetch()} />
      ) : productDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={productId}
          form={form}
          title="update_product"
          request={updateProduct}
          cancelText={'Cancel'}
          okText={'Save'}
          data={productDetails.data}
          onSuccess={() => history.push('/products')}
          onCancel={() => history.push('/products')}
          cleanBeforeSending={(oldData) => {
            if (oldData.mainPicture) {
              let main = oldData.mainPicture.fileList
                ? oldData.mainPicture?.fileList
                : oldData.mainPicture;

              if (!Array.isArray(main)) {
                main = [main];
              }
              const newPic = main.map((file) =>
                file.response ? file.response : file
              );

              oldData.mainPicture = newPic.length ? newPic[0] : {};
            } else {
              oldData.mainPicture = {};
            }

            if (oldData.otherPictures) {
              const otherPic = oldData.otherPictures.fileList
                ? oldData.otherPictures?.fileList
                : oldData.otherPictures;
              oldData.otherPictures = otherPic.map((file) =>
                file.response ? file.response : file
              );
            } else {
              oldData.otherPictures = [];
            }

            return oldData;
          }}
          invalidate={['getProducts', 'getProductDetails']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={ProductsDescriptionForm} />
            <Details form={form} />
            <FormBlock form={form} formTemplate={ProductsMediaForm} />
            <FormBlock form={form} formTemplate={ProductsOptionsForm} />
            <FormBlock.Basic formTemplate={ProductsPublicationForm} />
          </div>
        </FormWrap>
      )}
    </>
  );
};

export default EditProduct;
