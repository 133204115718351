import { INSTANCE } from './API';

export const login = (email, password) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: '/auth/login',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email: email,
        password: password,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const refresh = (refreshToken) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: '/auth/refresh',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        refreshToken,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const resetEmail = (email) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: '/auth/password',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email: email,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const resetPassword = (password, token) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: `/auth/password/${token}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        password: password,
      },
    })
      .then(resolve)
      .catch(reject);
  });
