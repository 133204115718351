import { useState } from 'react';
import { PageHeader, Space, Button, Modal, message, notification } from 'antd';
import { EyeOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';

import { getClients, resetPassword } from '../../network/Clients';
import TableBlock from '../../components/tables/TableBlock';
import { useAuth } from '../../hooks/useAuth';
import { ROLE_PERMISSIONS } from '../../constants/common';

const getBaseUrl = (path) => {
  let p = path.split('/');
  p.pop();
  return p.join('/');
};

const Clients = ({ history }) => {
  const queryClient = useQueryClient();
  queryClient.invalidateQueries('getClientDetails');
  let location = useLocation();
  const [selected, setSelected] = useState(null);
  const isRegular = location.pathname === '/clients';
  const { user } = useAuth();
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : [];

  const filters = (history) => ({
    fields: [
      {
        type: 'text',
        label: 'Client name',
        key: 'name',
        noRegex: true,
      },
      {
        type: 'text',
        label: 'Client ID',
        key: 'clientID',
      },
      {
        type: 'text',
        label: 'Wechat ID',
        key: 'wechatID',
      },
      {
        type: 'text',
        label: 'Phone number',
        key: 'phone',
      },
    ],
    actions: [
      {
        label: '+ New client',
        isActive: permissions.includes('new_clients'),
        action: () => history.push('/clients/create'),
      },
    ],
  });

  const filtersOrders = (
    history,
    selectedRowKeys,
    isLoading,
    selectedRow,
    lll,
    location
  ) => ({
    fields: [
      {
        type: 'text',
        label: 'Client name',
        key: 'name',
        noRegex: true,
      },
      {
        type: 'text',
        label: 'Client ID',
        key: 'clientID',
      },
      {
        type: 'text',
        label: 'Wechat ID',
        key: 'wechatID',
      },
      {
        type: 'text',
        label: 'Phone number',
        key: 'phone',
      },
    ],
    actions: [
      {
        label: 'Previous',
        second: true,
        isActive: true,
        action: () => history.push(getBaseUrl(location.pathname)),
      },
      {
        label: 'Next',
        isActive: !!selectedRow,
        action: () =>
          history.push(`${getBaseUrl(location.pathname)}/${selectedRow}`),
      },
    ],
  });

  const columns = (history, keyRequest) => [
    {
      title: 'Client Name',
      render: (e) => `${e.firstName} ${e.lastName}`,
      key: 'name',
      sorter: true,
    },
    {
      title: 'Client ID',
      dataIndex: 'clientID',
      key: 'clientID',
      sorter: true,
    },
    {
      title: 'Wechat ID',
      dataIndex: 'wechatID',
      key: 'wechatID',
      sorter: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: 50,
      render: (e) => {
        return (
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => history.push(`/clients/${e.id}/view`)}
            />
            <Button
              type="primary"
              shape="circle"
              icon={<UnorderedListOutlined />}
              onClick={() =>
                history.push({
                  pathname: `/clients/${e.id}/view`,
                  toOrder: true,
                })
              }
            />
            {permissions.includes('new_clients') && (
              <Button
                onClick={() => history.push(`/clients/${e.id}`)}
                type="primary"
              >
                Edit client
              </Button>
            )}
            {permissions.includes('new_orders') && (
              <Button
                onClick={() => history.push(`/orders/create/${e.id}`)}
                type="primary"
              >
                + New order
              </Button>
            )}
            {permissions.includes('new_clients') && (
              <ModalBtn
                id={e.id}
                keyRequest={keyRequest}
                buttonLabel="Reset password"
                title="Reset password"
                content="Do you want to reset password?"
                request={resetPassword}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const columnsOrders = (history, keyRequest, selectedRow) => [
    {
      title: 'Client Name',
      render: (e) => (
        <div className={e.id === selectedRow ? 'selected' : ''}>
          {`${e.firstName} ${e.lastName}`}
        </div>
      ),
      key: 'name',
      sorter: true,
    },
    {
      title: 'Client ID',
      render: (e) => (
        <div className={e.id === selectedRow ? 'selected' : ''}>
          {e.clientID}
        </div>
      ),
      key: 'clientID',
      sorter: true,
    },
    {
      title: 'Wechat ID',
      render: (e) => (
        <div className={e.id === selectedRow ? 'selected' : ''}>
          {e.wechatID}
        </div>
      ),
      key: 'wechatID',
      sorter: true,
    },
    {
      title: 'Phone Number',
      render: (e) => (
        <div className={e.id === selectedRow ? 'selected' : ''}>{e.phone}</div>
      ),
      key: 'phone',
      sorter: true,
    },
  ];

  // getBaseUrl(location.pathname)
  return (
    <>
      <PageHeader
        className="site-page-header"
        title={
          isRegular
            ? 'Clients'
            : `Create ${
                location.pathname.includes('orders') ? 'order' : 'adjustment'
              }`
        }
        onBack={
          isRegular ? null : () => history.push(getBaseUrl(location.pathname))
        }
      />
      <TableBlock
        className={isRegular ? '' : 'has-selection'}
        columns={isRegular ? columns : columnsOrders}
        filters={isRegular ? filters : filtersOrders}
        onRow={(record) => ({
          onClick: () =>
            setSelected((prev) => (prev === record.id ? null : record.id)),
        })}
        name={'getClients'}
        request={getClients}
        pageId={selected}
      />
      {!isRegular && (
        <Space
          style={{
            backgroundColor: '#fff',
            width: '100%',
            padding: '0px 24px 24px 24px',
          }}
        >
          <Button onClick={() => history.push(getBaseUrl(location.pathname))}>
            Previous
          </Button>
          <Button
            onClick={() =>
              history.push(`${getBaseUrl(location.pathname)}/${selected}`)
            }
            disabled={!selected}
            type="primary"
          >
            Next
          </Button>
        </Space>
      )}
    </>
  );
};

export default Clients;

const ModalBtn = ({ id, buttonLabel, keyRequest, request, title, content }) => {
  const [visible, setVisible] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation(() => request({}, id), {
    onSuccess: (data) => {
      queryClient.setQueryData(keyRequest, (oldData) => {
        notification.info({
          message: 'User Updated',
          description: `New password is ${data.password}`,
          duration: 0,
        });

        return oldData;
      });
      setVisible(false);
    },
    onError: () => {
      message.error('An error occurs');
      setVisible(false);
    },
  });

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {buttonLabel}
      </Button>
      <Modal
        title={title}
        centered
        open={visible}
        onOk={mutation.mutate}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        {content}
      </Modal>
    </>
  );
};
