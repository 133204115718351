import { INSTANCELIST, INSTANCE } from './API.js';

export const getCompanies = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter, tabFilter } = queryKey[1];

    INSTANCELIST({
      method: 'get',
      url: '/companies',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
        ...tabFilter,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getCompanyOrders = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter, tabFilter } = queryKey[1];

    INSTANCELIST({
      method: 'get',
      url: '/company-orders',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
        ...tabFilter,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getUniforms = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter, tabFilter } = queryKey[1];

    INSTANCELIST({
      method: 'get',
      url: '/uniforms',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
        ...tabFilter,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getCompanyProducts = () =>
  new Promise((resolve, reject) => {
    INSTANCELIST({
      method: 'get',
      url: '/products/details?targetAudience=company',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getCompanyTheme = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { filter } = queryKey[1];

    INSTANCELIST({
      method: 'get',
      url: '/company-themes',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        filter,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getCompanyOrderDetails = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1];

    INSTANCE({
      method: 'get',
      url: `/company-orders/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const createCompanyOrder = (form) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: '/company-orders',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export const updateCompanyOrder = (form, id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/company-orders/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export const getCompanyDetails = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1];

    INSTANCE({
      method: 'get',
      url: `/companies/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const createCompany = (form) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: '/companies',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export const updateCompany = (form, id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/companies/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export const getUniformDetails = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1];

    INSTANCE({
      method: 'get',
      url: `/uniforms/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const createUniform = (form) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: '/uniforms',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export const updateUniform = (form, id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/uniforms/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });

export const uploadClientList = (id, file) =>
  new Promise((resolve, reject) => {
    var FormData = require('form-data');
    var data = new FormData();
    data.append('file', file);

    INSTANCE({
      method: 'post',
      url: `/company-orders/${id}/import`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: data,
    })
      .then(resolve)
      .catch(reject);
  });

export const sendBatchEmail = (orderId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: `/company-orders/${orderId}/email`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const sendOneEmail = (orderId, userId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: `/company-orders/${orderId}/email/${userId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const sync3dLook = (orderId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'get',
      url: `/company-orders/${orderId}/3dlook-sync`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const createOrderUser = (orderId, data) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: `/company-orders/${orderId}/customer `,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: data,
    })
      .then(resolve)
      .catch(reject);
  });

export const getOrderExcel = (id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'get',
      url: `/company-orders/${id}/export`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const getOrderUser = (id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'get',
      url: `/company-customers/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject);
  });

export const editOrderUser = (form, id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/company-customers/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject);
  });
